.GuestAlreadyHaveAccountModalHeader {
  display: flex;
  padding: 0 0 28px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .GuestAlreadyHaveAccountModalHeaderTitle {
    color: var(--color-text-white);
    text-overflow: ellipsis;
  }

  .GuestAlreadyHaveAccountModalCloseButtonWrapper {
    display: flex;
    width: 44px;
    height: 44px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
      flex-shrink: 0;

      path {
        stroke: var(--color-text-white);
      }
    }
  }
}

.GuestAlreadyHaveAccountModal {
  .InfoModalBackground {
    &.isBottom {
      .GuestModalContainer {
        width: 100%;
        max-width: 100%;
        border-radius: 30px 30px 0 0;
      }
    }
  }

  .ModalContainer {
    width: 100vw;
  }

  .ModalClassname {
    background-color: var(--color-mini-button-reverse);
    padding: 28px 16px 30px;
  }

  .ContainerClassname {
    border-radius: 30px;
  }

  .ModalBackground {
    z-index: 101;
  }
}

.GuestAlreadyHaveAccountModalBody {
  padding: 0 0 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .GuestAlreadyHaveAccountModalBodyTitle {
    color: var(--color-text-white);
  }
}

.GuestAlreadyHaveAccountModalFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  h4{
    color: var(--color-inverse-25);
  }

  .PrimaryButton {
    position: static;
    width: 100%;
    margin-bottom: 30px;

    .PrimaryButtonText {
      color: var(--color-text-black);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .GuestAlreadyHaveAccountModalFooterSignIn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 6px 0;

    .GuestAlreadyHaveAccountModalFooterTitle {
      color: var(--color-text-white);
    }

    .GuestAlreadyHaveAccountModalFooterLogin {
      color: var(--color-primary);
    }
  }
}

.GuestAlreadyHaveAccountModalProfileButton {
  background: var(--color-preferred-secondary-bg);
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-text-white);

  svg path {
    stroke: var(--color-preferred-secondary-text);
    fill: var(--color-preferred-secondary-text);
  }

  &:active {
    background: var(--color-preferred-secondary-bg);
  }

  &:hover {
    background: var(--color-preferred-secondary-bg);
  }

  &:hover,
  &:active {
    svg path {
      stroke: var(--color-preferred-secondary-text);
      fill: var(--color-preferred-secondary-text);
    }
  }
}

.UserAvatarIcon {
  width: 80px;
  height: 80px;
  position: absolute;
  object-fit: cover;
  bottom: 0;
}

.GuestAlreadyHaveAccountModalBodyInputContainer {

  .InputControlContainer {
    margin-bottom: 16px;
  }
}

.GuestAlreadyHaveAccountModalProfileFileInput {
  display: none;
}

.GuestAlreadyHaveAccountModalFooterButton {
  &:active {
    scale: 0.95;
  }

  svg {
    stroke: var(--color-preferred-secondary-text);
  }

  .PrimaryButtonText {
    color: var(--color-preferred-secondary-text);
  }
}

.GuestLoginModalPrivacyAndPolicyModal {
  margin-top: 40px;
  margin-bottom: 12px;

  .GuestLoginModalPrivacyAndPolicyModalTitle {
    color: var(--color-light-mode-black);
    cursor: pointer;
  }

  .ModalContainer {
    height: calc(100vh - 100px);
  }
}

.GuestLoginModalPrivacyAndPolicyModalLink {
  font-size: 12px;
  font-weight: 500;

  strong {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
}
