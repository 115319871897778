.MenuItemInfoPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  h6 {
    color: var(--color--semantic-error);
  }
}

.MenuItemDescriptionContainer {
  margin-bottom: 12px;
}

.MenuItemTagsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
  margin: 12px -16px;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 0;
  }
}
