.StatsCardChartWrapper {
  width: 460px;

  border-radius: 16px;
  background: var(--color-background-light-mode);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  justify-content: center;

  .StatsCardChartWrapperTitleAndSubtitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: auto;

    .StatsDate {
      color: var(--color-inverse-75);
    }
  }

  .StatsCardChart {
    margin-bottom: auto;
  }
}
