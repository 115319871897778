.Root {
  background: var(--color-gray-20);
  width: 100%;
  height: 100%;
}

.Container {
  padding: 0 12px;
  width: 100%;
  height: 100%;
}

.Header {
  display: flex;
  padding: 24px 0 40px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}

.BackWrapper {
  position: absolute;
  left: 16px;

  &:dir(rtl) {
    right: 16px;
    left: auto;
    transform: rotate(180deg);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 32px;
  border: 1px solid var(--color-gray-20);
  background: #eeedf2;
}

.Title {
  align-self: stretch;
  color: var(--color-text-black-high);
  text-align: center;
  margin-inline: 35px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
