.HamburgerButton {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;
  background: none;

  &.hasBackground {
    background: var(--color-mini-button);

    .HamburgerButtonLineContainer {
      gap: 4px;

      .HamburgerButtonLine {
        background-color: var(--color-text-black);
        width: 24px;
      }
    }
  }
  &.onDashboard {
    .HamburgerButtonLineContainer {
      .HamburgerButtonLine {
        background-color: var(--color-text-white);
      }
    }
  }

  .HamburgerButtonLineContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .HamburgerButtonLine {
      width: 28px;
      height: 2px;
      background-color: var(--color-preferred-primary-text);
      border-radius: 8px;
    }
  }
}
