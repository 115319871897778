.FormattedPhoneNumber {
  div {
    display: none;
  }


  .react-international-phone-input {
    border: none;
    padding: 0;
    font-size: 12px;
    background-color: transparent;
    height: fit-content;
    user-select: none;
    cursor: auto;
    width: fit-content;

    [dir="rtl"] & {
      text-align: right;
    }
  }

  &.textWhite input {
    color: var(--color-text-white);
  }

  &.h1 input {
    font-size: 26px;
    line-height: 32px;
  }

  &.h2 input {
    font-size: 22px;
    line-height: 28px;
  }

  &.h3 input {
    font-size: 20px;
    line-height: 24px;
  }

  &.h4 input {
    font-size: 18px;
    line-height: 24px;
  }

  &.h5 input {
    font-size: 16px;
    line-height: 20px;
  }

  &.h6 input {
    font-size: 14px;
    line-height: 16px;
  }

  &.h7 input {
    font-size: 12px;
    line-height: 16px;
  }

  &.h8 input {
    font-size: 10px;
    line-height: 14px;
  }

  &.ExtraLight input {
    font-weight: 200;
  }

  &.Light input {
    font-weight: 300;
  }

  &.Regular input {
    font-weight: 400;
  }

  &.Medium input {
    font-weight: 500;
  }

  &.SemiBold input {
    font-weight: 600;
  }

  &.Bold input {
    font-weight: 700;
  }

  &.tableColor input {
    color: var(--color-inverse-75);
  }

  &.ReservationDetailPhoneNumber {
    width: 50%;

    input {
      width: 100%;
      text-align: right;
    }
  }
}
