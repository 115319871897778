@import "src/assets/styles/_variables";

.MenuItem {
  overflow-x: hidden;
  position: relative;

  .MenuItemImagesWrapper {
    position: relative;
    height: 420px;

    .ArButtonWrapper {
      cursor: pointer;
      position: absolute;
      bottom: 12px;
      right: 16px;
      display: inline-flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 24px;
      background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(5px);

      > svg {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
      }

      > p {
        color: var(--color-text-white);
      }
    }
  }

  .MenuItemCoverImage,
  .MenuItemCarouselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .MenuItemModifications {
    padding: 16px 16px 32px;

    > * + * {
      margin-top: 16px;
    }
  }

  .MenuItemFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0.65);
    position: fixed;
    backdrop-filter: blur(4px);
    bottom: 0;
    width: 100%;
    padding: 12px 16px;
    z-index: 10;

    .AnimatedPlusMinusButtonContainer {
      flex: 1;
      position: sticky;
    }

    .CTAButton {
      flex: 3;
      bottom: 0;
      height: 56px;
    }
  }

  .AddToBasketBtn,
  .SaveChanges {
    &:active {
      scale: 0.95;
    }
  }
}

.MenuItemInfoContainer {

  h2 {
    margin-bottom: 12px;
  }

  &.WithPhoto {
    padding: 16px 16px 0;
  }

  &.WithoutPhoto {
    padding: 68px 16px 0;
  }
}

.MenuItemSlider {
  height: 420px;
  z-index: -1;

  .SwiperSlide {
    height: 100%;
  }
}

.MenuItem {
  > div {
    &:nth-last-child(2) {
      margin-bottom: 90px;
    }
  }
}
