.PolicyModalBody {
  max-width: 560px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 30px;
}

.TermsSection {
  h5 {
    margin-bottom: 8px;
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #000;
    }
  }
}

.AddItemModalHeader {
  padding-top: 10px;
  padding-bottom: 10px;

  .AddItemModalHeaderCloseButton {
    border-radius: 6px;
    background: var(--color-mini-button);
    width: 40px;
    height: 40px;
  }
}
