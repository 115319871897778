.payment-selection {
  padding: 24px;

  &__header {
    text-align: center;
    margin-bottom: 24px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: var(--text-primary);
      margin-bottom: 8px;
    }

    p {
      color: var(--text-secondary);
      font-size: 16px;
    }
  }

  &__methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    max-width: 600px;
    margin: 0 auto;
  }
}

.payment-method {
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--surface-light);
  border: 2px solid var(--border-color);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &--selected {
    border-color: var(--primary-color);
    background: var(--primary-light);

    .payment-method__icon {
      background: var(--primary-color);
      color: white;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--surface-variant);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: all 0.2s ease;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    flex: 1;

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: var(--text-primary);
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      color: var(--text-secondary);
      margin: 0;
    }
  }
}

.payment-confirmation {
  padding: 24px;
  text-align: center;

  &__content {
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: var(--text-primary);
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      color: var(--text-secondary);
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

// Add these CSS variables to your root style file if not already present
:root {
  --primary-color: #2196F3;
  --primary-light: rgba(33, 150, 243, 0.1);
  --text-primary: #1A1A1A;
  --text-secondary: #666666;
  --surface-light: #FFFFFF;
  --surface-variant: #F5F5F5;
  --border-color: #E0E0E0;
}