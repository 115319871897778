@import 'src/assets/styles/_variables';

.AdminOrderDetailsOrdersGuestName {
  @media (max-width: $mobile) {
    margin: 8px 0 10px;
  }
}

.AdminOrderDetailsOrderListCaption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .AdminOrderDetailsOrdersGuestDetailsContainer {
    display: flex;
    align-items: center;

    .AdminOrderDetailsOrdersGuestPicture {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }
}

.AdminOrderDetailsOrdersGuestMessage {
  border-radius: 8px;
  background: var(--color-opacity-15);
  padding: 12px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: auto;

  .AdminOrderDetailsOrdersGuestMessageContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .AdminOrderDetailsOrdersGuestMessageShow {
    cursor: pointer;
  }

  .IsShowMore {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
    word-break: break-word;
  }
}

.AdminOrderDetailsOrdersList {
  margin-top: 10px;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.AdminOrderList {
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.AdminOrderListGuest {
  border-radius: 8px;
  background: var(--color-mini-button);
  padding: 16px;

  @media (max-width: $mobile) {
    padding: 8px;
  }
}

.OrderItemKitchenStatus {
  display: flex;
  align-items: center;
  margin-bottom: 7px;

  &.isDenied {
    .OrderItemKitchenStatusCircle {
      background-color: var(--color-error);
    }
  }

  &.isDeleted {
    .OrderItemKitchenStatusCircle {
      background-color: var(--color-error);
    }
  }

  &.isDone {
    .OrderItemKitchenStatusCircle {
      background-color: var(--color-success);
    }
  }

  &.isPreparing {
    .OrderItemKitchenStatusCircle {
      background-color: var(--color-admin-primary);
    }

    h6 {
      color: var(--color-inverse-50);
    }
  }
}

.OrderItemKitchenStatusCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
