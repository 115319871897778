.AnimatedWordContainer {
  height: 65px;
  flex-direction: column;
  display: flex;
  font-weight: 600;
  h1 {
    font-size: 32px;
  }

  .AnimatedWords {
    overflow: hidden;
    position: relative;
  }
  .AnimatedWords::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--bg-color) 10%,
      transparent 30%,
      transparent 70%,
      var(--bg-color) 90%
    );
    z-index: 20;
  }

  .AnimatedWord {
    display: block;
    color: #956afa;
    font-size: 28px;
    animation: changeWord 6s infinite;
    &:nth-child(odd){
      color: #4A4CF3;
    }
    &:nth-child(even){
      color: var(--color-admin-primary);
    }
  }

  @keyframes changeWord {
    10% {
      -webkit-transform: translateY(-102%);
      transform: translateY(-102%);
    }

    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }

    35% {
      -webkit-transform: translateY(-202%);
      transform: translateY(-202%);
    }

    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-302%);
      transform: translateY(-302%);
    }

    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }

    85% {
      -webkit-transform: translateY(-402%);
      transform: translateY(-402%);
    }

    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }
}
