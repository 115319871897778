.DropdownOption {
  cursor: pointer;
  transition: 0.3s;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;

  h6 {
    color: var(--color-text-black) !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: var(--color-pos-neutral-3);

  }

  &.isActive {
    background: var(--color-pos-brand-colors-secondary-low);
  }
}

.OptionWithIcon {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 11px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.OptionWithIconTitle {
  display: flex;
  flex-direction: column;
}

.WithIconOptionName {
  margin-bottom: 4px;
}

