@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

$chatFooterHeight: 82px;

.NewChat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-20);
  transition: $defaultTransition;
  background-size: cover;
  z-index: 2;
  position: relative;
  .EmptyStateContainer {
    padding: 0 16px;
  }
  .EmptyStateDescription {
    color: var(--color-text-black-low);
    font-size: 16px;
    font-weight: 500;
  }
}

.ChatHeader {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-pos-neutral-3);
  background: var(--color-text-white);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.02);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  .BackButton {
    width: 40px;
    height: 40px;
  }
  .ChatHeaderGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    .ChatHeaderProfile {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid var(--color-pos-neutral-3);
      background-color: var(--color-gray-700);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      h2 {
        color: var(--color-pos-neutral-3);
      }
      img {
        min-width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
      }
      h4 {
        background: var(--color-pos-brand-colors-secondary-normal);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .ChatHeaderTitleGroup {
      display: flex;
      gap: 2px;
      flex-direction: column;
      h5,
      h6 {
        @include text-truncate-ellipsis(1);
        word-break: break-all;
      }
      h6 {
        color: var(--color-text-black-low);
      }
    }
  }
}

.NewChatHeaderAvatarImage {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
  background-color: var(--color-gray-700);
  border: 1px solid var(--color-pos-neutral-3);
  display: flex;
  align-items: center;
  justify-content: center;
  &:dir(rtl) {
    margin-left: 4px;
    margin-right: 0;
  }
  h2 {
    color: var(--color-text-white);
    font-size: 12px;
  }
}

.NewChatHeaderTitle {
  color: var(--color-text-black);
  text-align: center;
}

.NewChatHeaderAccordion {
  cursor: pointer;
  border-radius: 8px;
  background: var(--color-mini-button-reverse);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewChatBody {
  padding: 12px 20px 16px 20px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - $chatFooterHeight);

  &::-webkit-scrollbar {
    display: none;
  }
}

.NewChatScrollToBottom {
  position: absolute;
  right: 0;
  bottom: 100px;
  padding: 8px 18px;
  border-radius: 12px 0 0 12px;
  background: rgba(229, 229, 229, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-height: 60px;
  justify-content: center;
}

.NewChatUnReadMessageCount {
  margin-top: 4px;
}

.NewChatBodyMessageContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  margin-left: 4px;
  &:dir(rtl) {
    margin-left: 0;
    margin-right: 4px;
  }
  &:not(:first-child) {
    margin-top: 16px;
  }

  &.isOwnMessage {
    margin-top: 12px;
    justify-content: flex-end;

    .NewChatBodyMessage {
      border-radius: 24px 2px 24px 24px;
      border: 1px solid var(--color-pos-neutral-3);
      background: linear-gradient(180deg, #4042e2 0%, #4f52ff 100%);
      &:dir(rtl) {
        border-radius: 2px 24px 24px 24px;
      }
      h4 {
        color: var(--color-text-white);
      }
      .NewChatBodyMessageTime {
        color: var(--color-pos-text-white-low);
      }
    }
  }

  &.isSameAuthor {
    margin: 4px 4px 4px 32px;
    &:dir(rtl) {
      margin: 4px 32px 4px 4px;
    }
    .NewChatBodyMessage {
      border-radius: 20px ;
    }
  }
}

.NewChatBodyMessage {
  padding: 8px 50px 8px 16px;
  text-align: left;
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 295px;
  line-break: anywhere;
  border-radius: 2px 20px 20px 20px;
  border: 1px solid var(--color-pos-neutral-3);
  background: var(--color-text-white);
  &:dir(rtl) {
    border-radius: 20px 2px 20px 20px;
  }
  & > h4 {
    color: var(--color-inverse-100);
  }
}

.NewChatBodyMessageAuthor {
  display: flex;
  justify-content: center;
  max-width: 90vw;
}
.NewChatBodyMessageAuthorName {
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}
.NewChatBodyMessageTime {
  position: absolute;
  right: 16px;
  bottom: 8px;
  color: var(--color-inverse-75);
}
.AdvanceInputBox {
  .AdvanceInputToolsSubmitButton {
    svg {
      path {
        fill: var(--color-text-white);
      }
    }
    &:disabled {
      background: var(--color-gray-20);
    }
    background: linear-gradient(180deg, #4042e2 0%, #4f52ff 100%);
  }
}

.NewChatFooter {
  position: relative;
  padding: 16px;
  width: 100%;
  background-color: var(--color-text-white);
  z-index: 21;
}

.NewChatFooterForm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  height: 56px;
  background: rgba(247, 247, 247, 0.5);
  backdrop-filter: blur(10px);

  .InputControlContainer {
    flex: 1;
  }
}

.NewChatFooterFormInput {
  .InputControlInput {
    background-color: transparent;
  }
  .InputControlLabel {
    color: var(--color-inverse-85);
  }
}

.NewChatFooterFormSubmitButton {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(90deg, #fd0 0%, #fbb034 100%);
  margin: 5px 5px 5px 0;
}

@media (min-width: $tablet) {
  .NewChatBodyMessage {
    max-width: 400px;
  }
}

@media (min-width: $desktop) {
  .NewChatBodyMessage {
    max-width: 600px;
  }
}
