@import "src/assets/styles/_variables";

.AdminOrderPendingItems {
  border-radius: $borderRadiusM;
  background: var(--color-group-background-light-mode);
  padding: 16px;

  .AdminOrderPendingItemsHeader {
    height: 32px;
  }

  &.isPendingItemExist {
    background: var(--color-indigo);

    .AdminOrderPendingItemsHeader {
      height: 52px;
    }
  }

  @media (max-width: $mobile) {
    padding: 8px;
  }
}

.AdminOrderPendingItemsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .AdminOrderPendingItemsSaveButton {
    width: 150px;
    height: 100%;
  }
}

.AdminOrderPendingItemsEmptyText {
  color: var(--color-inverse-85);
}

.AdminOrderPendingItemsTitle {
  display: flex;
  align-items: center;

  h4 {
    white-space: nowrap;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: $mobile) {
    h4 {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.AdminOrderPendingItemsNewOrders {
  margin-top: 16px;
}

.AdminOrderPendingItemsActions {
  margin-bottom: 8px;

  &.hasMarginBottom {
    margin-bottom: 40px;
  }
}

.AdminOrderPendingItemsSectionTitle {
  color: var(--color-inverse-85);
  margin-bottom: 8px;
}

.AdminOrderPendingItemsAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $borderRadiusM;
  background: var(--color-background-light-mode);
  padding: 10px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
