@import "src/assets/styles/_variables";

.TextAreaWrapper {
  height: 136px;
  &.hasError {
    h6 {
      color: var(--color--semantic-error);
    }

    .TextAreaControl {
      border: 1.5px solid var(--color--semantic-error);
      animation: shake 0.4s ease forwards;
      &:focus {
        border: 1px solid var(--color--semantic-error);
      }
    }

    &:hover .TextAreaControl {
      transform: translateX(-4px);
    }
  }
  .TextAreaContainer {
    height: 111px;
    border-radius: 8px;
    overflow: hidden;
  }
  .TextAreaControl {
    width: 100%;
    height: 92px;
    padding: 12px;
    border: 1px solid var(--color-gray-30);
    border-radius: 12px;
    resize: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    color: var(--color-text-black-high);
    font-size: 16px;
    font-weight: 500;
    background-color: var(--color-text-white);
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px var(--color-text-black-high) inset;
    }

    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px var(--color-text-black-high) inset;
    }
    &.noFloating {
      &::placeholder {
        color: var(--color-text-black-low);
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    &:focus {
      border: 1px solid var(--color-admin-primary);
    }
  }

  .TextArea::-webkit-scrollbar {
    display: none;
  }
  .TextAreaCommentLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    color: var(--color-text-black-low);
    text-align: end;
    padding: 8px 0 0 0;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
  100% {
    transform: translateX(0);
  }
}
