.AdminOrderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 16px 16px 30px;
}

.AdminOrderChat {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  background-color: var(--color-background-light-mode);
}

.AdminOrderTitleOrderCount {
  color: var(--color-inverse-25);
}

.AdminOrderAskCall {
  display: flex;
  > div {
    padding: 8px;
    background-color: var(--color-mini-button);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    justify-content: center;
    width: 70px;
    height: 64px;
    position: relative;
    &:hover {
      background-color: var(--color-group-background-light-mode);
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    &.isActive {
      border-color: var(--color-admin-primary);
      background-color: var(--color-admin-primary);
    }
  }
}

.AdminOrderZone {
  width: 210px;
}

.AdminOrder {
  .ModalBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  >.ModalFooter {
    margin-bottom: 30px;
    padding-top: 0;
    max-height: 165px;
    bottom: 30px;
  }
}
