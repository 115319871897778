p {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

h1 {
  font-size: 26px;
  line-height: 32px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
}

h6 {
  font-size: 14px;
  line-height: 16px;
}

.h7 {
  font-size: 12px;
  line-height: 16px;
}

.h8 {
  font-size: 10px;
  line-height: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.LargeTitle{
  font-size: 34px;
}

.ExtraLight {
  font-weight: 200;
}

.Light {
  font-weight: 300;
}

.Regular {
  font-weight: 400;
}


.Medium {
  font-weight: 500;
}

.SemiBold {
  font-weight: 600;
}

.Bold {
  font-weight: 700;
}

.LetterSpacingS {
  letter-spacing: -1;
}

.H1-Pos {
  font-size: 96px;
  line-height: 104px;
}

.H2-Pos {
  font-size: 68px;
  line-height: 76px;
}

.H3-Pos {
  font-size: 48px;
  line-height: 56px;
}

.H4-Pos {
  font-size: 40px;
  line-height: 48px;
}

.H5-Pos {
  font-size: 36px;
  line-height: 44px;
}

.H6-Pos {
  font-size: 28px;
  line-height: 36px;
}

.Subtitle1-Pos {
  font-size: 24px;
  line-height: 32px;
}

.Subtitle2-Pos {
  font-size: 20px;
  line-height: 28px;
}

.Body1-Pos {
  font-size: 18px;
  line-height: 24px;
}

.Body2-Pos {
  font-size: 16px;
  line-height: 24px;
}

.Caption-Pos {
  font-size: 12px;
  line-height: 16px;
}

.Button-Pos {
  font-size: 14px;
  line-height: 20px;
}

.Overline-Pos {
  font-size: 10px;
  line-height: 16px;
}