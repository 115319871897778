.BusinessBackgroundImageContainer {
  height: 262px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.BusinessHeroButtons {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MediumIconButton {
    background-color: var(--color-mini-button);

    svg {
      path {
        fill: var(--color-light-mode-black);
      }
    }
  }
}

.BusinessArrowBackIconButton {
  svg {
    path {
      stroke: var(--color-text-white);
    }
  }
}
