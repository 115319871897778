.BasketMessageModal{
  background-color: var(--color-text-white);
  padding: 24px 16px;
  border-radius: 24px 24px 0 0;
}
.BasketMessageModalHeader{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.BasketMessageModalBody{
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

}
.BasketMessageModalBodyTitle{
  display: flex;
  flex-direction: column;
  gap: 12px;
  h6{
    color: var(--color-text-black-low);
  }
}
.BasketMessageModalFooter{
  .BasketMessageModalFooterButton{
    background-color: var(--color-admin-primary);
    display: flex;
    justify-content: center;
  }
}
