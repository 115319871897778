.WorkingHoursInfo {
  display: flex;
  border-radius: 10px;
  padding: 10px;

  &.typeA {
    justify-content: space-between;
    align-items: center;
    background: var(--color-mini-button);

    .WorkingHoursInfoName {
      margin-right: 32px;
      margin-left: 10px;

      &:dir(rtl) {
        margin-left: 32px;
        margin-right: 10px;
      }
    }
  }

  &.typeB {
    flex-direction: column;
    background: var(--color-text-white);

    .WorkingHoursInfoName {
      margin-bottom: 10px;
    }
  }
}

.WorkingHoursInfoDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 8px;
  background: var(--color-background-light-mode);
}

.WorkingHoursInfoIcon {
  margin-right: 5px;

  &:dir(rtl) {
    margin-right: 0;
    margin-left: 5px;
  }

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;

  svg path {
    fill: var(--color-inverse-100);
    stroke: var(--color-inverse-100);
    stroke-width: 0.2;
  }
}

.WorkingHoursInfoInterval {
  color: var(--color-inverse-100);
}
