.IpBlacklistHeaderContainer {
  padding: 30px 30px 0 30px;
}

.IpBlacklistContainer {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  .IpBlacklistWrapper {
    display: flex;
    flex-direction: column;
    width: 600px;

    .AddIpToBlackListWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;

      .AddIpToBlackListInput {
        border: 1px solid gray;
        padding: 10px 20px;
        width: 500px;
      }
    }

    .IpsBlacklist {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .IpBlacklist {
        width: 100%;
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;

        > h4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }
  }
}