.DonutChart {
  width: fit-content;
  border-radius: 16px;
  background: var(--color-background-light-mode);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.DonutChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 10px 60px rgba(226, 236, 249, 0.5));
  background-color: var(--color-background-light-mode);
  width: 229px;
  height: 229px;
  border-radius: 50%;
  position: relative;
}
.DonutChartItems {
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style: none;
  gap: 16px;
  > li:not(:last-child) {
    margin-left: 10px;
  }
}

.DonutChartItem {
  display: flex;
  align-items: center;
}

.DonutChartItemsColor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.DonutChartItemsName {
  color: var(--color-text-black);
}

.DonutChartPercentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  pointer-events: none;

  > * {
    text-align: center;
  }
  h6 {
    color: var(--color-inverse-50);
  }
}
