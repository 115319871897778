.Photo {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhotoSource {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.PhotoRemove {
  position: absolute;
  top: 4px;
  right: 2px;
}

.ActivePhotoSign {
  position: absolute;
  transform: rotate(-25deg);
  left: -8px;
  top: -14px;
  width: 21px;
  height: 21px;
}
