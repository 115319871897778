@import "src/assets/styles/variables";

.Background {
  width: 100vw;
  height: 100dvh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: var(
    --Light-Mode-Theme-Custom-Colors-Blur-Dark,
    rgba(0, 0, 0, 0.64)
  );
  backdrop-filter: blur(8px);
  opacity: 0;
  transition: opacity 0.2s;
  display: none;
  &.isOpen {
    opacity: 1;
    z-index: 6;
  }
}
@media (max-width: $mobile) {
  .Background {
    display: block;
  }
}
