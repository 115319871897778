@import "src/assets/styles/_variables";

//TODO check if '*' is avoidable
*:not(font) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-text-black);
  user-select: none;

  font-family: $fontBody, "Tahoma", "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, sans-serif;
}

.OverflowHidden {
  overflow: hidden;
}

button,
input,
textarea {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  transition: $defaultTransition;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  //cursor: url("../../icons/other/Cursor.png"), auto;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
