@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.SuperAdmin {
  padding: 30px;

  .SuperAdminTitle {
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.SuperAdminHeader {
  margin: 32px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .SuperAdminHeaderDropdown {
    margin: 0 12px;
    width: 100%;
    max-width: 210px;
  }
}

.SuperAdminFilters {
  display: flex;
  margin-bottom: 32px;

  .DropdownSelectedItem {
    height: 48px;
  }

  > :not(:last-child) {
    margin-right: 16px;
  }

  .SuperAdminFiltersSearch {
    width: 190px;
  }

  .SuperAdminFiltersPlan,
  .SuperAdminFiltersStatus {
    width: 150px;
  }
}

.SuperAdminFiltersTable {
  .TableHeader .TableColumnItemClass {
    margin-right: initial;
  }
  .TableDataList .TableCustomRowItem {
    margin-right: initial;
  }
}

.SuperAdminHeaderLeft {
  flex: 1;

  h6 {
    color: var(--color-inverse-50);
    margin-top: 5px;
  }
}

@media (max-width: $mobile) {
  .SuperAdmin {
    padding: 24px 18px;
  }
  .SuperAdminHeader {
    border-top: 1px solid var(--color-stroke);
    margin: 28px 0 32px;
    padding-top: 30px;
    flex-wrap: wrap;

    .AddNewButton {
      order: -3;
    }

    .Search,
    .SuperAdminHeaderDropdown {
      max-width: 100%;
      margin: 0;
    }

    .SuperAdminHeaderDropdown {
      order: -2;
      margin: 32px 0 16px;
    }

    .Search {
      order: -1;
    }
  }

  .SuperAdminHeaderLeft {
    order: -4;

    h6 {
      white-space: nowrap;
    }
  }
}
