.AdminFeedbacks {
  .AdminFeedbacksStats {
    display: flex;
    align-items: center;
    gap: 16px;

    .StatsCardChartWithPeriodWrapper {
      width: 50%;
      height: 350px;
    }

    .AdminDashFeedbacksStarIcon {
      background-color: red !important;
    }

    .recharts-active-bar {
      path {
        fill: var(--color-primary);
      }
    }
  }

  .AdminFeedbackDropdownContainer {
    max-width: 407px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin: 32px 0 16px;
  }

  .AdminFeedbacksData {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;

    margin-bottom: 16px;

    @media (min-width: 6000px) {
      grid-template-columns: repeat(10, minmax(250px, 1fr));
    }

    @media (min-width: 4000px) and (max-width: 5999px) {
      grid-template-columns: repeat(8, minmax(250px, 1fr));
    }

    @media (min-width: 3000px) and (max-width: 3999px) {
      grid-template-columns: repeat(6, minmax(250px, 1fr));
    }

    @media (min-width: 2200px) and (max-width: 2999px) {
      grid-template-columns: repeat(5, minmax(250px, 1fr));
    }

    @media (min-width: 1600px) and (max-width: 2199px) {
      grid-template-columns: repeat(4, minmax(250px, 1fr));
    }

    @media (min-width: 1300px) and (max-width: 1599px) {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }

    @media (min-width: 750px) and (max-width: 1299px) {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }

    @media (max-width: 749px) {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
    .FeedBackCards {
      .FeedBackCard {
        background: #383838;
        height: 100%;
        border-radius: 15px;
        padding: 16px;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .MainInformationArea {
          .MainInformation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;
            gap: 10px;
            .Table_Info,
            .Date_Info,
            .OrderId,
            .Staff {
              display: flex;
              flex-direction: column;
              max-width: 111px;
            }

            .Table_Info {
              align-items: flex-start;
              justify-content: flex-start;

              #TableInfoGroup {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                h6 {
                  color: var(--color-gray);
                }
              }

              p {
                color: var(--color-inverse-25);
              }
            }

            .Date_Info,
            .OrderId,
            .Staff {
              align-items: flex-end;
              justify-content: flex-end;

              h6 {
                color: var(--color-gray);
              }

              p {
                color: var(--color-inverse-25);
              }
            }

            .OrderId {
              align-items: flex-start;
              justify-content: flex-start;
            }

            .Date_InfoInput {
              background-color: transparent;
              color: var(--color-gray);
              text-align: right;
            }
            .Date_InfoInput[type="date"]::-webkit-calendar-picker-indicator {
              display: none;
            }
          }
        }

        .FeedBackTextPart {
          margin: 0 0 16px 0;
          img {
            margin-bottom: 16px;
          }
          h6 {
            color: var(--color-mini-button);
          }
        }

        .FeedBackCardHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .FeedBackCardUserName {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;

            .PersonPicture {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .PersonName {
              color: white;
            }
          }

          .RatingContainer {
            display: flex;
            margin-bottom: 16px;
            justify-content: space-between;
            flex-direction: column;
            .RatingContainerRates {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              flex-wrap: nowrap;
              div {
                display: flex;
                align-items: center;
                gap: 2px;
                h6 {
                  color: var(--color-admin-primary);
                }
              }

              h6 {
                color: var(--color-text-white);
              }
            }
          }
        }
      }
    }
  }
  .AdminFeedbacksPagination {
    padding: 12px 0 12px;
  }
}

.CustomDropDown {
  max-height: 46px;

  .DropdownLabel {
    background: var(--color-mini-button);
  }

  .DropdownSelectedItem {
    height: initial;
    padding: 11px 16px;

    h6 {
      color: black;
    }
  }
}
