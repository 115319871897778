.ForgetPasswordVerifyOtpHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 80px;

  .ForgetPasswordOtpHeaderPhone {
    display: flex;
    gap: 4px;
  }
}

.ForgetPasswordVerifyOtpResend {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .isDisabledText {
    color: var(--color-inverse-50);
  }

  .ForgetPasswordVerifyOtpResendButton {
    padding: 8px 16px;
    border-radius: 72px;
    border: 1px solid var(--color-text-black);
    background: none;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;

    &.isDisabled {
      color: var(--color-inverse-50);
      border: 1px solid var(--color-inverse-50);
      cursor: not-allowed;
    }
  }
}