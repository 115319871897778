.MemoryCard {
  position: relative;
  cursor: pointer;
  animation-name: fall;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  height: 100%;

  .FrontFace {
    transform: rotateY(90deg);
    position: absolute;
    transition: all ease-in 0.2s;
  }
  .BackFace {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
  }

  &.isFlipped {
    .FrontFace {
      transform: rotateY(0deg);
      transition-delay: 0.2s;
      height: 100%;
    }
    .BackFace {
      transform: rotateY(90deg);
      transition-delay: 0s;
    }
  }
  img,
  svg {
    width: 100%;
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-size: contain;
  }
}



@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}