@import "src/assets/styles/_variables";

.AddNewButton {
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;


  h5,
  h6,
  svg path {
    transition: $defaultTransition;
  }

  &.typeA {
    background-color: var(--color-admin-primary);
    padding: 12px 16px;
    &:hover {
      background-color: var(--color-admin-primary-hover);
    }
    &.isDisabled{
      background: var(--color-mini-button);
      cursor: not-allowed;
      h5{
        color: var(--color-inverse-50);
      }
      svg{
        path{
          stroke:  var(--color-inverse-50);
        }
      }
    }

  }

  &.typeB {
    flex-direction: row-reverse;
    height: 36px;
    padding: 0 16px;
    border: 1px solid var(--color-admin-primary);
    background: var(--color-background-light-mode);

    .AddNewButtonIcon {
      margin-right: 0;
      margin-left: 4px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: var(--color-admin-primary);

      h5,
      h6 {
        color: var(--color-text-black);
      }

      svg path {
        stroke: var(--color-text-black);
      }
    }
  }

  &.typeC {
    border-radius: unset;
    background-color: transparent;
    h5 {
      color: var(--color-admin-primary);
    }
    svg path {
      stroke: var(--color-admin-primary);
    }

    &:hover {
      border-bottom: 1px solid var(--color-text-black);
      h5 {
        color: var(--color-text-black);
      }
      svg path {
        stroke: var(--color-text-black);
      }
    }

    &:active {
      border-bottom: 1px solid var(--color-admin-primary);

      h5 {
        color: var(--color-admin-primary);
      }

      svg path {
        stroke: var(--color-admin-primary);
      }
    }
  }

  &.typeD {
    background-color: var(--color-admin-primary);
    justify-content: center;
    padding: 13px;

    .AddNewButtonIcon {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: var(--color-background-light-mode);
      border: 1px solid var(--color-text-black);
    }

    &:active {
      background: var(--color-background-black);
      h5,
      h6 {
        color: var(--color-text-white);
      }
      svg path {
        stroke: var(--color-text-white);
      }
    }
  }

  &.typeE {
    background-color: var(--color-mini-button);
    padding: 12px 24px;

    &:hover {
      border: 1px solid var(--color-inverse-25);
      background-color: var(--color-background-light-mode);

      h5,
      h6 {
        color: var(--color-inverse-25);
      }

      svg path {
        stroke: var(--color-inverse-25);
      }
    }

    &:active {
      border: 1px solid transparent;
      background-color: var(--color-stroke);

      h5,
      h6 {
        color: var(--color-text-black);
      }

      svg path {
        stroke: var(--color-text-black);
      }
    }
  }

  &.typeF {
    background-color: var(--color-primary);
    padding: 12px 24px;
  }
}

.AddNewButtonIcon {
  margin-right: 5px;
  width: 16px;
  height: 16px;

  &:dir(rtl) {
    margin-left: 5px;
    margin-right: 0;
  }
}

@media (max-width: $mobile) {
  .AddNewButton {
    &.typeA {
      padding: 12px 18px;
    }
  }
}
