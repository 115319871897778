.Root {
  display: flex;
  width: 100%;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background-color: var(--color-pos-neutral-3);
  cursor: pointer;
}

.Title {
  color: var(--color-pos-utility-colors-dark-gray);
  text-align: center;
}