.GuestFeedBack {
  padding: 16px;
  background-color: var(--color-gray-20);
}

.GuestFeedBackTitle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  padding: 12px 0;

  h6 {
    color: var(--color-text-black-low);
  }
}

.GuestFeedBackBackContainer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    &:dir(rtl) {
      transform: rotateY(180deg);
    }
  }
}

.GuestFeedBackHeader {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  h4 {
    color: var(--color-text-black-high);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.GuestFeedBackBody {
  min-height: fit-content;
}

.FeedbackContainer {
  background-color: var(--color-mini-button-reverse);
  padding: 16px 16px 10px;
}
