.onSuccessAiTranslateModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 500px;
  padding: 30px 0;

  .onSuccessAiTranslateModalBodyIcon {
    width: 75px;
    height: 75px;
    background-color: rgba(255, 202, 64, 0.15);
    border-radius: 50%;
    padding: 15px;
  }

  .AITranslateModalBodyText {
    text-align: center;
  }
}

.onSuccessAiTranslateModalFooter {
  h5 {
    font-weight: 600;
  }
}