.CallButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  border-radius: 24px;
  background: var(--color-gray-700);

  .CallButton {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &.isDisabled {
    cursor: not-allowed;
    h5 {
      color: var(--color-text-white-low);
    }
    svg {
      opacity: 0.4;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  h5 {
    color: var(--color-text-white);
    margin-bottom: 4px;
  }
}
