.ScrollBottomContainer {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 40px;
  background: var(--color-text-white);
  box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.16);
  z-index: 20;
  transition: 0.6s ease-in-out;
  cursor: pointer;

  svg {
    path {
      stroke: #292D32;
    }
  }
}