.DateAccordionTitle {
  display: flex;
  align-items: center;

  h6 {
    margin-left: 15px;
  }
}

.DateAccordionBody {
  margin-top: 35px;
}

.DateAccordionBodyDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.DateAccordionBodyDateFrom,
.DateAccordionBodyDateTo {
  width: calc(50% - 5px);
  border-radius: 8px;
  background: var(--color-mini-button);
  padding: 14px 16px;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-gray);
}

.DateAccordionBodyDateLabel {
  position: absolute;
  left: 8px;
  transform: translateY(-50%);
  padding: 0 8px;
  color: var(--color-inverse-50);
  background: var(--color-mini-button);
  top: 0;
}
