@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.MapItemEditModalBodyTables {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 505px;
  margin-bottom: 24px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  margin-top: 24px;
}
.ShapeSelectionOptions {
  display: flex;
  width: 100%;
  gap: 40px;
}

.MapItemEditModalBodyTable {
  width: 85px;
  height: 61px;
  padding: 8px;
  border-radius: 4px;
  background: var(--color-stroke);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-word;
    text-align: center;
  }
  &.active {
    border: 2px solid var(--color-admin-primary);
    background: var(--color-admin-primary-disabled);
  }
}
.MapItemEditModalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MapItemEditModalTitleActions{
  display: flex;
  gap: 8px;
}