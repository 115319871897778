.MemoryCardGame {
  padding: 16px;
  background-color: var(--color-text-black);
  align-items: center;
  min-height: 100%;
  width: 100%;
  position: relative;

  .ModalClassname {
    background-color: var(--color-mini-button-reverse);
  }

  .MemoryCardGameTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .MemoryCardGameBackRefreshButton {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;
      background: var(
                      --Light-Mode-Text-Black,
                      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                      #1f1f1f
      );
      background-color: transparent;

      img {
        width: 20px;
        height: 20px;

        &:dir(rtl) {
          transform: rotate(180deg);
        }
      }
    }

    .MemoryCardGameTitleBack {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    h2 {
      color: var(--color-text-white);
    }
  }

  .MemoryCardGameCountsBoard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    margin-top: 30px;
    background: var(
                    --Light-Mode-Text-Black,
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                    #1f1f1f
    );
    background-color: transparent;

    .MemoryCardGameSeconds {
      h2 {
        font-size: 32px;
      }

      h2,
      h4 {
        color: var(--color-text-white);
      }

      h4 {
        margin-top: 6px;
      }
    }

    .MemoryCardGameMatchs {
      p {
        font-size: 42px;
        color: var(--color-text-yellow);
      }
    }

    .MemoryCardGameTries {
      h2 {
        font-size: 32px;
      }

      h2,
      h4 {
        color: var(--color-text-white);
        text-align: end;
      }

      h4 {
        margin-top: 6px;
      }
    }
  }

  .MemoryCardGameCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px 0px;
    gap: 4px;
    flex-shrink: 0;
    margin-top: 20px;
  }
}
