.PaidInfoWrapper {
  overflow: hidden;
  border-radius: 12px;
  //transition: 0.5s ease-in-out, opacity 0.5s ease-in-out;
  //transition: 0.5s;
}

.PaidInfo {
  display: flex;
  padding: 16px 16px 0 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: #3b6239;
  box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .PaidInfoHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .AdminOrderHomeInfoBoxOrderDetailPrintTheBill {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: var(--color-text-white);
      padding: 18px 34px;
      gap: 10px;
      border-radius: 8px;
      margin-top: 24px;
    }

    .PaidInfoHeader {
      > hr {
        width: 100%;
        height: 1px;
        border: 0;
        border-top: 1px solid rgb(76, 191, 74);
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .PaidInfoHeaderTitle {
        align-self: stretch;
        color: var(--color-text-white);
        font-style: normal;
      }

      .CreatedByContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .CreatedBy {
          color: var(--color-text-white);
          font-style: normal;
          display: flex;
          align-items: center;
          .SemiBold {
            display: inline-block;
            color: var(--color-text-white);
            font-size: 14px;
            width: 255px;
            font-style: normal;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 3px;
          }
        }

        .Medium {
          color: var(--color-success);
          font-style: normal;
        }
      }
    }

    .PaidInfoBody {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      .PaidInfoBodyTotal {
        display: flex;
        padding: 4px 0;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .SemiBold {
          color: var(--color-text-white);
          font-style: normal;
        }
      }

      .PaidInfoBodyServiceFee,
      .PaidInfoBodyDiscount,
      .PaidInfoBodySubTotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .Medium {
          color: var(--color-text-white);
          font-style: normal;
          display: flex;
          align-items: center;
          flex-direction: row;
          span {
            color: var(--color-text-white);
          }
          h6 {
            color: var(--color-text-white);
          }
        }
      }
    }
  }
}

.AdminOrderPageHomeInfoBoxOrderDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .PaidDetailsButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 24px;

    p {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-text-white);
    }

    svg {
      width: 12px;
      height: 6px;
      transition: 0.5s;

      path {
        fill: var(--color-text-white);
      }
    }

    .RotateArrowIcon {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }

  .OrderDetails {
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    list-style: none;
    width: 100%;

    li {
      //&:not(:nth-child(1)) {
      //  margin-top: 8px;
      //}
      margin-bottom: 8px;
      .AdminOrderInfoDetails {
        width: 100%;
        display: flex;
        justify-content: space-between;
        //margin-bottom: 8px;
        p,
        .Price,
        h6 {
          color: var(--color-text-white);
          font-size: 12px;
          font-weight: 500;
          width: auto;

          span {
            color: inherit;
            font-size: 14px;
            font-weight: 700;
            margin-right: 10px;
          }
        }

        > p {
          margin-right: 8px;
        }

        .Price {
          h6 {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-text-white);
            width: auto;
          }
        }
      }

      .OrderItemDetailsListModifications {
        margin: 8px 8px 0;

        .OrderItemDetailsListModificationList {
          h6 {
            word-break: break-word;
            color: var(--color-text-white);
          }
        }
      }
    }
  }
}
