@import 'src/assets/styles/variables';

.PaymentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.PaymentContentWrapper {
  height: 530px;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .PaymentMethodQuestion {
    margin: 40px 0 15px;
  }

  .PaymentMethodInfoText {
    margin-bottom: 30px;
    padding-right: 8px;
    color: var(--color-inverse-50);
  }
}

.PaymentConfirmStepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.PaymentMethodSelection {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.PaymentConfirmStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 48px;
    width: 200px;
  }

  h3,
  h6 {
    text-align: center;
  }

  h6 {
    color: var(--color-inverse-25);
    padding: 0 19px;
    margin-top: 10px;
  }
}
.PayWithButton{
  button:active{
    scale: 0.95;
  }
}

.PayWithButton,
.GoToDashboardBtn {
  width: 100%;
  margin-top: auto;
  transition: bottom 0.3s ease-in-out;
  margin-bottom: 20px;
  button:active{
    scale: 0.95;
  }
}

.PaymentMethods {
  > div + div {
    margin-top: 15px;
  }
}
