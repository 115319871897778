.AdminOrderDetailSidebar {
  position: absolute;
  left: 32px;
  top: 0;
  z-index: 6;
  background: var(--color-background-light-mode);
  width: calc(100% - 32px);
  height: 100%;
  overflow-y: auto;
  border-radius: 30px 0 0 30px;
  padding: 0 16px 40px;
  transition: 0.4s;
  &.isMobile {
    position: fixed;
    left: 0;
    transition: 0.4s;
  }

  &.isMobileBarClosed {
    left: calc(100% + 40px);
  }
  .CloseSidebarButton {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 90px;
    transition: 0.4s;
    border-radius: 4px;
    background: var(--color-mini-button);
    &.isMobileBarClosed {
      left: 100%;
    }
  }
}

.AdminOrderDetailSidebarButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 56px;
  height: 120px;
  border-radius: 16px 0 0 16px;
  background: var(--color-gradient-main);
  .buttonCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--color-background-light-mode);
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  .ArrowIcon {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
  }
}

.AdminOrderDetailSidebarButtonAnimationBlue {
  animation: changeColorYellowToBlue ease infinite alternate;
  animation-duration: 1000ms;
  animation-delay: 1.5ms;
  animation-fill-mode: both;
  .ArrowIcon {
    animation: ArrowIconAnimation ease infinite alternate;
    animation-duration: 1000ms;
    animation-delay: 1.5ms;
  }
}

.AdminOrderDetailSidebarButtonAnimationGreen {
  animation: changeColorYellowToGreen ease infinite alternate;
  animation-duration: 1000ms;
  animation-delay: 1.5ms;
  animation-fill-mode: both;
  .ArrowIcon {
    animation: ArrowIconAnimation ease infinite alternate;
    animation-duration: 1000ms;
    animation-delay: 1.5ms;
  }
}

@keyframes changeColorYellowToBlue {
  0% {
    background: var(--color-primary);
  }
  100% {
    background: var(--color-blue);
    width: 77px;
  }
}

@keyframes changeColorYellowToGreen {
  0% {
    background: var(--color-primary);
  }
  100% {
    background: var(--color-success);
    width: 77px;
  }
}

@keyframes ArrowIconAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
