.AccessDeniedModalBody {
  padding: 30px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 500px;
}
.AccessDeniedModalBodyContent {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  h6 {
    color: var(--color-inverse-50);
  }
}
.AccessDeniedModalBodyIcon {
  background-color: var(--color-red-15);
  width: 74px;
  height: 74px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AdminOrderPageHeaderActionButtons{
  .ModalFooter{
    padding: 0
  ;
  }
}
