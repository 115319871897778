.AdminOrderGeneralInfo {
  width: 100%;
  border-radius: 12px;
  background: var(--Light-Mode-Text-White, #FFF);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 16px;
}

.AdminOrderGeneralInfoLists {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.AdminOrderGeneralInfoListsAndQR{
  display: flex;
  justify-content: space-between;
  img{
    width: 88px;
    height: 88px;
  }
}
.AdminOrderGeneralInfoTableAndAssignee {
  margin-top: 20px;
  > div {
    &:not(:nth-child(1)) {
      margin-top: 14px;
    }
  }
  //.DropdownOption {
  //  h6 {
  //    color: var(--color-light-mode-black) !important;
  //  }
  //}
}
