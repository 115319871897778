.AdminMapSection {
  width: 100%;
  border-radius: 10px;
}

.AdminMapSectionCaption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  h4 {
    color: var(--color-text-black);
  }
}

.AdminMapSectionZones {
  display: flex;
  margin-top: 24px;
  overflow-x: auto;
  padding: 0 10px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.AdminMapAdd {
  width: 130px;
  height: 160px;
  border-radius: 10px;

}

.AdminMapSectionZoneItemBox {
  padding: 0 6px 8px 6px;
}
.AdminMapSectionZoneItem {
  padding: 16px 24px;
  background-color: var(--color-background-light-mode);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-group-background-light-mode);
  }

  h6 {
    color: var(--color-text-black);
    white-space: nowrap;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}
.AdminMapSectionTables {
  background-color: var(--color-mini-button);
  min-height: calc(100vh - 155px);
  width: 100%;
  display: flex;
  padding: 24px 30px 30px 30px;
  gap: 24px;
  flex-wrap: wrap;
  align-content: flex-start;
  .EmptyStateContainer {
    width: 100%;
    height: calc(100vh - 300px);
  }

  .AdminMapEmptyTable {
    width: 100%;
    height: calc(100vh - 390px);
  }
}
