.ReservationEditorModalHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ReservationEditorModalBody{
  width: 500px;
}

.ReservationEditorModalControls{
  display: flex;
  align-items: center;
  gap: 6px;
  .CloseButton.typeF{
    padding: 0;
  }
}