.UndoButton {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mini-button);
  border: 1px solid transparent;
  width: 40px;
  height: 40px;

  .UndoButtonIcon {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: var(--color-group-background-light-mode);
  }
}
