@import "src/assets/styles/variables";

.Modal {
  max-height: 90%;

  .Background {
    bottom: 0;

    .Container {
      background-color: var(--color-background-light-mode);
      border-radius: 24px 24px 0 0;
    }
  }
}

.Header {
  padding: 24px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .SearchCloseButton {
    position: absolute;
    top: 20px;
    right: 16px;

    &:dir(rtl) {
      right: auto;
      left: 16px;
    }
  }
}

.Title {
  margin: 0 auto;
}

.Body {
  margin: 16px 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  .Input {
    border-radius: 24px;

    .SearchInput {
      font-size: 14px;
    }
  }
}

.Wrapper {
  height: calc(100dvh - 160px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 16px 0;
  margin-top: 8px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.PromoAndRecommendations {
  padding-top: 8px;

  :global {
    .GuestPromotionAndBanner {
      padding: 0;
    }
  }
}

.NoPromotion {

  :global {
    .BasketRecommendations {
      padding-top: 0;
    }
  }
}

@media (min-width: $tablet) {
  .Modal {
    .Background {
      .Container {
        max-height: 100%;
      }
    }
  }
}

.Delay {
  opacity: 0;
  transform: translateY(20px) scale(0.5);
  animation: SelectableChipAnimation 0.3s ease-out forwards;
}

@keyframes SelectableChipAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}