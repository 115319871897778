.TagCard {
  border-radius: 10px;
  border: 1px solid var(--color-stroke);
  background: var(--color-background-light-mode);
  padding: 0 5px 0 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  cursor: pointer;

  &.isActive {
    border: 1px solid var(--color-text-black);
    background-color: var(--color-text-black);

    h6 {
      color: var(--color-text-white);
    }
  }

  h6 {
    text-transform: capitalize;
    margin-right: 30px;
    white-space: nowrap;
  }
}

.TagCard:not(.isActive):hover {
  background-color: var(--color-stroke);
}

