
.ReservationSuccessContainerClassname {
  background-color: var(--color-text-white);

  .GuestModal {
    padding: 16px;

    .ReservationSuccessModalTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: var(--admin-and-waiter-d-green-15, rgba(76, 191, 74, 0.15));
      border-radius: 50%;
      margin: auto;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .ReservationSuccessModalBodyTables {
      text-align: center;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-bottom: 32px;
    }
  }
}