.AdminMapHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;

}
.AdminMapContentSaveButton {
  width: auto;
  padding: 16px 32px;
}
.AdminMapHeaderZone {
  display: flex;
  align-items: center;
  .Dropdown {
    width: 180px;
    margin-right: 8px;
  }
  .PrimaryButton {
    width: fit-content;
    padding: 11px 16px;
    border-radius: 8px;
  }
}
