.AdminOrderReceiptWrapper {
  overflow: hidden;
  border-radius: 12px;
  transition: 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.AdminOrderReceipt {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--color-stroke);
  background: var(--color-mini-button);

  .AdminOrderReceiptHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-bottom: 12px;

    .AdminOrderHomeInfoBoxOrderDetailPrintTheBill {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: var(--color-text-white);
      padding: 18px 34px;
      gap: 10px;
      border-radius: 8px;
      margin-top: 24px;
    }

    .AdminOrderReceiptHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      .OrderDetails{
        display: flex;
        align-items: center;
        gap: 16px;
        div{
          display: flex;
          flex-direction: column;
          gap: 2px;
          height: 100%;
          align-items: flex-start;
          span{
            color: var(--color-inverse-50);
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      div{
        display: flex;
        align-items: center;
        gap: 16px;
        svg {
          width: 12px;
          height: 6px;
          transition: 0.5s;
        }
        .RotateArrowIcon {
          transform: rotate(180deg);
          transition: 0.5s;
        }
      }



      .AdminOrderReceiptHeaderTitle {
        align-self: stretch;
        font-style: normal;
      }

      .CreatedByContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .CreatedBy {
          text-align: center;
          font-style: normal;

          .SemiBold {
            font-size: 14px;
            font-style: normal;
            line-height: 16px;
          }
        }

        .Medium {
          color: var(--color-success);
          font-style: normal;
        }
      }
    }


  }
}
.AdminOrderReceiptContainer{
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #EAEAEA;

  .GuestDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    div{
      span{
        color: var(--color-inverse-50);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      &:nth-child(2){
        text-align: end;

      }
    }
  }
  .AdminOrderReceiptBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin-top: 10px;

    .AdminOrderReceiptBodyTotal {
      display: flex;
      padding: 4px 0;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .SemiBold {
        font-style: normal;
        color: var(--color-success);
      }
    }

    .AdminOrderReceiptBodyServiceFee,
    .AdminOrderReceiptBodyDiscount,
    .AdminOrderReceiptBodySubTotal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .Medium {

        font-style: normal;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
.AdminOrderPageHomeInfoBoxOrderDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .AdminOrderDetails {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    .OrderItemDetailsListModificationList{
      h6{
        color: var(--color-inverse-50);
      }
    }

    li {
      margin-bottom: 8px;
      .AdminOrderInfoDetails {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p{
          font-size: 12px;
        }
        .AdminOrderInfoPrice{
          display: flex;
          align-items: center;
          gap:4px;
        }
        .DiscountPrice{
          color: var(--color-inverse-50);
          text-decoration: line-through;
        }
      }

      .OrderItemDetailsListModifications {
        margin: 8px 8px 0;

        .OrderItemDetailsListModificationList {
          h6 {
            word-break: break-word;
          }
        }
      }
    }
  }
}
