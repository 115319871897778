.ReservationContactForm{
  margin-top: 40px;
}
.ContactForm{
  margin-bottom: 100px;
  margin-top: 32px;

  .InputControlContainer{
    margin-bottom: 16px;
  }
.react-international-phone-input{
  [dir="rtl"] & {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }
}
  .error-message {
    color: var(--color-error);
    font-size: 12px;
    margin-top: 3px;
    margin-left: 16px;

    &:dir(rtl) {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  .react-international-phone-country-selector-button{
    &:dir(rtl) {
     border-radius: 0 8px 8px 0;
    }
  }
}

