@import "src/assets/styles/variables";

.AdminMenuSettings {
  padding: 30px;
  background: var(--color-mini-button);
}

.AdminMenuSettingsList {
  & > div:not(:last-child) {
    margin-bottom: 40px;
  }
}

.AdminMenuSettingsListItem {
  padding: 16px;
  border-radius: 16px;
}

.AdminMenuSettingsLanguagesAndCurrency {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;

  > div {
    flex: 1;
    width: 100%;
    min-width: 490px;
  }
}

.AdminMenuSettingsTitle {
  height: 44px;
  display: flex;
  align-items: center;

  .AdminMenuSettingsTitleText {
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.AdminMenuSettingsMenuLanguage {
  .AdminMenuSettingsMenuLanguageDropdowns {
    display: flex;
    gap: 20px;

    .AdminMenuSettingsMenuLanguageDropdown {
      width: 50%;
    }
  }
}

.AdminMenuSettingsMenuCurrencyDropdown {
  .DropdownSelectedItemTitleSymbol {
    width: 24px;
    height: 24px;
    background-color: var(--color-text-black);
    border-radius: 50%;
    color: var(--color-text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .DropdownOptionWithIconSymbol {
    width: 24px;
    height: 24px;
    background-color: var(--color-text-black);
    border-radius: 50%;
    color: var(--color-text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.AdminMenuSettingsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.AdminMenuSettingsHeaderSubTitle {
  color: var(--color-inverse-25);
  margin-top: 5px;
}

.AdminMenuSettingsSaveButton {
  .PrimaryButton {
    padding: 12px 16px;
    height: 46px;
  }
}

.AddMenuSettingsTopSubTitle {
  color: var(--color-gray);
}

.AdminMenuSettingsListGroup {
  display: flex;
  flex-wrap: wrap;
}

.AdminMenuSettingsListName {
  font-size: 22px;
  margin-bottom: 24px;
  color: var(--color-inverse-75);
}

.AdminMenuSettingsLanguageAndCurrency,
.AdminMenuSettingsColorAndCurves {
  > div,
  > form {
    margin-bottom: 32px;
  }

  form {
    display: flex;
  }
}

.AdminMenuSettingsLanguageAndCurrency {
  > div {
    width: 358px;
    margin-right: 38px;
  }
}

.AdminMenuSettingsColorAndCurves {
  min-width: 460px;

  .AdminMenuSettingsListName {
    margin-bottom: 20px;
  }
}

.AdminMenuSettingsMenuViewOptions {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .AdminRadioSelectOption {
    margin-right: 8px;

    &:not(.isSelected):hover {
      background-color: var(--color-mini-button);
      border: 1px solid var(--color-mini-button);
    }
  }
}

@media (max-width: $tablet) {
  .AdminMenuSettingsMenuViewOptions {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .AdminMenuSettingsLanguageAndCurrency,
  .AdminMenuSettingsColorAndCurves {
    width: 100%;
    min-width: 100%;

    > div {
      margin-right: 0;
      min-width: 100%;
      width: 100%;
    }
  }

  .AdminMenuSettingsColorAndCurves {
    > div {
      margin-bottom: 16px;

      > form {
        flex-wrap: wrap;
      }
    }
  }

  .MenuSettingsElementCurvesOptions,
  .MenuSettingsPrimaryColorsOptions {
    > div {
      margin-bottom: 24px;
    }
  }
}

.AdminMenuSettingsMenuColorOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.AdminMenuSettingsMenuColorOption {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &.dual {
    transform: rotate(45deg);

    .AdminMenuSettingsMenuColorOptionPrimary {
      position: absolute;
      width: 40px;
      height: 20px;
      top: -1px;
    }

    .AdminMenuSettingsMenuColorOptionSecondary {
      position: absolute;
      width: 40px;
      height: 20px;
      bottom: -1px;
    }

    svg {
      transform: rotate(-45deg);
    }

    &.active {
      &:before {
        top: -4px;
      }

      &:after {
        bottom: -4px;
      }
    }
  }

  svg {
    z-index: 2;
  }

  &:not(.active):hover {
    border: 3px solid var(--color-primary);
  }
}

.AdminMenuSettingsMenuRadiusOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.AdminMenuSettingsMenuRadiusOption {
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--color-stroke);
  display: flex;
  align-items: center;
  min-width: 220px;
  cursor: pointer;

  .Medium {
    flex: 1;
  }

  &:not(.isSelected):hover {
    background-color: var(--color-mini-button);
    border: 1px solid var(--color-mini-button);
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  .AdminMenuSettingsListName {
    margin-left: 8px;
  }

  .AdminMenuSettingsMenuRadiusOptionPreview {
    height: 36px;
    width: 50px;
    border-top: 2px solid var(--color-inverse-50);
    border-right: 2px solid var(--color-inverse-50);
    margin-left: 30px;
  }

  &.isSelected {
    background-color: var(--color-group-background-light-mode);
  }
}

@media (max-width: $mobile) {
  .AdminMenuSettings {
    padding: 16px;
  }

  .AdminMenuSettingsList {
    margin-bottom: 40px;
  }
}
