.WarningIcon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.typeA {
    background-color: var(--color-opacity-15);

    svg {
      path {
        fill: var(--color-admin-primary);
      }
    }
  }

  &.typeB {
    background-color: var(--color-red-15);

    svg {
      width: 12px;
      height: 12px;

      path {
        stroke: var(--color-error);
      }
    }
  }
}
