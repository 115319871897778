.BasketHeroSection {
  padding: 13px 16px 28px;
  background: var(--color-gray-800);
  .BasketHeaderActions{
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .BasketHeaderActionButton{
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 48px;
    border: 1px solid var(--color-very-dark-gray);
    background: var(--color-gray-700);
  }
}

.BasketHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .BasketHeaderTitle {
    color: var(--color-text-white);
  }

  .BackButton {
    background: var(--color-mini-button);

    svg {
      path {
        fill: var(--color-light-mode-black);
      }
    }
  }
}

