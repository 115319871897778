@import "src/assets/styles/_variables";

.Container {
  position: fixed;

  width: 68px;
  padding: 16px 20px;
  border-radius: 12px 0 0 12px;
  right: 0;
  &:dir(rtl) {
    left: 0;
    right: auto;
    border-radius: 0 12px 12px 0;
  }
  background: rgba(229, 229, 229, 0.5);
  backdrop-filter: blur(4px);
  transition: 0.6s;
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
  svg {
    transform: rotate(180deg);
    width: 20px;
    height: 20px;

    path {
      fill: var(--color-gray-600);
    }
  }
}

@media (min-width: $tablet) {
  .Container {
    display: none;
  }
}
