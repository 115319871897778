@import "src/assets/styles/_variables";

.Category {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  margin-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  &.typeA {
    .CategoryItem {
      background: var(--color-background-light-mode);
      border: none;

      @media (min-width: $mobile) {
        &:hover {
          background-color: var(--color-gray);
        }
      }

      &.isActive {
        color: var(--color-text-white);
        background: var(--color-text-black);
      }
    }
  }

  &.typeB {
    .CategoryItem {
      border: 1px solid var(--color-mini-button);
      background: var(--color-mini-button);

      &:hover {
        background-color: var(--color-stroke);
      }

      &.isActive {
        color: var(--color-text-white);
        background: var(--color-text-black);
        border: 1px solid var(--color-text-black);
      }
    }
  }
}

.CategoryItem {
  color: var(--color-text-black);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 9px 15px;
  border-radius: 8px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  transition: $defaultTransition;
  text-transform: capitalize;
  height: 46px;

  &:last-child {
    margin-right: 16px;
  }

  &.isMascotSelection {
    min-width: 114px;
    background: var(--color-primary);
    border: none;
    font-weight: 500;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: var(--color-stroke);
  }

  &.isActive {
    color: var(--color-text-white);
    background: var(--color-text-black);
    border: 1px solid var(--color-text-black);
  }
}

.CategoryCrownImg {
  position: absolute;
  top: -14px;
  right: -8px;
}
