@import "src/assets/styles/_variables";

.EditButtonPrimary {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-opacity-15);
  border: 1px solid transparent;

  h6,
  svg path {
    transition: $defaultTransition;
  }

  &.typeA {
    width: 30px;
    height: 30px;

    .EditButtonPrimaryIcon {
      width: 14px;
      height: 14px;
    }
  }

  &.typeB {
    width: 36px;
    height: 36px;

    .EditButtonPrimaryIcon {
      width: 16px;
      height: 16px;
    }
  }

  &.typeC {
    width: 46px;
    height: 46px;
    border-radius: 8px;

    .EditButtonPrimaryIcon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background: var(--color-admin-primary);

    svg path {
      stroke: var(--color-text-black);
      fill: var(--color-text-black);
    }
  }
}
