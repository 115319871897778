.EmptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 1;
  gap: 16px;
  padding: 0 84px;
  height: 100%;
  .EmptyStateDescription {
    color: var(--color-inverse-50);
  }

  .EmptyStateTitle,
  .EmptyStateDescription {
    text-align: center;
  }

  &.isAdmin {
    padding: 10px;
    gap: 24px;
    margin: auto;

    .EmptyStateDescription {
      font-weight: 500;
    }
  }
  .AdminEmptyStateCloseBtn {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 100px;
    height: 84px;
  }
}
