.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background: var(--color-admin-primary);
  padding: 0 16px;
  .iloyal-logo {
    position: absolute;
    top: 16px;
  }

  .error-card {
    padding: 16px;
    border-radius: 16px;
    text-align: center;
    width: 100%;
    background: var(--color-preferred-primary-text);
  }

  .error-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-text-white);
  }

  .error-message {
    font-size: 16px;
    font-weight: 400;
    margin-top: 32px;
    line-height: 20px;
    color: var(--color-text-white);
  }

  .retry-button {
    width: 100%;
    height: 52px;
    padding: 10px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: var(--color-admin-primary);
    font-size: 18px;
    color: var(--color-text-black);
    font-weight: 600;
    margin-top: 65px;
  }
}
