.CustomButton{
  padding: 16px;
  border: none;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pos-neutral-3);

  >svg{
    &:first-child{
      margin-right: 16px;
    }

    &:nth-child(2){
      left: 16px;
    }
  }
}