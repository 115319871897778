@import "src/assets/styles/variables";

.ConfirmOtpForForgotUserPassword {
  .Subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .ConfirmOtpHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 80px;

    .title {
      font-size: 30px;
    }

    .ConfirmOtpHeaderPhone {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .Bold {
        margin-left: 4px;
      }
    }
  }
  .ConfirmOtpVerifyOtpResend {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .isDisabledText {
      color: var(--color-inverse-50);
    }

    .ConfirmOtpResendButton {
      padding: 8px 16px;
      border-radius: 72px;
      border: 1px solid var(--color-text-black);
      background: none;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;

      &.isDisabled {
        color: var(--color-inverse-50);
        border: 1px solid var(--color-inverse-50);
        cursor: not-allowed;
      }
    }
  }
  .OtpInputContainer {
    .error-message {
      margin-left: 0;
    }
  }
  .ConfirmOtpButton {
    margin-top: 66px;
  }
}

@media (max-width: $mobile) {
  .ConfirmOtpForForgotUserPassword {
    .ConfirmOtpButton {
      position: fixed;
      width: calc(100% - 32px);
      bottom: 32px;
      right: 0;
      margin: 0 16px;
      z-index: 1;
    }
  }
}
