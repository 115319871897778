.ForgotPasswordTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 80px;
  text-align: center;
}

.ForgotPasswordInput {
  margin-top: 16px;
}

.ForgotPasswordSetNewPassword {
  display: flex;
  flex-direction: column;

  .InputPasswordContainer {
    margin-top: 16px;
  }

  .EmailOrPhoneSignInMethodError {
    margin-bottom: 0;
  }

  .phone-input {
    .react-international-phone-country-selector-button {
      border-radius: 30px 0 0 30px;
      background-color: var(--color-mini-button);
      border-left: 1px solid var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);
      &:dir(rtl) {
        border-radius: 0 30px 30px 0;
        border-right: 1px solid var(--color-mini-button);
        border-left: transparent;
      }
    }
  }

  .react-international-phone-input {
    border-radius: 0 30px 30px 0;
    background-color: var(--color-mini-button);
    border-top: 1px solid var(--color-mini-button);
    border-bottom: 1px solid var(--color-mini-button);

    [dir="rtl"] & {
      border-radius: 30px 0 0 30px;
      border-left: 1px solid var(--color-mini-button);
    }

    [dir="ltr"] & {
      border-right: 1px solid var(--color-mini-button);
    }
  }

  .SignUpLabel {
    left: 18px;
    top: 9px;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &::before {
      background-color: transparent;
    }
  }

  .SignUpInput {
    background: var(--color-mini-button);
    border-radius: 30px;
    border: 1px solid var(--color-mini-button);
    padding-left: 24px;


    &::placeholder {
      padding-left: 24px;
    }


    &:placeholder-shown {
      ~ .SignUpLabel {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        top: 50%;
        left: 18px;
      }
    }

    &:focus {
      border: 1px solid var(--color-primary);

      ~ .SignUpLabel {
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        top: 9px;
        left: 18px;
      }
    }
  }

}


