.Layout {
  width: 100%;
  height: 100%;
  transition: 0.2s ease-out;

  &.isSlided {
    transform: translateX(-80%);

    &:dir(rtl) {
      transform: translateX(80%);
    }
  }
}

.ScrollArea {
  width: 100%;
  height: 100%;

  &.isSlided {
    overflow: hidden;
  }
}
