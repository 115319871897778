@import "src/assets/styles/variables";

.AdminDashTable {
  .AdminDashHistoryFormAndTableLoading {
    height: 25vh;
  }
  .AdminDashHistoryTable {
    margin-top: 32px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    background: var(--color-background-light-mode);

    .AdminDashBoardCustomTable {
      .TableHeader {
        padding: 16px;
        border-radius: 16px 16px 0 0;
        background: var(--color-mini-button);
        .TableColumnItemClass {
          padding: initial;
          display: flex;
          align-items: center;
          gap: 4px;
          color: var(--color-text-black);
          div {
            color: var(--color-text-black);
          }
        }
      }

      .TableDataList {
        padding: 12px 16px;
        .TableCustomRowItem {
          padding: initial;
          padding-right: 20px;
          color: var(--color-text-black);
        }
      }
    }

    .AdminDashHistoryTablePagination {
      padding: 12px;
    }

    .AdminDashHistoryTableLoadMoreLoadLess {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;

      .AdminDashHistoryTableButton {
        width: auto;
        height: 32px;
        border-radius: 6px;
        border: 1px solid var(--color-stroke);
        background: none;
        padding: 8px 12px;

        color: var(--color-preferred-primary-bg);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        svg {
          width: 8px;
          height: 10px;
        }
      }
      .AdminDashHistoryTableLoadLess {
        display: flex;
        align-items: center;
        gap: 8px;
        .AdminDashHistoryTableDoubleLeftButton svg:nth-child(1) {
          margin-right: 2px;
        }
        .AdminDashHistoryTableDoubleLeftButton svg:nth-child(2) {
          transform: rotateY(180deg);
        }
        .AdminDashHistoryTablePreviousButton {
          svg {
            margin-right: 12px;
          }
        }
      }
      .AdminDashHistoryTableNextButton {
        svg {
          margin-left: 12px;
          margin-right: 0;
        }
      }

      .AdminDashHistoryTableButton:hover {
        background-color: var(--color-mini-button);
        border: none;
      }
    }
  }

  .EditedTable {
    .TableHeader {
      padding: 14px 16px !important;
      h6 {
        font-weight: 600;
        color: #1f1f1f !important;
        div {
          font-weight: 600;
          color: #1f1f1f !important;
        }
      }
    }

    .TableDataList h6 {
      //overflow: unset;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .TableData > div > *:last-child {
      margin-left: initial;
      justify-content: initial;

      width: calc(9.2857% - 14.2857px) !important;
      max-width: calc(9.2857% - 14.2857px) !important;
    }

    .TableData > div > *:first-child {
      margin-right: auto;
      width: 23% !important;
      max-width: 23% !important;
    }

    .TableItemInfo {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;

      .TableItemIndex {
        text-align: start;
        width: 36px;
      }
      .TableItemImage {

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px !important;
        }
      }
    }
  }

  .AdminDashHistoryFormAndTableEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 48px;

    .AdminDashHistoryFormAndTableEmptyInfo {
      color: var(--color-inverse-50);
    }
  }

  .tableControlsClassName {
    padding: 0 !important;
  }
}

@media (max-width: $tablet) {
  .AdminDashHistoryTable {
    .AdminDashBoardCustomTable {
      .TableHeader {
        display: flex;
      }

      .TableDataList {
        flex-direction: row;
        align-items: center;

        .TableCustomRowItem {
          display: initial;
        }
      }
    }
  }
}
