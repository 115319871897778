@import "src/assets/styles/variables";

@mixin customBorder {
  border-radius: 8px;
  border: 1px solid var(--color-stroke);
  display: flex;
  align-items: center;
}

.ReservationForm {
  //padding-bottom: 210px;
  padding-bottom: 30px;
  margin-top: 15px;
}

.ReservationEditorDropdowns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.clickable-input-wrapper input[type="date"] {
  pointer-events: all;
}

.ReservationEditorInputFields {
  margin-top: 20px;

  .AdminReservationDatePicker {
    width: 100%;
    margin-right: 0;

    .DatePickerLabel {
      color: var(--color-inverse-50);
    }
  }

  > div {
    &:not(:first-child):not(.EmailOrPhoneSignInMethodError) {
      margin-top: 16px;
    }

  }
}

.ReservationEditorInputTextarea {
  input {
    min-height: 78px;
  }
}

.ReservationEditorBookingTimeContainer {
  padding: 10px 10px 10px 16px;
  margin-bottom: 48px;
  justify-content: space-between;
  @include customBorder;

  @media (max-width: $mobile) {
    padding: 14px 10px 10px 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.ReservationEditorBookingTime {
  display: flex;
  align-items: center;
  margin-left: 14px;

  > div:first-child {
    margin-right: 24px;
  }

  .DateTime {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 19px;
      color: var(--color-inverse-50);
      margin-right: 8px;
    }

    .DropdownSelectedItem {
      min-width: 110px;
      padding: 0 12px;
    }
  }

  @media (max-width: $mobile) {
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
    margin-left: 0;
  }
}

.ReservationFormGroupedDropdowns {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  gap: 16px;

  div {
    width: 100%;
  }
}