@import "src/assets/styles/mixins";
.ReservationInfo {
  background: var(--color-background-black);
  padding: 16px;
  border-radius: 12px;
  color: var(--color-text-white);
  overflow: hidden;
  position: relative;
  transition: 0.5s;
  &:not(:first-child) {
    margin-top: 16px;
  }
  .ReservationInfoMoreDetailLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #1f1f1fcc;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }
  .ReservationInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ReservationInfoBusiness {
      display: flex;
      align-items: center;
      width: calc(100% - 50px);
      .ReservationInfoBusinessImage {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text-white);
        margin-left: 8px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 40px);

        &:dir(rtl) {
          margin-right: 8px;
          margin-left: 0
        }
      }
    }
    .ReservationInfoHeaderThreeDots {
      button {
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .ReservationInfoStatus {
    margin-top: 24px;
    .ReservationInfoStatusWrapper {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        font-weight: 500;
        margin-right: 16px;

        &:dir(rtl) {
          margin-left: 16px;
          margin-right: 0;
        }
      }
      .ReservationInfoStatusPending {
        color: var(--color-primary);
      }
      .ReservationInfoStatusConfirmed {
        color: var(--color-success);
      }
      .ReservationInfoStatusDenied {
        color: var(--color-dark-mode-error);
      }
    }
  }
  .ReservationInfoDetail {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .ReservationInfoDetailDate,
    .ReservationInfoDetailGuest {
      display: flex;
      flex-direction: column;
      flex: 1;
      > div {
        display: flex;
        align-items: center;
        color: var(--color-text-white);
        &:not(:nth-child(1)) {
          margin-top: 12px;
        }
        span {
          color: inherit;
          margin-left: 8px;
          font-size: 14px;
          font-weight: 500;

          &:dir(rtl) {
            margin-left: 0;
            margin-right: 8px;
          }
        }
        .ReservationInfoDetailCalendarIcon {
          flex-shrink: 0;
          margin-left: 2px;

          &:dir(rtl) {
            margin-left: 0;
            margin-right: 2px;
          }

          path {
            fill: var(--color-text-white);
          }
        }
        .ReservationInfoDetailClockIcon {
          flex-shrink: 0;
          path {
            fill: var(--color-text-white);
          }
        }
        .ReservationTableAndZoneName {
          display: flex;
          align-items: center;
          width: 100%;
          margin-left: 8px;
          color: var(--color-text-white);

          &:dir(rtl) {
            margin-left: 0;
            margin-right: 8px;
          }

          h5 {
            color: inherit;
          }
          h6 {
            max-width: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            position: relative;
            color: inherit;
            &:first-child {
              margin-right: 5px;

              &:dir(rtl) {
                margin-left: 5px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .ReservationsDetailsButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-background-black);
    z-index: 1;
    gap: 13px;

    p {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-text-white);
    }
    svg {
      width: 12px;
      height: 6px;
      transition: 0.5s;
      path {
        fill: var(--color-text-white);
      }
    }
    .RotateArrowIcon {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
  .ReservationInfoMoreDetail {
    margin-top: 48px;
    position: relative;
    padding-bottom: 56px;
    .ReservationInfoDetailGuestContact {
      cursor: default;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 40px;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 40px;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        svg{
          flex-shrink: 0;
        }
        .phone-input{
          cursor: default;
          .react-international-phone-country-selector {
            button {
              display: none;
            }
          }
          .react-international-phone-input {
            background: transparent;
            border: none;
            padding: 0;
            color: var(--color-text-white);
            height: 100%;
            cursor: default;

            [dir="rtl"] & {
              text-align: right;
            }
          }
        }
      }
      span {
        color: var(--color-text-white);
      }
    }
    .ReservationInfoDetailGuestComment {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 40px;
      span {
        width: 100%;
        color: var(--color-text-white);
        @include text-truncate-ellipsis(1);
        word-break: break-all;
      }
    }
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:not(:nth-child(1)) {
        margin-top: 16px;
      }
      .IconWrapper {
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background: var(--color-background-light-mode);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        flex-shrink: 0;

        &:dir(rtl) {
          margin-left: 16px;
          margin-right: 0;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
      span {
        color: var(--color-text-white);
        font-size: 18px;
        font-weight: 500;
        pointer-events: none;
        width: 100%;
        word-break: break-word;
      }
    }
  }

}
