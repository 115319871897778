.MenuCard {
  display: flex;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #EEEDF2;
  //overflow: hidden;
  position: relative;
  gap: 12px;

  &.Medium, &.Large {
    .ArButtonWrapper {
      cursor: pointer;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 24px;
      background: var(--Glass-effect, rgba(0, 0, 0, 0.20));
      backdrop-filter: blur(5px);

      > svg {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
      }

      > p {
        color: var(--color-text-white);
      }
    }
  }

  &.Small {
    padding: 12px;

    .ImageContainer {
      //margin-right: 12px;
      height: 106px;

      .Image {
        width: 100px;
        height: 106px;
        border-radius: 12px;
        object-fit: cover;
      }
    }

    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 112px);
      justify-content: space-between;

      .Tags {
        margin-bottom: 12px;
      }

      .Title {
        margin-bottom: 8px;
        font-size: 16px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.Medium {
    width: 298px;
    min-width: 298px;
    flex-direction: column;

    .ImageContainer {
      position: relative;
      height: 224px;

      .Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }

      .TimeToMake {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: white;
        border-radius: 12px;
        background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
        backdrop-filter: blur(8px);
        position: absolute;
        left: 12px;
        top: 12px;

        &:dir(rtl) {
          right: 12px;
          left: auto;
        }
      }

      :global {
        .FavoritesIconButton {
          position: absolute;
          right: 12px;
          top: 12px;

          &:dir(rtl) {
            left: 12px;
            right: auto;
          }
        }
      }
    }

    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 12px 12px 12px;
      min-height: 126px;

      .Tags {
        margin-bottom: 12px;
      }

      .Title {
        margin-bottom: 8px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.Large {
    flex-direction: column;

    .ImageContainer {
      height: 224px;
      min-height: 224px;
      min-width: 100%;
      position: relative;

      .Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }

      .FavoriteAndTimeToMake {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px;
        position: absolute;
        top: 0;

        .TimeToMake {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 2px;
          color: white;
          border-radius: 12px;
          background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
          backdrop-filter: blur(8px);

          &:dir(rtl) {
            right: 12px;
            left: auto;
          }
        }

        :global {
          .FavoritesIconButton {
            position: absolute;
            right: 12px;
            top: 12px;

            &:dir(rtl) {
              left: 12px;
              right: auto;
            }
          }
        }
      }
    }

    .DetailContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 12px 12px 12px;
      min-height: 126px;

      .Tags {
        margin-bottom: 12px;
      }

      .Title {
        margin-bottom: 8px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.WithoutImage {
    width: 100%;
    flex-direction: column;

    &:last-child {
      margin-right: 16px;
    }

    .ImageContainer {
      display: none;
    }

    .DetailContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;
      min-height: 126px;
      position: relative;

      .Favorite {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      .Tags {
        margin-bottom: 12px;
      }

      .Title {
        margin-bottom: 8px;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.Grid {
    display: block;
    flex-shrink: 0;
    aspect-ratio: 43/60;
    overflow: hidden;
    position: relative;
    border: none;
    background-color: transparent;

    .ImageContainer {
      width: 100%;
      height: 100%;
      position: relative;

      .Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ArButtonWrapper {
      position: absolute;
      top: 8px;
      left: 8px;
      display: inline-flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 28px;
      background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(8px);

      > p {
        color: white;
      }
    }

    .FavoriteAndTimeToMake {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .DetailContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      padding: 4px 8px 8px 8px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 2px;
      border-radius: 0 0 16px 16px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 27.47%, rgba(0, 0, 0, 0.00) 99.63%);
      backdrop-filter: blur(1.5px);

      .Title {
        height: 16px;
        align-self: stretch;
        overflow: hidden;
        color: var(--color-text-white);
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        max-width: 100%;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      &.extraGap {
        gap: 12px;
      }
    }

    .Footer {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .Price {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;

        h6 {
          font-size: 10px;
          font-weight: 400;
          color: var(--color-text-medium);
        }

        h5 {
          font-size: 14px;
          color: var(--color-text-white);
        }
      }

      :global {
        .AnimatedAddButton {
          border-radius: 12px;
        }
      }
    }
  }

  .Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .Price {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;

      h6 {
        color: var(--color--semantic-error);
      }
    }
  }
}
