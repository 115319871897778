@import "src/assets/styles/variables";

$modalFooterHeight: 88px;
$modalTopBottomPadding: 50px;
$modalLeftRightPadding: 50px;
$modalBodyLeftRightPadding: 30px;
$modalSlideAnimationDuration: 0.5s;
$modalBodyLeftRightPaddingMobile: 16px;

.GuestModalBackground {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity $modalSlideAnimationDuration ease,
    visibility 0s linear $modalSlideAnimationDuration;

  &.isDark {
    background-color: var(--color-background-modal);
  }

  &.isBlur {
    background-color: var(--color-background-modal-blur);
    backdrop-filter: blur(5px);
  }

  &.isVisible {
    opacity: 1;
    visibility: visible;
  }

  &.isHidden {
    opacity: 0;
    visibility: hidden;
  }

  &.isCentered {
    align-items: center;
  }

  &.isBottom {
    align-items: flex-end;

    .GuestModalContainer {
      width: 100%;
      max-width: 100%;
      border-radius: 24px 24px 0 0;
    }
  }
}

.GuestModalContainer {
  border-radius: 16px;
  max-width: calc(100vw - (2 * $modalLeftRightPadding));
  overflow: hidden;

  &.hasExtraComponentContent {
    display: flex;
  }
}

.GuestModal {
  position: relative;

  &.hasNoHeaderAndFooter {
    padding: 20px 0;
  }

  &.hasOnlyHeader {
    padding-bottom: 20px;
  }

  &.hasOnlyFooter {
    padding-top: 20px;
  }
}

@media (max-width: $mobile) {
  .GuestModalBackground {
    align-items: center;

    &.isCentered {
      .GuestModalContainer {
        width: 100%;
        max-width: 100%;
        margin: 0 17px;

        &.hasExtraComponentContent {
          width: unset;
          max-width: unset;
        }
      }

      .GuestModal {
        width: 100%;
        max-width: 100%;
        border-radius: 16px 16px;
      }
    }
  }
}

@keyframes slide {
  from {
    transform: translate(var(--slide-x, 0), var(--slide-y, 0));
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
  to {
    transform: translate(var(--slide-x, 0), var(--slide-y, 0));
    opacity: 0;
  }
}

.GuestModalContainer {
  transform: translateY(100%);
  opacity: 0;
  transition: transform $modalSlideAnimationDuration ease,
    opacity $modalSlideAnimationDuration ease;

  &.slideIn {
    animation: slide $modalSlideAnimationDuration forwards;
  }

  &.slideOut {
    animation: slideOut $modalSlideAnimationDuration forwards;
  }
}
