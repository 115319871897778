.AdminBlock {
  height: 100%;
  position: relative;
  .AdminBlockHeader {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .AdminBlockBoxContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .AdminBlockBox {
      border-radius: 8px;
      background: var(--color-mini-button);
      width: 500px;
      padding: 30px;
      .SelectBusinessModalBody {
        padding: 0;
        h3 {
          text-align: center;
        }
        ul {
          width: 100%;
          justify-content: center;
        }
      }
      .ContactUsButton {
        margin-top: 40px;
      }
    }
  }
}
