.SortingOptions {
  display: flex;
  align-items: center;
  svg {
    path {
      fill: #292D32
    ;
    }
  }
  svg {
    path {
      fill: #292D32
    ;
    }
  }
  &.desc {
    .SortingOptionsIconAsc {
      opacity: 0;
    }
    .SortingOptionsIconDesc {
      path {
        fill: var(--color-admin-primary);
      }
    }
  }
  &.asc {
    .SortingOptionsIconAsc {
      path {
        fill:  var(--color-admin-primary);
      }
    }
    .SortingOptionsIconDesc {
      opacity: 0;

    }
  }
}