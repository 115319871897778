@import "src/assets/styles/_variables";

.AddTagButton {
  border-radius: 10px;
  background: var(--color-mini-button);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;

  .AddTagButtonIcon,
  svg path {
    transition: $defaultTransition;
  }

  &.typeA {
    .AddTagButtonIcon {
      width: 20px;
      height: 20px;
    }
  }

  &.typeB {
    .AddTagButtonIcon {
      width: 24px;
      height: 24px;
    }
  }

  &.typeC {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;

    .AddTagButtonIcon {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    background: var(--color-group-background-light-mode);
  }
  &.typeD {
    background-color: var(--color-text-white);
    padding: 8px;
    flex-direction: column;
    gap: 8px;
    .AddTagButtonIcon {
      width: 20px;
      height: 20px;
    }
    &:hover{
      background-color: var(--color-group-background-light-mode);
    }
  }
}
