@import "src/assets/styles/variables";

.TableData {
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > * {
      &:last-child {
        display: flex;
        justify-content: flex-end;
        width: fit-content;
        max-width: fit-content;
        margin-left: auto;
        padding: 9px 0 9px 9px;
      }
    }
  }
  .TableDataSortArrowAsc {
    transform: translateX(-10px);
  }
}
.TableEditColumn {
  h6 {
    white-space: nowrap;
  }
}

.TableHeader {
  border-bottom: 1px solid var(--color-stroke);

  .TableColumnItemClass {
    color: var(--color-text-black);
    padding-bottom: 10px;

    &.isFilterable {
      cursor: pointer;
      position: relative;

      svg {
        min-width: 20px;
      }
    }
    &.isFilterableAscSelected {
      svg {
        scale: 1.3;
        path {
          fill: var(--color-admin-primary);
        }
      }
    }
    &.isFilterableDescSelected {
      svg {
        scale: 1.3;
        transform: rotateX(180deg);
        path {
          fill: var(--color-admin-primary);
        }
      }
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.TableDataList {
  border-bottom: 1px solid var(--color-stroke);

  .TableCustomRowItem {
    color: var(--color-inverse-75);
    padding: 9px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.TableControls {
  > * + * {
    margin-left: 8px;
  }
}
.TableDataList:hover {
  border-bottom: 1px solid var(--color-stroke);
  background: var(--color-mini-button);
}

.TableDataListLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  .TableLoading {
    height: 25vh;
    margin: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: $tablet) {
  .TableData {
    .TableHeader {
      display: none;
    }
  }

  div.TableDataList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 10px 0;

    .TableCustomRowItem {
      display: none;
      white-space: nowrap;
      padding: 2px 0;
      overflow: unset;
      &:nth-child(1) {
        color: var(--color-text-black);
        font-size: 14px;
      }

      &:nth-child(1),
      &:nth-child(3) {
        display: block;
        max-width: 50%;
      }
    }

    h6.TableControls {
      position: absolute;
      right: 0;
      top: 9px;

      > * {
        width: 40px;
        height: 40px;

        .EditButtonIcon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
