.SelectableChip {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 24px;
  border: 1px solid var(--color-gray-40);
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px) scale(0.5);
  animation: SelectableChipAnimation 0.3s ease-out forwards;
}

.ChipRadioButtonContainer {
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-inverse-100);
  border-radius: 50%;
  background-color: transparent;
  flex-shrink: 0;
}

.ChipRadioButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: var(--color-inverse-100);
  border: 1px solid var(--color-gray-40);
  background: transparent;
  transition: all 0.3s ease;
  svg {
    width: 10px;
  }
}

.SelectableChip.isChecked {
  background-color: #ffca40;
  border: 1px solid #ffca40;

  .ChipRadioButton {
    background: var(--color-inverse-100);
    border: 1px solid var(--color-inverse-100);

    svg path {
      stroke: #ffca40;
    }
  }
}

.SelectableChipName {
  color: var(--color-inverse-100);
}

@keyframes SelectableChipAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
