.Root {
  display: flex;
  width: 100%;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: transparent;

  svg {
    path {
      stroke: var(--color-pos-utility-colors-dark-gray);

    }
  }

  &.isActive {
    background: var(--color-pos-brand-colors-secondary-normal);
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.12);

    svg {
      path {
        stroke: var(--color-pos-utility-colors-white);

      }
    }

    .Title {
      color: var(--color-pos-utility-colors-white);
    }
  }
}

.Icon {
  max-width: 20px;
  max-height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.Title {
  color: var(--color-pos-utility-colors-dark-gray);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.isClickable {
  cursor: pointer;
}