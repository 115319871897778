@import "src/assets/styles/mixins";
.ReservationMapZoneTable {
  background-color: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  &.isCircle {
    border-radius: 50%;
  }
  &.isSelected{
    background-color: var(--color-primary);
  }

  .ReservationMapZoneTableName {
    color: var(--color-inverse-100);
    @include text-truncate-ellipsis(1);
    word-break: break-word;
    text-align: center;
  }
}
