.TelegramIntegrationModal {
  .ModalContainer {
    width: 568px;
    border-radius: 8px;
    background: var(--color-background-light-mode);

    .ModalHeader {
      padding: 30px 30px 0;
    }

    .ModalBody {
      padding: 0 30px 30px;
      margin-top: 40px;
      margin-bottom: 30px;
      height: 350px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .TelegramIntegrationModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      width: 100%;
      text-align: center;
    }

    .ModalHeaderBackIcon svg {
      width: 10px;
    }
  }

  .TelegramIntegrationModalBody {
    .ModalCardItem {
      height: 48px;
      width: 100%;
      border: 1px solid var(--color-mini-button);
      background-color: var(--color-mini-button);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      padding: 0 16px;
      border-radius: 8px;

      .ModalCardItemContent {
        display: flex;
        align-items: center;
        gap: 8px;

        .ModalCardItemIsConnectIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          background: var(--color-stroke);
          border-radius: 6px;
          width: 32px;
          height: 24px;

          svg path {
            fill: var(--color-inverse-50);
          }

          &.isConnected {
            background-color: var(--color-green-15);

            svg path {
              fill: var(--color-success);
            }
          }
        }
      }

      .LinkedButton {
        width: 89px;
        height: 32px;
        padding: 4px 12px;
        background: var(--color-admin-primary);
        color: var(--color-text-black);
        border-radius: 6px;
        justify-content: center;

        .CTAButtonName {
          font-size: 12px;
          font-weight: 500;
          color: var(--color-text-black);
        }

        svg {
          width: 22px;
        }

        &.isConnectedToLink {
          background: var(--color-red-15);

          .CTAButtonName {
            color: var(--color-error);
          }
        }

        svg {
          margin-right: 4px;
        }
      }

      h6 {
        color: var(--color-text-black);
      }
    }

    .Confirm {
      .ModalBody {
        max-height: calc(100vh - 100px);
        height: fit-content;
        overflow-y: auto;
        padding: 0 30px;
        scrollbar-width: none;
        margin: 0;
      }

      .ConfirmModalBodyIcon {
        width: 74px;
        height: 74px;
      }
    }
  }

  .QrModalBody {
    .QrCodeWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin: 30px 0;

      .QrCode {
        border: 1px solid var(--color-gray);
        padding: 20px;
        border-radius: 8px;
      }

      .QrCodeDescription {
        max-width: 370px;
        text-align: center;
        color: var(--color-inverse-50);
      }
    }
  }
}
