.DoneButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: var(--color-green-15);

  &:hover {
    background: var(--color-success);

    .DoneButtonIcon {
      path {
        stroke: var(--color-text-white);
      }
    }
  }

  .DoneButtonIcon {
    width: 20px;
    height: 20px;
  }
}
