.MenuItemStatsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 16px;
  flex-grow: 1;
  .MenuItemStatsCardInformation {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .MenuItemImageAndName {
      display: flex;
      align-items: center;

      .MenuItemStatsCardIndex {
        padding-right: 8px;
        color: var(--color-text-black);
      }

      .MenuItemStatsCardImage {
        width: 36px;
        height: 36px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .MenuItemStatsCardItemName {
        padding-left: 8px;
        color: var(--color-text-black);
      }
    }

    .MenuItemSaleAndRevenueInformation {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      gap: 32px;

      .MenuItemStatsPriceDetails {
        display: flex;
        align-items: center;
        gap: 4px;

        .PriceDetailTitle {
          color: var(--color-inverse-50);
        }
        .PriceDetailCount {
          color: var(--color-text-black);
        }
      }
    }
  }

  .MenuItemStatsCardOrderInformation {
    display: flex;
    align-items: center;
    gap: 4px;
    .OrderInformationTitle {
      color: var(--color-inverse-50);
    }
  }
}
