@import "src/assets/styles/mixins";

.MenuItemHeaderContainer {
  position: absolute;
  top: 16px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  transition: background-color 0.7s ease-in-out;
}

.MenuItemHeaderTitle {
  color: var(--color-text-black);
  padding: 0 8px;

  @include text-truncate-ellipsis();
}
