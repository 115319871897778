@import "src/assets/styles/_variables";

.SignIn {
  .SignInForm {
    padding-top: 8px;

    .SignInFormForgotPassword {
      text-decoration: underline;
      margin: 4px 0 56px auto;
      width: fit-content;
      cursor: pointer;
    }

    .SignInFormInputGroups {
      .InputControlContainer {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .SignIn {
    .SignInFormButton {
      position: fixed;
      width: calc(100% - 32px);
      bottom: 32px;
      left: 0;
      margin: 0 16px;
      z-index: 1;
    }
  }
}
