@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.MenuItemDescription {
  font-weight: 400;
  color: var(--color-text-black);

  &.typeA {
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
    color: rgba(0, 0, 0, 0.60);
  }

  &.typeB {
    font-size: 11px;
    line-height: 13px;
  }
}
