@import "src/assets/styles/mixins";

.AdminOrderTable {
  padding: 12px 12px 8px 12px;
  width: clamp(148px, 100%, 100%);
  max-width: 248px;
  border-radius: 12px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);
  background-color: var(--color-text-white);
  height: 116px;
  cursor: pointer;
  position: relative;
  h4 {
    @include text-truncate-ellipsis(1);
    word-break: break-all;
    padding-top: 8px;
  }
  h6 {
    color: var(--color-text-black-low);
  }
  span {
    position: absolute;
    color: var(--color-pos-text-white-medium);
    font-size: 12px;
    right: 12px;
    top: 8px;
    font-weight: 400;
  }
  &.hasOrder {
    background: var(--color-pos-brand-colors-secondary-normal);

    h4 {
      color: var(--color-text-white);
    }

    h6 {
      color: var(--color-gray);
    }
  }

  &.newNotification {
    animation: backgroundColorChange 2s infinite ease;
  }
}

.AdminOrderTableActionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin-top: 8px;
  .IconWrapper {
    position: relative;
    svg {
      margin-bottom: 4px;
      path {
        stroke: var(--color-pos-text-white-low);
      }
    }
    .IconAskBill {
      margin-bottom: 8px;
    }
    &.isActive {
      svg {
        path {
          stroke: var(--color-pos-text-white-high);
        }
      }
      &:after {
        position: absolute;
        width: 4px;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--color-text-white);
        content: "";
        border-radius: 50%;
        bottom: 0;
      }
    }
  }
  .AdminOrderTableAction {
    width: 24px;
    height: 24px;
  }
}
@keyframes backgroundColorChange {
  0% {
    background: #4c4ef6;
  }
  50% {
    background: rgba(#ef8f00, 1);
  }
  100% {
    background: #4c4ef6;
  }
}
