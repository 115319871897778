.ContactContainer {
  .ContactName {
    margin-bottom: 8px;
  }

  .Contact {
    display: flex;
    align-items: center;
    border-radius: 8px;

    &.isDefaultInput {
      border: 1px solid var(--color-gray);
      padding-right: 8px;

      &.hasError {
        border: 1px solid var(--color-error);
      }
    }
  }

  .ContactIcon {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      width: 1px;
      top: 5px;
      bottom: 5px;
      content: '';
      background-color: var(--color-gray);
    }
  }

  .ContactInput {
    flex-grow: 1;

    input {
      padding: 0 10px;
    }
  }
}
