@import "src/assets/styles/variables";

.TextIconButton {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &:not(:nth-child(1)) {
    margin-left: 16px;
  }
  svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    path {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
    color: var(--color-text-black);
  }
}
