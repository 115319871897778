.FilterGroup {
  .Categories {
    h2 {
      margin-bottom: 20px;
    }

    .CategoriesWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}
