@import 'src/assets/styles/variables';

.AddItemModalHeader {
  display: flex;
  align-items: center;

  .AddItemModalDeleteButton{
    &.isDisabled{
      background-color: var(--color-mini-button);
      >svg{
        path{
          fill: var(--color-inverse-50);
        }
      }
    }
  }

  > div {
    margin-right: 10px;
  }
}

.error-message {
  color: var(--color-error);
  font-size: 12px;
  margin-top: 3px;
  margin-left: 16px;
}
.AddItemModalBodyFormInputs{
  display: flex;
  gap: 10px;
  margin-top: 16px;
  .InputControlContainer{
    width: 50%;
  }
}

.AddItemModalHeaderTitle {
  margin-right: auto;
}

.AddItemModalBodyForm {
  margin-top: 10px;
  margin-bottom: 56px;
}

.AddItemModalBodyFormInput {
  flex-grow: 1;
}

.AddItemModalBodyFormDropdown {
  margin-top: 30px;
}

.AddItemModalBody {
  width: 500px;
}

.AddItemModalHeaderTitlePublishMode {
  display: flex;
  align-items: center;

  h6 {
    margin-right: 10px;
    color: var(--color-inverse-50);
    white-space: nowrap;
  }
}

@media (max-width: $mobile) {
  .AddItemModalBody {
    width: auto;
    padding-bottom: 200px;
  }
}

.AddItemModalBodyFormSchedule {
  margin-top: 40px;
  padding: 14px 8px;
  background-color: var(--color-mini-button);
  border-radius: 12px;
}
