.WeekDayOuterProgressBar {
  width: 100%;

  height: 8px;
  border-radius: 4px;
  position: relative;

  &.isMonday {
    background: rgba(243, 66, 55, 0.2);
  }
  &.isTuesday {
    background-color: rgba(255, 159, 0, 0.2);
  }
  &.isWednesday {
    background-color: rgba(255, 202, 64, 0.2);
  }

  &.isThursday {
    background-color: rgba(43, 196, 138, 0.2);
  }

  &.isFriday {
    background-color: #d9e1f8;
  }

  &.isSaturday {
    background-color: rgba(186, 72, 255, 0.2);
  }

  &.isSunday {
    background-color: rgba(246, 92, 146, 0.2);
  }
  &.isDefault{
    background-color: var(--color-mini-button);
  }

  .WeekDayInnerProgress {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    left: 0;
    border-radius: 4px;

    &.isMonday {
      background-color: var(--toastify-color-error);
    }
    &.isTuesday {
      background-color: #ff9f00;
    }
    &.isWednesday {
      background-color: var(--color-admin-primary);
    }

    &.isThursday {
      background-color: #2bc48a;
    }

    &.isFriday {
      background-color: #0051d1;
    }

    &.isSaturday {
      background-color: #ba48ff;
    }

    &.isSunday {
      background-color: #f65c92;
    }
  }
}

.WeekdaysProgressChart {
  display: flex;

  flex-direction: column;
  gap: 12px;

  .WeekdaysProgressBarChart {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 26px;

    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid var(--Light-Mode-Stroke, #efeded);

    .WrapperOfProgressAndPercent {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 26px;
      width: 306px;

      .WeekDayPercent {
        width: 106px;
      }
    }
  }

  .weekDayOnly {
    width: 125px;
  }
}

.WeekdaysProgressChart .WeekdaysProgressBarChart:last-child {
  border: none; /* Remove border from the last item */
}
