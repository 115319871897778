.ChartPeriodSelectButton {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
  .weekDaysBtnTitle {
    color: var(--color-inverse-100);

    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 16px;
  }

  .weekDaysBtnTitle:hover {
    background: var(--color-light-gray);
  }
  .isActiveBtn {
    color: var(--color-background-light-mode);
    background: var(--color-light-mode-black) !important;
  }
}
