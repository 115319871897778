@import "src/assets/styles/_variables";

.IconButton {
  &:active {
    background: var(--color-stroke);
  }
}

.SmallIconButton {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: var(--color-mini-button);
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover{
    background-color: var(--color-group-background-light-mode);
  }
}
.typeSM {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  padding: 7px;
  svg {
    width: 18px;
    height: 18px;
  }
}
.MediumIconButton {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--color-background-light-mode);
}
