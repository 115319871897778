.InstaStoryCnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 21;
  overflow: hidden;

  img {
    object-fit: cover;
    border: none !important;
  }

  .CloseInstaStoryCnt {
    cursor: pointer;
    position: absolute;
    z-index: 999+1; // insta story left-right click has z-index 999
    right: 4px;
    top: 15px;

    svg {
      width: 44px;
      height: 44px;

      path {
        stroke: var(--color-text-white);
      }
    }
  }

}

@media (min-width: 1000px) {
  .InstaStoryCnt {
    width: 500px;
    height: 700px;
    border-radius: 40px;
    overflow: hidden;
  }
}

@media (max-width: 1000px) {
  .InstaStoryCntUnderlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 19;
    background-color: var(--color-inverse-100);
  }
}
