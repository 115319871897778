@import 'src/assets/styles/mixins';

.MenuDisplaySectionName {
  margin: 56px 16px 24px;

  h1 {
    text-transform: capitalize;
  }

  .MenuDisplaySectionNameCtgTitle {
    font-size: 32px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    padding-bottom: 3px;
  }
}

.AdminOrderMenuSection {
  &.isAdmin {
    .MenuDisplaySectionName {
      margin: 32px 16px 8px;
    }

    .MenuDisplaySectionNameForOrderPage {
      margin: 56px 0 12px;
      h1{
        font-size: 32px;
        overflow: hidden;
        word-break: break-word;
        text-transform: uppercase;
      }
    }
  }
}
