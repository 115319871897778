@import "src/assets/styles/variables";

$modalFooterHeight: 88px;
$modalTopBottomPadding: 50px;
$modalLeftRightPadding: 50px;
$modalBodyLeftRightPadding: 30px;
$modalSlideAnimationDuration: 0.5s;
$modalBodyLeftRightPaddingMobile: 16px;

.ModalBackground {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;

  &.isDark {
    background-color: var(--color-background-modal);
  }

  &.isBlur {
    background-color: var(--color-background-modal-blur);
    backdrop-filter: blur(5px);
  }
}

.ModalContainer {
  border-radius: 16px;
  max-width: calc(100vw - (2 * $modalLeftRightPadding));
  overflow: hidden;

  &.hasExtraComponentContent {
    display: flex;
    background-color: var(--color-background-light-mode);
    height: calc(100vh - (2 * $modalTopBottomPadding));
  }
}

.Modal {
  background-color: var(--color-background-light-mode);
  position: relative;
  padding-top: $modalHeaderHeight;

  &.hasNoHeaderAndFooter {
    padding: 0;
  }

  &.hasOnlyHeader {
    padding-top: $modalHeaderHeight;
    padding-bottom: 0;

    .ModalBody {
      max-height: calc(
        100vh - (2 * $modalTopBottomPadding) - $modalHeaderHeight
      );
    }
  }

  &.hasOnlyFooter {
    padding-top: 0;

    .ModalBody {
      max-height: calc(100vh - (2 * $modalTopBottomPadding));
    }
  }
}

.ModalHeader {
  height: $modalHeaderHeight;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 $modalBodyLeftRightPadding;
  z-index: 1;
  background-color: var(--color-background-light-mode);

  > div {
    width: 100%;
  }
}

.ModalBody {
  position: relative;
  overflow-y: auto;
  padding: 0 $modalBodyLeftRightPadding;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: calc(100vh - (2 * $modalTopBottomPadding) - $modalHeaderHeight);

  &::-webkit-scrollbar {
    display: none;
  }
}

.ModalFooter {
  height: $modalFooterHeight;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  //z-index: 5;
  background: rgba(255, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding-top: 12px;
}

@media (max-width: $mobile) {
  .ModalContainer {
    width: 100%;
    max-width: 100%;

    margin: 0 17px;

    &.hasExtraComponentContent {
      width: unset;
      max-width: unset;
    }
  }

  .ModalBackground {
    align-items: center;
  }

  .Modal {
    width: 100%;
    max-width: 100%;
    border-radius: 16px 16px;

    &.hasOnlyHeader {
      padding-top: $modalHeaderHeight;

      .ModalBody {
        max-height: calc(100vh - $modalTopPaddingMobile - $modalHeaderHeight);
        padding: 0 16px;
      }
    }

    &.hasOnlyFooter {
      .ModalBody {
        max-height: calc(100vh - $modalTopPaddingMobile);
      }
    }
  }

  .ModalBody,
  .ModalHeader {
    padding: 0 $modalBodyLeftRightPaddingMobile;
  }
}
