.Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 13px 16px 28px 16px;
  background-color: var(--color-gray-800);

  .Header {
    display: flex;
    align-items: center;
    gap: 24px;

    .Info {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;

      .LogoWrapper {
        $logoSize: 52px;
        $logoGap: 10px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        .Circle {
          position: absolute;
          width: $logoSize;
          height: $logoSize;
          border-radius: 50%;
          inset: 0;
          background: linear-gradient(
                          45deg,
                          #ffcc16 0%,
                          #ff2a2a 25%,
                          #da02bc 50%,
                          #ff2a2a 75%,
                          #ffcc16 100%
          );
          padding: 3px;
          animation: rotateInCircle 1.5s ease-in-out infinite;
        }

        @keyframes rotateInCircle {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .Logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc($logoSize - $logoGap);
          height: calc($logoSize - $logoGap);
          border-radius: 50%;
          box-shadow: 0 0 0 2.9px var(--color-gray-800);
          object-fit: cover;
          z-index: 1;
          background-color: var(--color-gray-700);
          display: flex;
          align-items: center;
          justify-content: center;

          h2 {
            color: var(--color-pos-neutral-3);
          }
        }
      }

      .Details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        .Name {
          align-self: stretch;
          color: var(--color-text-white);
        }

        //.Fee {
        //  color: var(--color-text-white);
        //  opacity: 0.38;
        //}
      }
    }

    .Options {
      display: flex;
      align-items: center;
      gap: 12px;

      .Language {
        cursor: pointer;
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        border-radius: 48px;
        border: 1px solid var(--color-very-dark-gray);
        background: var(--color-gray-700);
      }

      .Hamburger {
        cursor: pointer;
        position: relative;
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        border: 1px solid var(--color-very-dark-gray);
        background: var(--color-gray-700);
      }
    }
  }

  .Footer {
    display: flex;
    align-items: center;
    gap: 12px;

    .SearchWrapper {
      cursor: pointer;
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 24px;
      border: 1px solid var(--color-very-dark-gray);
      background: var(--color-gray-700);

      .Search {
        width: 24px;
        height: 24px;
      }

      .Text {
        color: var(--color-text-low);
      }
    }

    .Filter {
      cursor: pointer;
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 10px;
      border-radius: 48px;
      border: 1px solid var(--color-very-dark-gray);
      background: var(--color-gray-700);
    }
  }
}

.LanguagesContainer {
  max-height: 90vh;

  .LanguagesModal {
    background-color: var(--color-text-white);

    .Header {
      padding: 24px 16px 12px;
      position: relative;

      .Title {
        color: var(--color-text-black-high);
        text-align: center;
      }

      .Close {
        position: absolute;
        top: 24px;
        right: 16px;

        &:dir(rtl) {
          left: 16px;
          right: auto;
        }
      }
    }

    .Options {
      max-height: calc(90vh - 60px);
      overflow-y: auto;
      scrollbar-width: none;
      padding: 20px 16px 0;

      .Option {
        list-style: none;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        align-items: center;
        position: relative;
        cursor: pointer;

        &:not(:first-child) {
          padding-top: 16px;
        }

        &:last-child {
          padding-bottom: 16px;
        }

        &:not(:last-child) {
          padding-bottom: 12px;

          &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: var(--color-gray-30);
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        .Details {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;

          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            flex-shrink: 0;
            object-fit: cover;
          }

          .Name {
            color: var(--color-text-black-high);
          }
        }
      }
    }
  }
}
