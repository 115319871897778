.ReservationMap {
  position: fixed;
  overflow: scroll;
  z-index: 9999;
  left: 0;
  top: 0;
  padding-bottom: 150px;
  width: 100%;
  height: 100%;
  background-color: var(--color-text-white);
  //border-radius: 40px;
  .ReservationStepperPreview {
    margin: 16px;
  }
  &::-webkit-scrollbar{
    display: none;
  }
}

.ReservationMapHeader {
  background-color: var( --color-mini-button-reverse);
  height: 60px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg{
    width: 40px;
    height: 40px;
  }
}

.ReservationMapHeaderTitle {
  color: var(  --color-text-white);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.ReservationMapHeaderCloseButton.CloseButton {
  background: none;
}

.ReservationMapBody {
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  justify-content: center;

}

.ReservationMapBodyViewContainer {
  border-color: var(--color-inverse-25);
  border-style: solid;
  border-radius: 5px;
  margin: 18px auto;
}

.ReservationMapBodyViewContent {
  background-color: var(--color-text-white);
  border-radius: 6px;
  overflow: hidden;
  cursor: grab;
}

.ReservationMapBodyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .Dropdown {
    width: 50%;
  }
}

.ReservationMapBodyZoomController {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.ReservationMapBodyZoomControllerTitle {
  color: var(--color-text-black);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.ReservationMapBodyZoomControllerButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ReservationMapBodyZoomControllerPlusButtonContainer,
.ReservationMapBodyZoomControllerMinusButtonContainer {
  cursor: pointer;
  display: flex;
  padding: 9px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--color-mini-button);
}

.ReservationMapBodyZoomControllerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  svg {
    width: 28px;
    height: 28.002px;
    flex-shrink: 0;
  }
}

.ReservationMapBodyCheck {
  display: flex;
  gap: 12px;
  align-items: center;
}

.ReservationMapBodyCheckAllTables {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ReservationMapBodyCheckAllTablesStatus {
  width: 10px;
  height: 10px;
  background-color: var(--color-stroke);
  border-radius: 50%;
}

.ReservationMapBodyCheckAllTablesTitle {
  color: var(--color-inverse-100);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.ReservationMapBodyCheckSelectedTables {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ReservationMapBodyCheckSelectedTablesStatus {
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
}

.ReservationMapBodyCheckSelectedTablesTitle {
  color: var(--color-inverse-100);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.ReservationMapBodyViewFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReservationMapBodyContinueButtonTitle{
  h4{
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
  }
}

.mock-border {
  border-radius: 40px; /* Mock içində border-radius 40px olur */
}

.no-border {
  border-radius: 0px;  /* Mockdan çıxanda border-radius 0px olur */
}