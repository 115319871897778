.PaymentResultModalHeader {
  padding: 30px 16px;
  .PaymentResultModalIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .CloseBTN {
      position: absolute;
      right: 0;
    }
  }
  h1 {
    color: var(--color-text-black-high);
    text-align: center;
    margin-top: 13px;
  }
}
.PaymentResultModalBody {
  width: 100%;
  padding: 0 16px;
  h4 {
    color: var(--color-text-black-high);
    text-align: center;
  }
}
.PaymentResultModalFooter {
  width: 100%;
  padding: 30px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  h4 {
    color: var(--color-text-black-high);
  }
  .PaymentUserCardDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;

      .PaymentCardIcon {
        width: 32px;
        height: 32px;
        color: white;
        path{
          fill: var(--color-text-black-high);
        }
      }
      .PaymentUserProfile {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 32px;
        min-width: 32px;
        height: 32px;
        background-color: var(--color-gray);
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        .UserWithoutProfilePicturePhoto {
          width: 25.6px;
          height: 25.6px;
          position: absolute;
          bottom: -3px;
        }
      }
      .PaymentUserProfilePicture {
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 32px;
          min-height: 32px;
        }
      }
      .PaymentUserProfileName {
        width: calc(100% - 45px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
