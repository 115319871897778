.SuperAdminInfra {
  padding: 30px;
  background-color: var(--color-mini-button);
  .PageTitle {
    margin-bottom: 32px;
    background-color: var(--color-mini-button);
  }
}
.SuperAdminInfraContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.SuperAdminInfraGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.SuperAdminInfraCaptionUpdateButton {
  width: 139px;
  min-height: 46px;
  img {
    margin-right: 10px;
  }
}
.SuperAdminInfraCards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
