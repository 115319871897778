.MenuItemPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray);
  font-size: 14px;

  h4 {
    color: var(--color-text-black);
  }

  & > * + * {
    margin-left: 5px;
  }

  .lineThrough {
    font-weight: 500;
  }
}
