.WifiModalBody {
  min-height: fit-content;
}
.MascotDashboardWifeModal {
  .ModalBody {
    padding: 0 16px;
    margin-top: 40px;
  }
}
.WifiTitle{
  display: flex;
  gap: 12px;
  align-items: center;

  img{
    width: 40px;
    height: 40px;
  }
}
.WifiModalQR{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  img{
    width: 142px;
    height: 142px;
  }

}

.WifiModalBodyDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;

  .WifiModalBodyDetail {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap: 4px;

    :first-child {
      color: var(--color-inverse-50);
    }

    .WifiValue {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
    }

    .WifiName,
    .WifiPassword {
      text-wrap: nowrap;
    }
  }

  .WifiModalCopy {
    cursor: pointer;
    padding: 4px;
  }

  .WifiModalCopiedText {
    position: absolute;
    left: 0;
    bottom: -15px;
    color: var(--color-success);
  }
}

.WifiModalBodyNetworks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  background: var(--color-text-white);
  padding: 8px 0;
  margin-bottom: 8px;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * + * {
    margin-left: 8px;
  }

  div {
    border-radius: 8px;
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 100px;

    background-color: var(--color-mini-button-reverse);

    > h6 {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
    }

    &.isSelectedWifi {
      background: var(--color-text-yellow);

      .WifiModalBodyNetworkName {
        color: var(--color-text-black);
      }
    }

    &:last-child {
      margin-right: 6px;
    }

    .WifiModalBodyNetworkName {
      white-space: nowrap;
      color: var(--color-text-white);
    }
  }
}
