@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.ReservationDetail {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  background-color: var(--color-text-white);
  box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.04);
}

.ReservationDetailStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: var(--color-inverse-50);
    margin-left: 4px;
  }
}

.ReservationDetailGuestName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  h6 {
    @include text-truncate-ellipsis(2);
    word-break: break-word;
    width: 50%;
  }
}

.ReservationDetailGuestInfos {
  display: flex;
  justify-content: space-between;
}

.ReservationDetailGuestInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;

    path {
      fill: var(--color-text-black);
    }
  }

  .ReservationInfoDetailCalendarInput {
    background-color: transparent;
    font-size: 16px;
  }

  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-word;
    max-width: 58px;
  }

  h5 {
    margin: 0 5px;
  }
}

.ReservationDetailComment {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  background: var(--admin-and-waiter-d-opacity-15, rgba(255, 202, 64, 0.15));

  h6:last-child {
    @include text-truncate-ellipsis(2);
    word-break: break-word;
    margin-left: 4px;
  }
}

.ReservationDetailActionButtons {
  display: flex;
  gap: 8px;
}
