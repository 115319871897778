.OrderMenuItemWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  > div {
    min-width: 128px;
    width: 162px;
    height: 130px;
    background-color: var(--color-light-gray);
    &:hover{
      background-color: var(--color-inverse-25);
    }
  }
}
