.AdminRadioSelectOption {
  cursor: pointer;

  &.typeA {
    border: 1px solid var(--color-stroke);
    max-width: 220px;
    min-width: 220px;
    padding: 16px 28px;
    border-radius: 12px;

    .AdminRadioSelectOptionHeader {
      margin-bottom: 8px;
    }

    img {
      width: 164px;
      max-width: 164px;
      height: 256px;
      object-fit: cover;
    }
  }

  &:hover {
    background-color: var(--color-mini-button);
  }

  &.isSelected {
    background-color: var(--color-group-background-light-mode);
  }

  &.typeB,
  &.typeColor {
    min-width: 130px;
    max-width: 130px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &.typeColor {
    .RadioSelection {
      margin-bottom: 4px;
    }
  }

  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.AdminRadioSelectOptionHeader {
  display: flex;
  align-items: center;

  .RadioSelectionCircle {
    margin-right: 4px;
  }
}

.RadioSelectOptionColorContainer {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.AdminRadioSelectOptionLabel {
  display: flex;
  align-items: center;

  h6 {
    margin-left: 8px;
  }
}
