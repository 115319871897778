@import "src/assets/styles/variables";

.AddPhotoButton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px dashed var(--color-admin-primary);
  &:hover {
    background: var(--Admin-and-Waiter-D-Primary, #ffca40);
    svg {
      path {
        stroke: var(--color-text-white);
      }
    }
    h5 {
      color: var(--color-text-white);
    }
  }

  h6 {
    display: none;
  }

  h5 {
    color: var(--color-admin-primary);
    text-decoration: underline;
  }
}

.AddPhotoButtonIcon {
  width: 40px;
  height: 40px;
}

.AddPhotoButtonInner {
  width: 100%;
  height: 100%;
  background: var(--color-opacity-5);

}

.AddPhotoButtonFileInput {
  display: none;
}

@media (max-width: $mobile) {
  .AddPhotoButton {
    flex-direction: column;

    h6 {
      display: block;
    }
  }

  .AddPhotoButtonIcon {
    margin-bottom: 5px;
  }

  .AddPhotoButtonImageFormat {
    color: var(--color-gray);
  }
}
