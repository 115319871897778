.SelectedCardContainer {
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  background: var(--Light-Mode-Mini-Button, #f7f7f7);

  .SelectedCardHeader {
    display: flex;
    align-items: center;
    padding: 11px 16px;

    .TitleDropdownWrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .SelectedCardHeaderTitle {
        margin-right: auto;
        margin-left: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.isOpen {
    .SelectedCardBody {
      display: block;
    }

    .TitleDropdownWrapper img {
      transform: rotate(180deg);
    }
  }

  .SelectedCardBody {
    padding: 0 16px 16px;
    display: none;

    .SelectedCardBodyItem {
      padding: 8px 16px;
      background: #fff;
      margin-bottom: 8px;
      border-radius: 6px;

      &.onError {
        background: #fde3e1;

        .SelectedCardBodyItemErrorMessage {
          display: block;
        }

        .RadioButton {
          background: #efeded;
          cursor: not-allowed;
        }
      }

      &.allItemSelect {
        padding: 16px 16px 0;
        margin-bottom: 4px;
      }

      .SelectedCardBodyItemWrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        &.SelectedCardBodyItems {
          padding: 16px 16px 0;
          margin-bottom: 4px;
        }

        &:hover {
          cursor: pointer;
        }

        > h6 {
          margin-right: auto;
          margin-left: 10px;
        }

        .SelectedCardBodyItemButton {
          border-radius: 8px;
          background: var(--Light-Mode-Mini-Button, #f7f7f7);
          color: var(--color-inverse-100);
          padding: 8px 16px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;

          &:hover {
            background: var(--Light-Mode-Button, #e5e5e5);
          }
        }
      }

      .SelectedCardBodyItemErrorMessage {
        display: none;
        padding: 0 4px;
        margin-top: 4px;

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: var(--Light-Mode-Error, #f34237);
        }
      }
    }

    h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .RadioButtonContainer {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }
}

.SelectedCardBodyAllList {
  background: white;
  border-radius: 6px;
  margin-bottom: 8px;

  .SelectedCardBodyListItem {
    background: #fff;
    padding: 8px 16px;
    margin-bottom: 4px;

    .SelectedCardBodyListItemWrapper {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .SelectedCardBodyListItemImage {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        margin-left: 12px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    &.onError {
      background: #fde3e1;

      .SelectedCardBodyListItemErrorMessage {
        display: block;
      }

      .RadioButton {
        background: #efeded;
        cursor: not-allowed;
      }
    }

    .SelectedCardBodyListItemErrorMessage {
      display: none;
      padding: 0 4px;
      margin-top: 4px;

      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--Light-Mode-Error, #f34237);
      }
    }
  }

  .SelectedCardBodyList {
    padding-bottom: 8px;
  }
}
