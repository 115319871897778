@import 'src/assets/styles/_variables';

.Root {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  padding: 28px;
}


.Container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: $mobile) {
  .Root{
    padding: 16px 16px 30px 16px;
  }
}