@import "src/assets/styles/mixins";

.Root {
  padding: 10px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.isLoading {
    align-items: center;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.Circle{
    border-radius: 50%;
  }

  &.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    svg {
      path {
        stroke: var(--color-gray-50);
      }
    }
  }

  &.Primary {
    background-color: var(--color-pos-brand-colors-primary-normal);
    &.isDisabled {
      background-color: var(--color-gray-30);
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-primary-pressed);
    }
  }

  &.Secondary {
    background-color: var(--color-pos-brand-colors-secondary-low);
    &.isDisabled {
      background-color: var(--color-gray-30);
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-secondary-medium);
    }
  }

  &.Tertiary {
    border: 1px solid var(--color-pos-neutral-3);
    background-color: var(--color-pos-utility-colors-white);
    &.isDisabled {
      background-color: var(--color-pos-utility-colors-white);
    }

    &:active,
    &:hover {
      background: var(--color-pos-brand-colors-secondary-normal);
      border: none;
    }
    .isLoading {
      background-color: var(--color-pos-utility-colors-white);
    }
  }

  &.Neutral {
    border: 1px solid var(--color-pos-neutral-2);
    color: var(--color-pos-neutral-3);
    background-color: var(--color-pos-neutral-3);
    &:active,
    &:hover {
      background-color: var(--color-pos-neutral-2);
    }
  }

  &.Text {
    background-color: transparent;
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-secondary-low);
    }
  }

}


