@import "src/assets/styles/_variables";
.MapSidebarButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
  background: var(--color-inverse-85);
  cursor: pointer;
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.resetMap {
    background-color: #f7f7f7;
    .MapSidebarButtonTitle {
      color: var(--color-error);
    }
  }
}

.MapSidebarButtonIcon {
  width: 27px;
  height: 27px;
}
.MapSidebarButtonTitle {
  color: var(--color-text-white);
}
