@import "src/assets/styles/variables";

.LockScreen {
  background-color: var(--color-text-black);
  width: 100vw;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.LockScreenContainer {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LockScreenLogo {
  width: 64px;
  height: 55px;
}

.LockScreenDisplay {
  text-align: center;
  width: 100%;
  min-height: 54px;
  padding: 16px 0;
  background-color: var(--color-mini-button-reverse);
  border: 1px solid var(--color-mini-button-reverse);
  border-radius: 16px;
  position: relative;

  h3 {
    text-transform: uppercase;
  }

  &.hasError {
    border-color: var(--color-dark-mode-error);
  }
}

.LockScreenDisplayValue {
  color: var(--color-text-white);
}

.LockScreenErrorMessage {
  color: var(--color-dark-mode-error);
  position: absolute;
  bottom: -8px;
  left: 4px;
  z-index: 4;
  transform: translateY(100%);
}

.LockScreenKeyboard {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 24px 30px;

  margin-top: 40px;
}

.LockScreenKeyboardBtn {
  padding: 28px 35px;
  width: 80px;
  height: 80px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: var(--color-background-light-mode);
  transition: 0.3s all ease;

  &:active {
    background-color: var(--color-inverse-25);
  }

  &.LockScreenKeyboardBtnClear {
    background-color: var(--color-error);
    padding: 22px;

    path {
      stroke: var(--color-background-light-mode);
    }
  }
  &.LockScreenKeyboardBtnConfirm {
    background-color: var(--color-success);
    padding: 22px;

    path {
      stroke: var(--color-background-light-mode);
    }
  }
}

.LockScreenAlternative {
  color: var(--color-text-white);
  margin: 32px 0;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: var(--color-background-light-mode);
    height: 1px;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.LockScreenSignIn {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 18px;
  }
  h4 {
    color: var(--color-text-white);
  }
}

.LockScreenSignInLink {
  color: var(--color-primary);
  margin-left: 8px;
}

@media (max-width: $tablet), (max-height: 800px) {
  .LockScreenContainer {
    margin-top: 0;
  }

  .LockScreenKeyboard {
    margin-top: 32px;
    gap: 28px 22px;

    .LockScreenKeyboardBtn {
      width: 80px;
      height: 70px;

      &.LockScreenKeyboardBtnClear {
        padding: 12px;
      }
      &.LockScreenKeyboardBtnConfirm {
        padding: 12px;
      }
    }
  }

  .LockScreenAlternative {
    margin: 24px 0;
  }
}

@media (max-width: $mobile), (max-height: 600px) {
  .LockScreenKeyboard {
    margin-top: 24px;
    gap: 16px 10px;

    .LockScreenKeyboardBtn {
      width: 85px;
      height: 65px;
      padding: 20px 24px;
      border-radius: 10px;
      &.LockScreenKeyboardBtnClear {
        padding: 8px;
      }
      &.LockScreenKeyboardBtnConfirm {
        padding: 8px;
      }
    }
  }
  .LockScreenSignIn {
    > h4 {
      font-size: 14px;
    }

    .LockScreenSignInLink {
      font-size: 14px;
    }
  }
}

@media (max-height: 490px) {
  .LockScreenKeyboard {
    margin-top: 16px;
    gap: 12px 8px;

    .LockScreenKeyboardBtn {
      width: 75px;
      height: 55px;
      padding: 16px 20px;
      border-radius: 8px;

      &.LockScreenKeyboardBtnClear {
        padding: 6px;
      }

      &.LockScreenKeyboardBtnConfirm {
        padding: 6px;
      }
    }
  }

  .LockScreenContainer {
    width: 260px;

    .LockScreenDisplay {
      min-height: auto;
      padding: 12px 0;
    }

    .LockScreenSignIn {
      > h4 {
        font-size: 14px;
      }

      .LockScreenSignInLink {
        font-size: 14px;
      }
    }
  }

  .LockScreenAlternative {
    margin: 16px 0;
  }
}

@media (max-height: 400px) {
  .LockScreenKeyboard {
    margin-top: 16px;
    gap: 12px 8px;

    .LockScreenKeyboardBtn {
      width: 75px;
      height: 45px;
      padding: 16px 20px;
      border-radius: 8px;

      &.LockScreenKeyboardBtnClear {
        padding: 6px;
      }

      &.LockScreenKeyboardBtnConfirm {
        padding: 6px;
      }
    }
  }

  .LockScreenContainer {
    width: 260px;

    .LockScreenDisplay {
      min-height: auto;
      padding: 12px 0;
    }

    .LockScreenSignIn {
      > h4 {
        font-size: 14px;
      }

      .LockScreenSignInLink {
        font-size: 14px;
      }
    }
  }

  .LockScreenAlternative {
    margin: 16px 0;
  }
}
