@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.AdminOrderGuestInfoModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdminOrderGuestInfoModalBodyWrapper {
  height: 540px;
  min-width: 560px;

  .SpinnerContainer {
    width: 100%;
    height: 100%;
  }
}

.AdminOrderGuestInfoContainer {
  .ModalBackground {
    .ModalContainer {
      .Modal {
        padding-top: 0;
        overflow: scroll;
        scrollbar-width: none;

        .ModalHeader {
          position: sticky;
          z-index: 2;
        }

        .ModalBody {
          overflow-y: visible !important;
        }

        .ModalFooter {
          position: static;
        }
      }
    }
  }
}

.AdminOrderGuestInfoModalBody {
  width: 560px;

  .GuestMainInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .HappyBirthday {
      width: 108px;
      height: 74px;
    }

    .GuestProfile {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;

      h3 {
        @include text-truncate-ellipsis(3);
        word-break: break-all;
      }

      .BirthdayHat {
        transform: rotate(-45deg);
        width: 73px;
        height: 73px;
        position: absolute;
        top: -38px;
        left: -35px;
        z-index: 1;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .GuestDetailInfoContainer {
    display: flex;
    gap: 16px;
    margin: 16px 0;
    width: 100%;

    div {
      width: 50%;

      h5 {
        text-transform: capitalize;
      }

      h6 {
        color: var(--color-inverse-50);
      }
    }
  }

  .GuestTotalInfoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    div {
      width: 50%;
      height: 100px;
      border-radius: 12px;
      background: var(--color-mini-button);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border: 1px solid var(--color-stroke);

      h6 {
        color: var(--color-inverse-25);
      }
    }
  }

  .GuestTopOrderContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 16px 0 32px 0;

    div {
      width: calc(100% / 3);
      height: 100px;
      border: 1px solid var(--color-stroke);
      border-radius: 12px;
      background: var(--color-mini-button);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 8px;

      h5 {
        text-align: center;
        @include text-truncate-ellipsis(3);
        word-break: break-all;
      }

      span {
        color: var(--color-inverse-50);
      }
    }
  }
}

.AdminOrderGuestInfoModalFooter {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 22px;
}

@media (max-width: $mobile) {
  .AdminOrderGuestInfoModalBodyWrapper {
    min-width: auto;
  }
}
