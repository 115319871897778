@import "src/assets/styles/mixins";

.AdminDetailedChat {
  display: flex;
  height: 100vh;
}

.AdminChatSidebar {
  width: 40%;
  border-right: 1px solid var(--color-stroke);
}

.AdminChatSidebarTools {
  padding: 24px 16px;
}

.AdminChatSidebarToolsSearch {
  display: flex;

  & > * {
    width: 50%;

    &.Search {
      max-width: 50%;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.AdminChatSidebarToolsFilterOptions {
  display: flex;
  margin-top: 16px;
}

.AdminChatSidebarToolsFilterOption {
  padding: 15px 24px;
  background-color: var(--color-mini-button);
  border-radius: 24px;
  cursor: pointer;
  position: relative;

  &.isSelected {
    background-color: var(--color-admin-primary);

    .AdminChatSidebarToolsFilterOptionUnreadMessageCount {
      display: none;
    }
  }

  &:not(.isSelected):hover {
    background-color: var(--color-group-background-light-mode);
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

}

.ChatLineItems {
  height: calc(100vh - 157px);
  padding: 0 11px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
  }
}

.ChatLineItem {
  cursor: pointer;
  padding: 5px;

  &.isSelected {
    .ChatLineItemContent {
      background-color: var(--color-group-background-light-mode);
    }

    .ChatLineAvatar {
      background-color: var(--color-background-light-mode);
    }
  }

  &:not(.isSelected):hover {
    .ChatLineItemContent {
      background-color: var(--color-mini-button);
    }
  }
}

.ChatLineItemContent {
  padding: 5px 8px 5px 5px;
  display: flex;
  align-items: center;
  border-radius: 16px;
}

.ChatLineAvatar {
  margin-right: 12px;
  background-color: var(--color-group-background-light-mode);
  border-radius: 12px;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChatLineItemDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.ChatLineItemPreview {
  flex: 1;

  h5 {
    width: calc(100% - 8px);
    white-space: nowrap;
    word-break: break-all;
    @include text-truncate-ellipsis(1)
  }


  h6 {
    margin-top: 8px;
    white-space: nowrap;
    word-break: break-all;
    @include text-truncate-ellipsis(1)
  }
}

.ChatLineItemDetailsUnreadMessageCount {
  border-radius: 40px;
  background-color: var(--color-admin-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  margin-top: 8px;
}

.AdminChatSidebarToolsFilterOptionUnreadMessageCount {
  border-radius: 40px;
  background-color: var(--color-admin-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(4px, -4px);
}

.ChatLineItemDetailsDate {
  h6 {
    color: var(--color-inverse-75);
  }

  &.isUnread {
    h6 {
      color: var(--color-secondary);
    }
  }
}

.AdminDetailedChatHeader {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-stroke);
  background-color: var(--color-background-light-mode);

  .AdminChatCloseBtn {
    height: 84px;
    width: 100px;
    margin-left: 10px;
  }
}

.AdminDetailedChatHeaderOrderInfo {
  width: 100%;
  display: flex;
  align-items: center;
}

.AdminDetailedChatHeaderOrderActions {
  display: flex;
  align-items: center;
}

.AdminDetailedChatHeaderOrderInfoOrderBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mini-button);
  padding: 15px 24px;
  width: 100px;
  height: 84px;
  border-radius: 8px;
  border: none;

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: var(--color-group-background-light-mode);
  }
}


.AdminDetailedChatHeaderOrderInfoPreview {
  background-color: var(--color-group-background-light-mode);
  padding: 14px;
  border-radius: 16px;
  margin-right: 16px;
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.AdminDetailedChatHeaderOrderInfoDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AdminDetailedChatNoSelectedTopic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .AdminDetailedChatHeader {
    justify-content: flex-end;
  }
}

.AdminDetailedChatHeaderOrderInfoDetailTopicName {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  h3 {
    color: var(--color-text-black);
  }

  h4 {
    color: var(--color-text-black);
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    position: relative;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.AdminDetailedChatHeaderOrderInfoDetailEditBtn {
  padding: 15px 24px;
}

