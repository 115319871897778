@import "src/assets/styles/variables";

.CalendarModalContainer {
  .Modal {
    padding: 0;
    border-radius: 15px;
  }


  .ModalHeader {
    @media (min-width: $mobile) {
      display: none;
    }
  }

  .ModalBody {
    padding: 0;
  }

  @media (max-width: $mobile) {
    .Modal {
      padding: 70px 0 30px;
      border-radius: 16px 16px 0 0;
    }
  }

  .CalendarModalHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .CalendarModalBody {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile) {
      margin: 0 12px;
    }
  }
}

.CalendarPicker {
  width: 358px;
  border-radius: 15px;
  border: 1px solid var(--color-stroke);
  padding: 20px 12px;

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid var(--color-stroke);
  }
  .react-calendar__month-view__weekdays__weekday {
    font-size: 12px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    *{
      background-color: var(--color-text-white);
    }
    .react-calendar__navigation__label {
      width: 150px;
      pointer-events: none;
      user-select: none;
      flex-grow: 0 !important;
    }

    button {
      span {
        color: var(--color-text-black);
        font-weight: 600;
        line-height: 20px;
        font-size: 16px;
      }
      &:disabled {
        background: transparent;
        color: var(--color-inverse-25);
      }
    }
  }
}

.CalendarPickerTile {
  padding: 10px;
  border-radius: 50%;
  width: 36px;
  height: 49px;
  line-height: 19px;

  &.react-calendar__tile--now {
    background: var(--color-mini-button);
  }

  &.react-calendar__tile:disabled {
    background: none;
  }


  &.react-calendar__tile--active {
    background: var(--color-primary) !important;
    &:focus {
      background: var(--color-primary) !important;
    }
  }

  &:disabled {
    background-color: transparent;
    cursor: default;
    abbr {
      color: var(--color-inverse-25);
    }
  }
}
