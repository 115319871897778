@import "src/assets/styles/variables";

.Root {
  width: 100vw;
  height: 100dvh;
  position: relative;
  overflow: hidden;
  display: flex;
  *{
    font-family: $fontTTHoves;
  }
}

.SidebarToggleBtn {
  position: absolute;
  width: 56px;
  height: 56px;
  right: 16px;
  bottom: 28px;
  z-index: 5;
  background-color: var(--color-pos-utility-colors-black);
  padding: 16px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
}

.Page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 164px);

  &.isHomePage {
    width: 100%;
  }
}

.PageHeader {
  display: none;
}

.Content {
  background-color: var(--color-background-light-mode);
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: $mobile) {
  .Page {
    width: 100%;
  }
  .PageHeader {
    display: flex;
  }

  .Root {
    overflow-y: auto;

    .AdminSidebarController {
    }

    .SidebarToggleBtn {
      display: block;
    }
  }

  .SidebarToggleBtn {
    display: flex;
  }
}
