@import "src/assets/styles/variables";

.Root {
  width: 100vw;
  height: 100dvh;
  background-color: var(--color-light-mode-black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  pointer-events: none;
  &.isVanish {
    transition: 400ms;
    background-color: transparent;
    .Container {
      transition: 400ms;
      opacity: 0;
      * {
        animation: none;
      }
      .Text {
        top: 0;
      }
      .Logo {
        margin-bottom: 16px;
      }
    }
  }
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LogoContainer {
  animation: bounce 2s ease infinite;
  animation-delay: 2s;
  transform-origin: bottom;
}

.Logo {
  animation: logoAnimation 2s forwards;
  width: 106px;
  height: 70px;
}

.TextContainer {
  height: 29px;
  width: 154px;
  position: relative;
  overflow: hidden;
}

.Text {
  animation: textAnimation 2s forwards;
  position: absolute;
  top: -100%;
}


@keyframes textAnimation {
  50% {
    top: -100%;
  }
  90% {
    top: 0;
  }
  100% {
    top: 0;
  }
}

@keyframes logoAnimation {
  0% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(1);
  }
  50% {
    margin-bottom: 0;
  }
  90% {
    margin-bottom: 16px;
  }
  100% {
    margin-bottom: 16px;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  70%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(5px);
  }
}

@media (min-width: $tablet) {
  .Root {
    border-radius: 40px;
    width: 500px;
    height: 700px;
  }
}
