@import "src/assets/styles/_variables";

.Price {
  h4 {
    color: var(--color-preferred-secondary-text);
  }
}

.lineThrough {
  color: var(--color-discount-price);
  text-decoration: line-through;
}

.ModificationPrice {
  font-size: 14px;
  line-height: 17px;
}
