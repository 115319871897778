.AdminDashMenuByOrder {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  border-radius: 16px;
  background: #fff;
  margin-top: 32px;
  overflow: hidden;

  .AdminDashMenuByOrderTitleAndElement {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;

    .AdminDashMenuByOrderTitleAndSubTitle {
      display: flex;
      align-items: center;
      gap: 4px;
      h3 {
        color: var(--color-text-black);
      }
      h5 {
        color: var(--color-inverse-75);
      }
    }

    .AdminDashMenuByOrderElementCustomElementSize .CustomDropDown {
      .DropdownOptions {
        max-height: 142px !important;
      }
    }
  }

  .AdminDashMenuByOrderFirstColumnAndSecondColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .AdminDashMenuByOrderFirstColumn {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .AdminMenuByOrderStatCards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
      }
    }

    .AdminDashMenuByOrderSecondColumn {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .AdminMenuByOrderStatCards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
      }
    }
  }

  .AdminDashMenuByOrderEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    padding: 20px 16px;
    width: 286px;
    .AdminDashMenuByOrderEmptyMessage {
      text-align: center;
      color: var(--color-inverse-50);
    }
  }
}

.AdminDashMenuByOrderWithoutOrder {
  height: 478px;
}
@media (max-width: 1048px) {
  .AdminDashMenuByOrder {
    .AdminDashMenuByOrderFirstColumnAndSecondColumn {
      flex-direction: column;
    }
  }
}
