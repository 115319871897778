.ReservationVR {
  position: fixed;
  overflow: hidden;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-mini-button-reverse);
}

.ReservationVRHeader {
  height: 60px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg{
    width: 40px;
    height: 40px;
  }
}

.ReservationVRHeaderTitle {
  color: var(--color-text-white);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.ReservationVRHeaderCloseButton.CloseButton {
  background: none ;
}

.ReservationVRBody {
  width: 100%;
  height: calc(100% - 60px);
}

.ReservationVRBodyIframe {
  border: none;
}
