.SpeechToTextContainerFooter {
  position: relative;
  padding: 16px;
  width: 100%;
  background-color: var(--color-text-white);
  z-index: 21;

  .ModalContainer {
    width: 167px;
    min-height: 195px;
    position: absolute;
    bottom: 158px;

    .Modal {
      padding-top: 40px;
      background-color: #32383E;

    }

    .ModalHeader {
      padding: 8px;
      height: 24px;
      margin-top: 8px;
      background-color: #32383E;

    }

    .ModalBody {
      padding: 8px;
    }

    .ModalFooter {
      height: auto;
      background-color: #32383E;
    }
  }

  .ChatFooterFormInput {
    .InputControlInput {
      border-radius: 26px;
      background: #32383e;
      height: 56px;
      padding-left: 55px;
      color: var(--color-text-white);

      border: none;
    }

    .InputControlLabel {
      color: var(--color-inverse-50);
    }
  }
}
