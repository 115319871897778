.AddCategoryModalHeader {
  display: flex;
  align-items: center;

  .AddCategoryModalHeaderTitle {
    margin-right: auto;
  }

  .AddCategoryModalHeaderTitlePublishMode {
    display: flex;
    align-items: center;

    h6 {
      margin-right: 10px;
      color: var(--color-inverse-50);
      white-space: nowrap;
    }
  }


  .AddCategoryModalDeleteButton {
    &.isDisabled {
      background-color: var(--color-mini-button);

      > svg {
        path {
          fill: var(--color-inverse-50);
        }
      }
    }
  }

  > div {
    margin-right: 10px;
  }
}

.AddCategoryModalBody {
  min-width: 500px;
  max-width: 600px;

  .AddCategoryModalBodyLine {
    margin: 20px 0;
    height: 1px;
    flex-shrink: 0;
    background-color: var(--color-stroke);
  }

  .AddCategoryModalBodySelectionTypes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .AddCategoryModalBodySelectionType {
      cursor: pointer;
      display: flex;
      padding: 24px 8px;
      align-items: center;
      gap: 9px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      background: var(--color-group-background-light-mode);
      border: 3px solid transparent;

      &.notAllowed {
        cursor: not-allowed;

        .RadioSelection {
          cursor: not-allowed;

        }
      }

      &.hasBorder {
        border: 3px solid var(--color-admin-primary);
        background: var(--color-group-background-light-mode);
      }

      .SelectionTypeWrapper {
        display: flex;
        align-items: center;
        gap: 9px;
        flex: 1 0 0;

        .RadioSelectionCircle {
          margin-right: 0;
        }

        .AddCategoryModalBodySelectionTypeTitle {
          flex: 1 0 0;
          color: var(--color-text-black);
        }
      }
    }
  }

  .AddCategoryModalBodyForm {
    margin-bottom: 56px;
    padding-top: 40px;
    width: 500px;

    &.maxWidth {
      width: auto;
    }

    .AddCategoryModalBodyFormInput {
      flex-grow: 1;
    }

    .AddCategoryModalBodyFormSchedule {
      margin-top: 40px;
      padding: 14px 8px;
      background-color: var(--color-mini-button);
      border-radius: 12px;
    }
  }
}