.NotOpenForReservation{
  height: 100%;
  .GuestPageHeader{
    padding:24px 16px ;
  }
  .EmptyStateContainer{
    height: calc(100% - 136px);
  }
  .ReservationStepperPreviewBusiness{
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 0 16px;
  }
  .ReservationStepperPreviewBusinessImage{
    img{
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
