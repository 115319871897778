.Chip {
  padding: 4px 8px;
}
.Title {
  text-wrap: nowrap;
}
.Circle {
  border-radius: 1000px;
}
.Square {
  border-radius: 6px;
}
.Small {
  .Title {
    font-size: 12px;
  }
}
.Medium {
  .Title {
    font-size: 14px;
  }
}
.Large {
  .Title {
    font-size: 16px;
  }
}
.Icon {
  width: 24px;
  height: 24px;
}
.Primary {
  background: var(--color-pos-utility-colors-dark-gray);
  .Title {
    color: var(--color-text-white);
  }
}
.Secondary {
  background: var(--color-pos-brand-colors-secondary-low);
  .Title {
    background: var(--color-pos-brand-colors-secondary-normal);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.Success {
  background: rgba(2, 192, 74, 0.16);
  .Title {
    color: var(--color-pos-semantic-colors-success);
  }
}
.Error {
  background: rgba(227, 21, 46, 0.16);
  .Title {
    color: var(--color-pos-semantic-colors-error);
  }
}
.Warning {
  background: rgba(239, 143, 0, 0.16);
  .Title {
    color: var(--color-pos-semantic-colors-warning);
  }
}
