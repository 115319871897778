.BasketRecommendations {
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  margin-left: -16px;
  margin-right: -16px;
  background-color: var(--color-group-background-light-mode);
  margin-bottom: 40px;

  &.search {
    background-color: transparent;

    .BasketRecommendationsCards {
      > div {
        border: 1px solid var(--Neutral-colors-Neutral-3, #EEEDF2);
      }
    }
  }

  .RecommendationTitle {
    padding: 12px 24px;
    color: var(--color-text-black-high);
  }

  .BasketRecommendationsCards {
    display: flex;
    gap: 12px;
    overflow-x: scroll;
    scrollbar-width: none;

    > :first-child {
      margin-left: 16px;
    }

    > :last-child {
      margin-right: 16px;
    }
  }
}

.Delay {
  opacity: 0;
  transform: translateY(20px) scale(0.5);
  animation: SelectableChipAnimation 0.3s ease-out forwards;
}

@keyframes SelectableChipAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}