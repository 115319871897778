.GuestComments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 8px;
  padding: 8px;
  background: var(--color-opacity-15);

  .GuestCommentsGuestNameAndGuestId {
    height: max-content;
    margin-bottom: auto;
    margin-top: 1.5px;
    color: var(--color-inverse-100);
  }
  .GuestAllComment {
    display: flex;
    gap: 12px;
    flex-direction: column;
    overflow: hidden;
    .GuestAllCommentCountAndContent {
      display: flex;
      gap: 10px;
      width: 100%;
      height: 100%;

      .GuestAllCommentCount {
        margin-top: 0.5px;
        color: var(--color-inverse-100);
      }
      .GuestAllCommentContent {
        list-style-type: none;
        line-height: 16px;
        position: relative;
        padding-left: 4px;
        padding-bottom: 3px;
        color: var(--color-inverse-100);
        width: 100%;
        height: 100%;
        word-break: break-word;

        &::before {
          content: "-"; /* Custom bullet */
          position: absolute;
          left: -7px;
          color: var(--color-inverse-100);
        }
      }
    }
  }
}
