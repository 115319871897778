@import 'src/assets/styles/_variables';

.AdminBusinessSettings {
  padding: 30px;
  background-color: var(--color-mini-button);
  min-height: 100vh;

  .PageTitle {
    background-color: var(--color-mini-button);
  }

  .AdminBusinessSettingsSaveButton {
    width: auto;
    height: 46px;
    padding: 12px 16px;

    svg{
      width: 20px;
    }
  }
}

.AdminBusinessSettingsInfo {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.AdminBusinessSettingsCategory {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: var(--color-text-white);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 16px;
  min-width: calc(50% - 16px);
}

.AdminBusinessSettingsCategoryTitle {
  margin-bottom: 8px;
}

.AdminBusinessSettingsSwitches {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.AdminBusinessSettingsSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.AdminBusinessInfoAboutPrintersAndWiFiTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.AdminBusinessInfoAboutPrintersAndWiFiListMessage {
  color: var(--color-inverse-50);
}

.AdminBusinessInfoAboutPrintersAndWiFi {
  .Tag {
    cursor: pointer;
  }
}

.AdminBusinessSettingsAddItems {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 50%;
  border-left: 1px solid var(--color-stroke);
}

.SwitchInfo {
  display: flex;
  gap: 12px;
  align-items: center;

  h6 {
    color: var(--color-inverse-25);
    width: 45px;

    &.isActive {
      color: var(--color-success);
    }
  }
}

.AdminBusinessSettingsIntegrations {
  margin-top: 24px;
  margin-bottom: 16px;
}

.AdminBusinessSettingsIntegrationContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: $mobile), (max-width: $tablet) {
  .AdminBusinessSettingsCategory {
    width: 100%;
  }
}
