@import "src/assets/styles/variables";

.Root {
  height: 100%;
  min-width: 164px;
  max-width: 164px;
  background: var(--color-text-white);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);
  border-right: 1px solid var(--color-pos-neutral-3);
  padding: 28px 10px;
  z-index: 6;
  transition: left 0.2s;
}

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Top {
  width: 100%;
  padding: 0 18px;
}
.Cancel {
  display: none;
}
.Nav {
  width: 100%;
  margin: 32px 0;
  height: calc(100% - 212px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &.hasLock {
    height: calc(100% - 160px);
  }
}

.Routes {
  padding: 0 18px 30px 18px;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.Back {
  padding: 0 18px;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 1px;
    width: 28px;
    background-color: var(--color-pos-neutral-1);
  }
}

.Bottom {
  width: 100%;
  padding: 0 18px;
  margin-top: auto;
}

.LockScreenBtnContainer {
  width: 100%;
  height: 48px;
}

.NetworkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

@media (max-width: $mobile) {
  .Root {
    position: absolute;
    left: -100%;
    z-index: 7;
    min-width: 261px;
    max-width: 261px;
    padding: 28px 0;
    background-color: var(--color-mini-button);
    &.isOpen {
      left: 0;
    }
  }
  .Top {
    padding: 0 16px;
  }
  .Nav {
    margin: 24px 0;
  }
  .Cancel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .Back {
    display: none;
  }
  .Routes {
    padding: 0 16px;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-content: flex-start;
    height: 100%;
  }
  .SelectButton {
    min-width: 110px;
    max-width: 110px;
    border-radius: 12px;
    background: #fff;
    padding: 16px 0;
  }
  .NetworkWrapper {
    justify-content: normal;
    gap: 19px;
    margin-top: 20px;
  }
}
