@import "src/assets/styles/_variables";

.Root {
  width: 100%;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  height: 68px;
  margin-bottom: 12px;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.User {
  max-width: 192px;
}

.PinLockBtnContainer {
  min-width: 160px;
  height: 52px;
}
.Wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
.Dropdown {
  width: 112px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $mobile) {
  .Root{
    background-color: transparent;
    box-shadow: none;
  }
  .PinLockBtnContainer {
    display: none;
  }
  .Dropdown{
    width: max-content;
  }
}
