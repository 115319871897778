@import "src/assets/styles/_variables";

.StatsCardChartWithPeriodWrapper {
  width: 100%;
  background-color: var(--color-background-light-mode);
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;


  .WrapperOfIconAndText {
    position: absolute;
    display: flex;
    gap: 16px;
    align-items: center;
    top: 32px;
    right: 28px;

    .IconAndText {
      display: flex;
      align-items: center;
      gap: 8px;
      .circle {
        width: 12px;
        height: 12px;
        background: #ffdd00;
        border-radius: 50%;
      }
    }
  }

  .periodsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 16px;
  }

  .titleAndPeriod {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .title {
    color: var(--color-text-black);
  }

  .ChartWrapper {
    width: 100%;
    height: 480px;
    //height: auto;
    overflow-x: auto;
    overflow-y: hidden;

  }
  .PeriodDropDown {
    display: none;
  }
}

@media (max-width: 1048px) {
  .StatsCardChartWithPeriodWrapper {
    overflow: hidden;


    .CustomChartPeriodSelectButton{
      width: initial;
    }
    .WrapperOfIconAndText {
      position: initial;
      margin-bottom: 32px;
    }
    .titleAndPeriod {
      justify-content: space-between;
      gap: unset;
    }
    .ChartWrapper {
      height: auto;
    }
  }
}

@media (max-width: $mobile) {
  .StatsCardChartWithPeriodWrapper {
    .CustomChartPeriodSelectButton {
      display: none;
    }
    .PeriodDropDown {
      display: initial;
      width: 50%;
    }
  }
}
