.QR {
  border-radius: 8px;
  border: 1px solid var(--color-gray);
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.QREmpty {
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;

  h6 {
    color: var(--color-inverse-50);
  }
}

.QRInfo {
  display: flex;
}

.QRStatusLinked {
  color: var(--color-success);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.QRStatusUnlinked {
  color: var(--color-error);
  padding-left: 5px;
}

.QRCreatedDate {
  color: var(--color-inverse-75);
  margin-top: 3px;
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.QRInfoLeft {
  padding: 20px;
  border-right: 1px solid var(--color-gray);
}

.QRImg {
  width: 142px;
  height: 142px;
}

.QRInfoRightTop {
  white-space: nowrap;

  h6 {
    display: flex;
    align-items: center;
  }
}

.QRInfoRight {
  width: calc(100% - 182px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.QRLinkCopyButton {
  &.isUnLinked {
    visibility: hidden;
  }
}

.QRLinkCopyButton,
.QRLinkButtons {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background: var(--color-mini-button);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--color-group-background-light-mode);
  }
}

.QRInfoRightBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.QRInfoRightBottomActions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.AdminTableModalBodyQRLink {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

}

.QRLinkModalCopiedText {
  position: absolute;
  color: var(--color-success);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}


.QRlinkButton {
  width: calc(100% - 168px);
  height: 46px;
  border-radius: 8px;
  background-color: var(--color-red-15);

  h6 {
    color: var(--color-error);
  }

  &:hover {
    background-color: var(--color-error);

    h6 {
      color: var(--color-text-white);
    }
  }

  &.isUnLinked {
    visibility: hidden;
  }
}

.QRLinkButtonsWhatsapp {
  width: 22px;
  height: 22px;
}
