.AITranslateModalHeader {
  display: flex;
  align-items: center;

  .AITranslateModalHeaderTitle {
    margin-right: auto;
  }
}

.AITranslateModalBody {
  width: 500px;
  padding-top: 15px;
  position: relative;

  .AITranslateModalBodyTitle {
    margin-bottom: 24px;
  }

  .AITranslateModalLanguageDropdowns {
    display: flex;
    gap: 16px;
    margin-bottom: 40px;

    .AITranslateModalLanguageDropdown {
      width: 100%;
    }
  }
}

.AITranslateModalFooterButton {
  background-color: var(--color-admin-primary);
  display: flex;
  justify-content: center;
  gap: 12px;
  opacity: 1;
  > div {
    width: 24px;
    height: 24px;
  }

  &.typeD, &.typeF {
    max-width: 100%;
    padding: 13px 32px;
    height: 52px;

    &:disabled{
      background-color: var(--color-mini-button);
      .CTAButtonName{
        color: var(--color-inverse-50);
      }
      svg{
        path{
          fill: var(--color-inverse-50);
        }
      }

    }

    .CTAButtonName  {
      font-size: 16px !important;
      line-height: 26px;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
  }

  .CTAButtonIcon {
    svg path {
      fill: var(--color-text-black);
    }
  }
}