@import "src/assets/styles/_variables";

.VerticalBarChart {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--color-background-light-mode);
  border-radius: 16px;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

@media (max-width: 1048px) {
  .VerticalBarChart {
    width: 150%;
  }
}

@media (max-width: $mobile) {
  .VerticalBarChart {
    width: 250%;
  }
}
