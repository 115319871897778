.WorkingHours {
  border-radius: 6px;
  background: var(--color-background-light-mode);
}

.WorkingHoursHeader {
  background-color: var(--color-opacity-15);
  padding: 12px 0;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--color-gray);
  border-right: 1px solid var(--color-gray);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  > h6 {
    &:first-child {
      width: 50%;
      padding-left: 16px;
    }
    &:not(:first-child) {
      width: 25%;
      text-align: center;
    }
  }
}

.WorkingHoursBodyDay {
  display: flex;
  align-items: stretch;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray);
  }

  &.isWorking {
    .WorkingHoursDropdown {
      h6 {
        color: var(--color-text-black);
      }
    }
  }

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      width: 50%;
      padding: 10px 0 10px 16px;

      h6 {
        margin-left: 28px;
      }
    }
    &:not(:first-child) {
      justify-content: center;
      width: 25%;
      text-align: center;
      border-left: 1px solid var(--color-gray);
    }
  }
}

.WorkingHoursBody {
  border: 1px solid var(--color-gray);
  border-top: none;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.WorkingHoursDropdown {
  width: 100%;

  h6 {
    text-align: center;
    width: 100%;
    color: var(--color-gray);
  }
}

.AdminBusinessInfoContact {
  margin-top: 40px;
}

.AdminBusinessInfoContactTitle {
  margin-bottom: 10px;
}
