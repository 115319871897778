@import "src/assets/styles/variables";

.SpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Spinner {
  border: 5px solid var(--color-stroke);
  border-top: 5px solid var(--color-admin-primary);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: $mobile) {
  .Spinner {
    border: 4px solid var(--color-stroke);
    border-top: 4px solid var(--color-admin-primary);
    width: 60px;
    height: 60px;
  }
}
