.GuestAuthForgetPassword {
  padding: 16px 16px 0;
  height: 100%;
}

.GuestAuthSignUpHeader {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 44px;

  h2 {
    position: absolute;
    text-wrap: nowrap;
    left: 50%;
    transform: translateX(-50%);
  }
}

.GuestAuthContent {
  .PrimaryButton {
    position: inherit;
    width: 100%;
    height: 52px;
  }
}