@import "src/assets/styles/_variables";

.ReservationList {
  padding: 16px 12px;
  border-radius: 16px;
  background: var(--color-mini-button);
  min-width: 350px;
  max-width: 400px;
  height: 100%;

  &.editMode {
    pointer-events: none;

    h3,
    h6,
    h5,
    span {
      color: var(--color-inverse-50);
    }

    svg {
      path {
        fill: var(--color-inverse-50);
      }
    }
  }

  .ReservationListContainer {
    height: 100%;
  }
}

.ReservationListTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  .DropdownLabel {
    background-color: var(--color-mini-button);
  }

  .Dropdown {
    width: 100%;
    border-radius: 8px;
  }
}

.ReservationStats {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 16px 0;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 4px;

    h6 {
      width: 45px;
    }
  }
}

.ReservationListContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 90px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
