@import "src/assets/styles/mixins";

.Container {
  position: sticky;
  top: 0;
  padding: 8px 0;
  z-index: 2;
  background-color: var(--color-mini-button);
  margin-top: 12px;
}

.Wrapper {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.isScrollable {
  padding-left: 10px;
}

.Item {
  padding: 12px;
  border-radius: 24px;
  cursor: pointer;

  &.isActive {
    background-color: var(--color-admin-primary);

    .Name {
      color: var(--color-text-black-high);

    }
  }
}

.Name {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.60);
  //@include text-truncate-ellipsis(1);
  //word-break: break-word;
}
