.AddTabModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AddTabModalBody {
  width: 500px;
  margin-top: 10px;
  margin-bottom: 278px;
}
