.CustomTooltip {
  background-color: var(--color-text-white);
  padding: 8px 10px;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.09);
  .CustomTooltipLegends {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .LegendInfo {
      display: flex;
      gap: 4px;
    }
  }

  .CustomTooltipLegendInfo {
    color: var(--color-text-black);
  }
}
