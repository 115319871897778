.ModificationOption {
  background-color: var(--color-mini-button);
  border-radius: 10px;
  padding: 8px 14px;
}

.ModificationOptionCheckbox {
  display: flex;
  flex-direction: column;
}

.ModificationOptionTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ModificationOptionPrice,
.ModificationOptionMaxNumber {
  margin-top: 20px;
}
