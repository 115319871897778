@import "src/assets/styles/_variables";

.DeleteButton {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-red-15);
  cursor: pointer;
  border: 1px solid transparent;

  &.typeA {
    width: 40px;
    height: 40px;
    padding: 8px;
    .DeleteButtonIcon {
      width: 24px;
      height: 24px;
    }
    .OrderItemDetailsListButtonsDontConfirm {
      width: 24px;
      height: 24px;
    }
  }

  &.typeB {
    width: 36px;
    height: 36px;

    .DeleteButtonIcon {
      width: 16px;
      height: 16px;
    }
  }

  &.typeC {
    width: 46px;
    min-width: 46px;
    height: 46px;
    border-radius: 8px;

    .DeleteButtonIcon {
      width: 18px;
      height: 18px;
    }
  }

  &.typeD {
    width: 30px;
    min-width: 30px;
    border-radius: 6px;
    height: 30px;
    .DeleteButtonIcon {
      width: 18px;
      height: 18px;
    }
  }

  .DeleteButtonLabel {
    padding: 8px 16px;
    color: var(--color-error);
  }

  &.showLabel {
    width: max-content;
  }

  &:hover {
    background-color: var(--color-error);
    >svg path {
      fill: var(--color-background-light-mode);
    }
    .OrderItemDetailsListButtonsDontConfirm {
      path {
        stroke: #ffffff;
      }
    }
  }
}
