.Review {
  .CTAButton:active {
    scale: 0.95;
  }

  .CTAButton {
    background-color: var(--color-preferred-secondary-bg);
    display: flex;
    justify-content: center;
  }

  .TextAreaWrapper {
    margin-bottom: 100px;
  }

  .TextArea {
    color: var(--color-text-white);
  }
}

.ReviewSendButton {
  position: fixed;
  width: calc(100% - 32px);
  bottom: 30px;

  &.typeB {
    &:disabled {
      cursor: not-allowed;
      background-color: var(--color-gray-30);
      scale: none;

      h4 {
        color: var(--color-text-black-low);
      }
    }

    &.isSend:not(:disabled),
    &.isUpdate:not(:disabled) {
      background-color: var(--color-admin-primary);
    }

    &.isSent {
      display: none;
    }

    &.isTryAgain {
      background-color: var(--color-error);
    }
  }
}

.SuccessFullySent {
  width: calc(100% - 32px);
  position: fixed;
  padding: 18px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  bottom: 30px;

  h4 {
    color: var(--color-success);
    text-align: center;
  }

  img {
    fill: var(--color-success);
  }
}

.ReviewRating {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 56px;


}
