@import "src/assets/styles/_variables";

.AdminDashAllOrders {
  .TableDateColumnClass {
    position: relative;
    left: 1px !important;
  }

  .AdminOrderHistoryEmptyTableData {
    margin: initial;
  }
  .AdminOrderHistoryTableCustomRowItem {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .AdminOrderHistoryTableDeleteButton {
    display: flex;
    justify-content: center;
    transition: 0.2s;
    opacity: 0;
    > div {
      width: 30px;
      height: 30px;
      padding: 6px;
    }
  }

  .TableDataList {
    &:hover .AdminOrderHistoryTableDeleteButton {
      opacity: 1;
      transition: 0.2s;
    }
  }
  //.TableCustomRowItem:not(:last-child) {
  //  margin-right: 20px;
  //}

  // When Edit button exist delete them
  .TableHeader {
    padding-right: 0;
  }
  .TableCustomRowItem {
    width: calc(50.5% - 50.5px) !important;
    max-width: calc(50.5% - 50.5px) !important;
  }
  .TableColumnItemClass {
    width: calc(50.5% - 50.5px) !important;
    max-width: calc(50.5% - 50.5px) !important;
  }

  .TableHeader .TableColumnItemClass:last-child {
    justify-content: flex-start;
    margin-left: initial;
    width: calc(50.5% - 50.5px) !important;
    max-width: calc(50.5% - 50.5px) !important;
  }
  //.TableCustomRowItem:first-child {
  //  margin-right: auto;
  //}
  .TableCustomRowItem:last-child {
    margin-left: initial;
    display: initial;
    margin-right: initial !important;
  }

  // When edit button exist Delete up to here

  .AdminOrderHistoryEmptyStateDescription {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .AdminOrderHistoryEmptyStateDescription {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
}

//@media (max-width: $tablet) {
//  .AdminDashOrderHistoryTable {
//    .AdminOrderHistoryCustomTable {
//      .TableHeader {
//        display: flex;
//
//        h6 {
//          width: calc(17.5% - 12.5px) !important;
//          max-width: calc(17.5% - 12.5px) !important;
//        }
//        .TableCustomColumnItem {
//          width: calc(17.5% - 12.5px) !important;
//          max-width: calc(17.5% - 12.5px) !important;
//          display: flex;
//          gap: 4px;
//        }
//        .TableEditColumn {
//          width: calc(17.5% - 12.5px) !important;
//          max-width: calc(17.5% - 12.5px) !important;
//        }
//      }
//
//      .TableHeader .TableColumnItemClass:nth-child(3) {
//        display: none !important;
//      }
//      .TableHeader .TableColumnItemClass:nth-child(1) {
//        display: none !important;
//      }
//      .TableDataList {
//        flex-direction: row;
//        align-items: center;
//        .TableCustomRowItem {
//          width: calc(17.5% - 12.5px) !important;
//          max-width: calc(17.5% - 12.5px) !important;
//        }
//        .TableCustomRowItem {
//          display: initial;
//        }
//
//        h6.TableControls {
//          position: initial;
//          width: calc(17.5% - 12.5px) !important;
//          max-width: calc(17.5% - 12.5px) !important;
//
//          button {
//            margin-left: auto;
//          }
//        }
//      }
//
//      .TableDataList .TableCustomRowItem:nth-child(3) {
//        display: none;
//      }
//      .TableDataList .TableCustomRowItem:nth-child(1) {
//        display: none;
//      }
//    }
//  }
//}
