.MagicOrderGif{
  width: 370%;
  height: 370%;
  object-fit: cover;
  border-radius: 50%;
}
.MagicOrderIconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--color-very-dark-gray);
  width: 60px;
  height: 60px;
  min-width: 60px;
  overflow: hidden;
}
.MagicOrderLoadingContainer {
  background-image: linear-gradient(rgb(186, 66, 255) 35%,rgb(0, 225, 255));
  width: 60px;
  height: 60px;
  animation: spinner 1.7s linear infinite;
  text-align: center;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px rgb(186, 66, 255), 0px 5px 20px 0px rgb(0, 225, 255);
}

.MagicOrderLoadingSpinner {
  background-color: rgb(36, 36, 36);
  width: 60px;
  height: 60px;
  border-radius: 50px;
  filter: blur(14px);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}