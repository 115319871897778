@import "src/assets/styles/mixins";

.AdminReservationScheduleContainer {
  width: 100%;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    visibility: hidden;
  }
}

.AdminReservationSchedule {
  display: flex;
  flex-direction: column;
  width: max-content;
  //position: relative;
  overflow: auto;
}
.AdminReservationScheduleEmptyState {
  position: absolute;
  left: 50%;
  top:60%;
  transform: translate(-50%, -50%);
  h4 {
    color: var(--color-inverse-50);
  }
}

.AdminReservationScheduleSlots {
  display: flex;
  flex-direction: row;
  padding-left: 82px;
  border-bottom: 1px solid var(--color-stroke);
}

.AdminReservationScheduleHourHeader {
  display: flex;
  width: 100%;
  position: relative;
  left: -15px;
  margin-bottom: 5px;
}

.AdminReservationScheduleHourBox {
  flex: 1;
  display: flex;
  min-height: 72px;
  width: 60px;
  position: relative;
  border-right: 1px solid var(--color-stroke);
  border-bottom: 1px solid var(--color-stroke);
}

.AdminReservationScheduleHourBoxHeader {
  flex: 1;
  display: flex;
  width: 60px;
  position: relative;
  flex-direction: column;
  z-index: 2;
  justify-content: space-between;
}

.AdminReservationScheduleHourBoxParts {
  display: flex;
  justify-content: space-between;
}

.AdminReservationScheduleHourBoxPart {
  height: 7px;
  width: 1px;
  background-color: var(--color-stroke);
  &:last-child {
    opacity: 0;
  }
  &:first-child {
    background-color: var(--color-inverse-25);
  }
}

.AdminReservationScheduleTableRow {
  display: flex;
  flex-direction: row;
}

.AdminReservationScheduleZoneRow {
  &:nth-child(odd) {
    background-color: var(--color-mini-button);
    .AdminReservationScheduleTableResource {
      background-color: var(--color-mini-button);
    }
  }

  &:nth-child(even) {
    background-color: var(--color-text-white);
    .AdminReservationScheduleTableResource {
      background-color: var(--color-text-white);
    }
  }
}

.AdminReservationTableContent {
  border: 1px solid var(--color-stroke);
}

.AdminReservationScheduleSlotColumn {
  flex: 1;
  &.isDraggingOver {
    background-color: var(--color-gray);
    width: 15px;
  }
}

.AdminReservationScheduleTableResource {
  width: 83px;
  min-height: 50px;
  padding: 8px 4px;
  border-right: 1px solid var(--color-stroke);
  border-bottom: 1px solid var(--color-stroke);
  border-left: 1px solid var(--color-stroke);
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-word;
    text-align: center;
  }
}

.AdminReservationScheduleReservation {
  padding: 8px;
  margin-top: 6px;
  color: white;
  cursor: move;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  flex-direction: column;
  position: relative;
  transform-origin: left;
  width: 15px;
  height: 60px;
  &:last-child {
    margin-bottom: 6px;
  }

  h4,
  h6 {
    z-index: 3;
  }
  h6:nth-of-type(1) {
    z-index: 3;
    word-break: break-word;
    @include text-truncate-ellipsis(1);
  }

  &:active {
    .AdminReservationScheduleReservationContent {
      background: var(
        --Light-Mode-Gradient-Main,
        linear-gradient(90deg, #fd0 0%, #fbb034 100%)
      );
    }
  }

  .AdminReservationScheduleReservationContent {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: red;
    z-index: 1;
    background: var(
      --Bozz,
      linear-gradient(90deg, #d9d9d9 70.13%, rgba(217, 217, 217, 0) 100%)
    );
    border-radius: 2px 8px 8px 2px;
  }
}

.AdminReservationSchedulePresentTimeLine {
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  top: 21px;
  border-right: 2px dashed var(--color-admin-primary);
  transform: translateX(82px);
}
