@import "src/assets/styles/mixins";

.Tab {
  &.typeA {
    .TabItem {
      background: var(--color-background-light-mode);
    }

    .TabItemContainer {
      &.isActive {
        background-color: var(--color-mini-button);

        &::before {
          @include cornerShadow(
            "left",
            8px,
            8px,
            8px,
            var(--color-mini-button)
          );
        }

        &::after {
          @include cornerShadow(
            "right",
            8px,
            -8px,
            8px,
            var(--color-mini-button)
          );
        }
      }
    }

    .TabContent {
      background-color: var(--color-mini-button);
      .InputControlContainer {
        .InputControlLabel:before {
          background-color: var(--color-mini-button);
        }
      }
      * {
        .InputControlLabel {
          &:before {
            //background-color: var(--color-mini-button);
          }
        }

        .DropdownLabel {
          background-color: var(--color-mini-button);
        }
      }
    }
  }

  &.typeB {
    .TabItem {
      background: var(--color-mini-button);
    }

    .TabItemContainer {
      &.isActive {
        background-color: var(--color-background-light-mode);

        &::before {
          @include cornerShadow(
            "left",
            8px,
            8px,
            8px,
            var(--color-background-light-mode)
          );
        }

        &::after {
          @include cornerShadow(
            "right",
            8px,
            -8px,
            8px,
            var(--color-background-light-mode)
          );
        }
      }
    }

    .TabContent {
      background-color: var(--color-background-light-mode);
    }
  }
}

.TabItemContainer {
  padding: 4px;
  border-radius: 6px 6px 0 0;
  position: relative;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:first-child {
    margin-left: 4px;
  }

  &.isActive {
    .TabItem {
      background-color: transparent;
      border: 1px solid transparent;

      h6 {
        color: var(--color-text-black);
      }
    }

    .TabItemClose {
      path {
        color: var(--color-text-black);
      }
    }
  }
}

.TabItem {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 12px;
  border: 1px solid var(--color-gray);
  cursor: pointer;

  h6 {
    color: var(--color-inverse-50);
  }
}

.Tabs {
  display: flex;
  align-items: center;
}

.TabsExisting {
  display: flex;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: 4px;
  position: relative;
  margin-right: 12px;
  padding-right: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.TabItemIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-stroke);
  margin-right: 8px;
}

.TabItemLeft {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.TabContent {
  border-radius: 12px;
  padding: 16px 8px;
}

.TabItemClose {
  path {
    color: var(--color-inverse-25);
  }
}
