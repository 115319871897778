.EmptyOrderInfoModalContainerClassname {
  background-color: var(--color-text-white);
  padding: 16px;
  border-radius: 24px 24px 0 0 ;
}
.EmptyOrderInfoModalHeader {
  display: flex;
  justify-content: flex-end;
  .WarningIcon {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
  }
}
.EmptyOrderInfoModalBody {
  display: flex;
  align-items: center;
  margin-top: 60px;
  h5 {
    text-align: center;
    line-height: 24px;
  }
}
.EmptyOrderInfoModalFooter {
  margin-top: 24px;
  .EmptyOrderInfoModalFooterButton {
    background-color: var(--color-admin-primary);
    .PrimaryButtonText{
     font-weight: 600;
    }
  }
}
