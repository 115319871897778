@import "src/assets/styles/variables";

.AdminUser {
  padding: 30px;

  .AdminUserTitleText {
    margin-bottom: 32px;
    color: var(--color-text-black);
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.AdminUserTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.AdminUserTitleLeft {
  h4 {
    margin-bottom: 5px;
    color: var(--color-text-black);
  }

  h6 {
    color: var(--color-inverse-50);
  }
}

.AdminUserTable {
  .TableHeader .TableColumnItemClass {
    margin-right: initial;
  }
  .TableDataList .TableCustomRowItem {
    margin-right: initial;
    padding-right: 20px;
  }
}

@media (max-width: $mobile) {
  .AdminUser {
    padding: 26px 16px 30px 16px;

    h2 {
      border-bottom: 1px solid var(--color-stroke);
      padding-bottom: 27px;
      margin-bottom: 30px;
    }
  }
}
