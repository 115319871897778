.MenuCard {
  display: flex;
  background-color: white;
  position: relative;
  width: 168px;
  min-width: 168px;
  flex-direction: column;
  border-radius: 8px;
  height: 222px;
  padding: 8px;


  &:last-child {
    margin-right: 16px;
    &:dir(rtl) {
      margin-left: 16px;
      margin-right: 0;

    }
  }

  .ImageContainer {
    height: 132px;

    .Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

  }

  .DetailContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4px;

    .Tags {
      display: none;
    }

    .Title {
      font-size: 12px;
      margin-bottom: 8px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      line-height: 16px;
    }

    .Footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .PriceColumn {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 0px;

        div {
          color: var(--color--semantic-error);

        }
      }
    }
  }
}
