@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

//TTHoves

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "TTHoves";
  src: url("hoves/TTHoves-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

//Cabinet

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Extrabold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url("cabinet/CabinetGrotesk-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}