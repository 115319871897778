.FilterButtonContent {
  background: transparent;
  cursor: pointer;
  position: relative;
  width: max-content;

  svg {
    path {
      stroke: #2e2e2e;
    }
  }

  .hasFilter {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--Gray-0, #fff);
    background: var(--Primary, #ffca40);
    position: absolute;
    top: -4px;
    right: -4px;
  }
}

.MenuNoFilterResults {
  height: calc(100vh - 45px);
}
