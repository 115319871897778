.Root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;

  &.isVisible {
    opacity: 1;
    visibility: visible;
  }

  &.Center {
    .Wrapper {
      animation: fadeIn var(--animation-duration) ease-in-out;
      border-radius: 20px;
    }
  }

  &.Left {
    justify-content: flex-start;
    .Wrapper {
      animation: slideInLeft var(--animation-duration) ease-in-out;
      border-radius: 0 16px 16px 0;
    }
  }

  &.Right {
    justify-content: flex-end;
    .Wrapper {
      animation: slideInRight var(--animation-duration) ease-in-out;
      border-radius: 20px 0 0 20px;
    }
  }

  &.Top {
    align-items: flex-start;
    .Wrapper {
      animation: slideInTop var(--animation-duration) ease-in-out;
      border-radius: 0 0 16px 16px;
    }
  }

  &.Bottom {
    align-items: flex-end;
    .Wrapper {
      animation: slideInBottom var(--animation-duration) ease-in-out;
      border-radius: 20px 20px 0 0;
    }
  }
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  animation: fadeOpacity var(--animation-duration) ease-in-out;
}

.Wrapper {
  position: relative;
  background: var(--color-pos-utility-colors-white);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes fadeOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
