@import "src/assets/styles/variables";

.AdminOrderPrints {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -1rem;
  margin-bottom: -1rem;
  @media (max-width: $mobile) {
    justify-content: space-between;
    margin: 0;
  }
  .AdminOrderPrintBtn {
    background-color: var(--color-light-mode-black);
    padding: 8px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    min-width: 170px;
    max-width: 190px;
    width: 100%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    @media (max-width: $mobile) {
      width: calc((100% / 2) - 1rem);
      min-width: unset;
      max-width: unset;
      margin-left: 0;
    }
    h5 {
      color: var(--color-text-white);
      margin-left: 8px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    svg{
      flex-shrink: 0;
    }
  }
}
