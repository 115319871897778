@import "src/assets/styles/_variables";

.Root {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  &.isClickable {
    cursor: pointer;
  }
}

.ProfileContainer {
  position: relative;
  background-color: var(--color-pos-neutral-2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Profile32 {
    min-width: 32px;
    width: 32px;
    height: 32px;

    .Initial {
      font-size: 16px;
      line-height: 16px;
    }

    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &.Profile36 {
    min-width: 36px;
    width: 36px;
    height: 36px;

    .Initial {
      font-size: 12px;
      line-height: 16px;
    }

    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &.Profile40 {
    min-width: 40px;
    width: 40px;
    height: 40px;

    .Initial {
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &.Profile44 {
    min-width: 44px;
    width: 44px;
    height: 44px;

    .Initial {
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      max-height: 20px;
      max-width: 20px;
    }
  }

  &.Profile48 {
    min-width: 48px;
    width: 48px;
    height: 48px;

    .Initial {
      font-size: 16px;
      line-height: 24px;
    }

    svg {
      max-height: 24px;
      max-width: 24px;
    }
  }

  &.Profile52 {
    min-width: 52px;
    width: 52px;
    height: 52px;

    .Initial {
      font-size: 18px;
      line-height: 24px;
    }

    svg {
      max-height: 24px;
      max-width: 24px;
    }
  }
}

.ProfileSrc {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.TitleContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.TitleAndArrow {
  display: flex;
  align-items: center;
  gap: 16px;

  &.Profile32 {
    .Title {
      font-size: 12px;
      line-height: 16px;
    }

    .HelperText {
      font-size: 10px;
      line-height: 16px;
    }

    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &.Profile36 {
    .Title {
      font-size: 14px;
      line-height: 20px;
    }

    .HelperText {
      font-size: 12px;
      line-height: 16px;
    }

    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &.Profile40 {
    .Title {
      font-size: 16px;
      line-height: 24px;
    }

    .HelperText {
      font-size: 12px;
      line-height: 16px;
    }

    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &.Profile44 {
    .Title {
      font-size: 16px;
      line-height: 24px;
    }

    .HelperText {
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }

  &.Profile48 {
    .Title {
      font-size: 18px;
      line-height: 24px;
    }

    .HelperText {
      font-size: 16px;
      line-height: 24px;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }

  &.Profile52 {
    .Title {
      font-size: 20px;
      line-height: 28px;
    }

    .HelperText {
      font-size: 16px;
      line-height: 24px;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }
}

.HelperText {
  color: var(--color-pos-text-black-medium);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Title {
  color: var(--color-pos-text-black-high);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Initial {
  color: var(--color-pos-utility-colors-dark-gray);
}

@media (max-width: $mobile) {
  .PinLockBtnContainer {
    display: none;
  }
  .Root {
    .ProfileContainer {
      min-width: 40px !important;
      max-width: 40px !important;
      height: 40px !important;

      svg {
        max-height: 16px;
        max-width: 16px;
      }
      .Initial {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .TitleContainer {
      .Title {
        font-size: 16px;
        line-height: 24px;
      }

      .HelperText {
        font-size: 12px;
        line-height: 16px;
      }

      svg {
        min-width: 20px;
        min-height: 20px;
      }
    }
  }
}
