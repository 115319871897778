.CustomPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .ArrowIcon {
    path {
      stroke: #ffca40;
    }
  }

  .ArrowRightIcon {
    transform: rotate(180deg);
  }

  .page-item {
    list-style-type: none;
    cursor: pointer;

    .page-link {
      color: var(--light-mode-inverse-50, #8d8d8d);
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .ActivePage {
    border-bottom: 1.5px solid var(--Admin-and-Waiter-D-Primary, #ffca40);

    .page-link {
      color: var(--Light-Mode-Text-Black, #1f1f1f);
    }
  }

  .previousPageLink {
    padding-right: 16px;
  }

  .nextPageLink {
    padding-left: 16px;
  }
}
