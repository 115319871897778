.AddItem {
  border: dashed 2px #000;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 29%;
  height: 108px;
  margin: 8px;
  padding: 4px;
  h6 {
    margin-top: 4px;
    text-align: center;
  }
}