.SuperAdminGuests {
  padding: 30px;
  .EmptyStateContainer {
    height: calc(100vh - 160px);
  }
  .GuestInfo {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .TableColumnItemClass {
    &:last-child {
      justify-content: flex-start;
    }
  }
  .TableCustomRowItem {
    &:last-child {
      justify-content: flex-start;
    }
  }

  .TableCustomRowItem {
    .GuestProfile {
      width: 40px;
      height: 40px;
      border-radius: 30px;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
}

.SuperAdminGuestsTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.SuperAdminGuestsInfo {
  .SuperAdminGuestsInfoTitle {
    margin-bottom: 5px;
    color: var(--color-text-black);
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
  h6 {
    color: var(--color-inverse-50);
  }
}
