.TicTacToeModalHeader {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  .TicTacToeModalHeaderMessage {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    top: 17px;
    font-size: 28px;
    h1 {
      color: var(--color-text-white);
    }
  }
}

  .TicTacToeModalBody{
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 0 16px;
    .TicTacToeGameModalScoreBoard{
      width: 100%;
      height: 70px;
      border-radius: 10px;
      background: #1A202C;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      &.isActive{
        background-color: #08D9D6;
      }
      h2,h5{
        color: var(--color-text-white);
      }
      div{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
      }

    }

}

.TicTacToeModalFooter {
  padding: 24px 16px 16px;
  margin-top: 30px;
}
