.AdvanceInputBox {
  border-radius: 29px;
  overflow: hidden;

  .AdvanceInputToolsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 56px;
    padding: 12px;
    background-color: var(--color-text-white);
    position: relative;
    z-index: 2;
    color: #fff;
    border-radius: 29px;
    border: 1px solid var(--color-gray-40);
  }

  .AdvanceInputToolsContainer.active {
    border-top: none;
    border-radius: 0 0 29px 29px;
  }

  .AdvanceInputToolsRecording {
    width: calc(100% - 58px);
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    img {
      width: 100%;
    }

    .AdvanceInputToolsRecordingActions {
      display: flex;
      align-items: center;
      gap: 4px;

      .AdvanceInputToolsRecordingAction {
        width: 46px;
        height: 46px;
        background-color: var(--color-text-black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    h5 {
      color: var(--color-text-white);
      min-width: 45px;
    }
  }

  .AdvanceTextArea {
    resize: none;
    border: 1px solid var(--color-gray-40);
    border-bottom: none;
    border-radius: 29px 29px 0 0;
    outline: none;
    display: block;
    width: 100%;
    padding: 18px 16px 5px 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: var(--color-text-white);
    color: var(--color-text-black-high);
    caret-color: var(--color-gradient-brand-secondary);
    position: relative;
    z-index: 1;
    height: auto;
    transition: height 0.2s ease-out;
    animation: InputSlideAnimation 0.4s ease-out;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;

    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-inverse-50);
    }
  }

  .AdvanceInputTools {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 11px;
    &:dir(rtl) {
      padding-left: 0;
      padding-right: 11px;
    }

    .AdvanceInputToolsLanguages {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .AdvanceInputToolsRecordButton {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .AdvanceInputToolsPlaceholder {
      cursor: pointer;
      color: var(--color-discount-price);
      flex: 1;
    }
  }

  .AdvanceInputToolsSubmitButton {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-gray-20);

    svg {
      path {
        fill: #4042e2;
      }
    }

    &:disabled {
      svg {
        path {
          fill: #bfbfbf;
        }
      }
    }
  }
}

@keyframes InputSlideAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
