.Root {
  width: 550px;
  min-height: 264px;
  padding: 16px;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.Dropdown {
  width: 200px;
}

.Main {
}
