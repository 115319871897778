.AdminReservation {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.AdminReservationScheduleAndList {
  display: flex;
  gap: 20px;
  max-height: calc(100vh - 204px);
  height: 100%;

   .AdminReservationScheduleContainer {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }

}
.AdminReservationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.AdminReservationOptions {
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  //&.editMode{
  //  .DropdownSelectedItem,.AdminReservationDatePicker{
  //    cursor: not-allowed;
  //  }
  //  h6{
  //    color: var(--color-inverse-50);
  //  }
  //  svg{
  //    path{
  //      fill: var(--color-inverse-50);
  //    }
  //  }
  //}
  .Dropdown {
    width: 200px;
  }
}

.AdminReservationDatePicker {
  width: 200px;
  margin-right: 20px;
  border: 1px solid var(--color-gray);
  padding: 0 5px 0 16px;
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.isDisabled{
    pointer-events: none;
    h6{
      color: var(--color-inverse-50);
    }
    svg{
      path{
        fill: var(--color-inverse-50);
      }
    }
  }
}
.AdminReservationEditOperations {
  display: flex;
  gap: 16px;
  .PrimaryButton {
    text-wrap: nowrap;
    min-width: 102px;
    width: 100%;
    height: 46px;
    border-radius: 8px;
    .AddNewButtonIcon {
      min-width: 16px;
    }
  }
  .UndoButton{
    height: 46px;
    width: 46px;
    padding: 11px;
  }
}
.AdminReservationDatePickerIcon {
  padding: 8px;
  background-color: var(--color-mini-button);
  border-radius: 8px;
  height: 36px;
}
.AdminReservationBottomContainer {
  margin-top: auto;
  position: fixed;
  right: 30px;
  bottom: 0;
  width: 70%;
  background-color: var(--color-text-white);
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .PrimaryButton {
    width: auto;
    height: 46px;
    padding: 16px 34px;
  }
}

.AdminReservationHeaderOperations {
  display: flex;
  align-items: center;
  gap: 16px;
}
