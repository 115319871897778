.print-loading-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  &__content {
    text-align: center;
  }

  &__icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--surface-light);
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    svg {
      width: 40px;
      height: 40px;
      color: var(--primary-color);
    }

    &.animate-pulse {
      animation: pulse 2s infinite;
    }
  }

  &__spinner {
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid transparent;
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  &__text {
    position: relative;

    h4 {
      font-size: 1.25rem;
      font-weight: 500;
      color: var(--text-primary);
      margin: 0 0 0.5rem;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 8px;

    span {
      width: 6px;
      height: 6px;
      background-color: var(--primary-color);
      border-radius: 50%;
      opacity: 0.3;

      &:nth-child(1) {
        animation: dot 1s infinite 0s;
      }
      &:nth-child(2) {
        animation: dot 1s infinite 0.2s;
      }
      &:nth-child(3) {
        animation: dot 1s infinite 0.4s;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  &__button {
    min-width: 120px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

@keyframes dot {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

:root {
  --primary-color: #2196F3;
  --text-primary: #1A1A1A;
  --surface-light: #FFFFFF;
}
