.GuestPromotionAndBanner {
  padding: 0 16px 0 16px;

  .GuestPromotionAndBannerSwiper {
    width: 100%;
    aspect-ratio: 24/9;
    overflow: hidden;
    border-radius: 16px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;

      [dir="rtl"] & {
        transform: rotateY(180deg);
      }
    }
  }

  &.Promotion {
    .GuestPromotionAndBannerSwiper {
      .PromoAndBannerDetailsWrapper {
        max-width: 65%;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;

        [dir="rtl"] & {
          right: 16px;
          left: 0;
        }

        .PromoAndBannerTitle, .PromoAndBannerDescription {
          max-width: 100%;
          white-space: normal;
          word-wrap: break-word;
          align-self: stretch;
          line-height: 32px;
        }

        .PromoAndBannerTitle {
          color: var(--color-text-white);
          font-size: 24px;
          font-weight: 600;
        }

        .PromoAndBannerDescription {
          color: var(--color-text-medium);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .swiper-slide img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;
    }

    .swiper-pagination {
      max-width: max-content;
      position: absolute;
      bottom: 12px;
      display: flex;
      justify-content: center;
      text-align: center;
      left: 50% !important;
      transform: translateX(-50%);
      border-radius: 12px;
      background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
      padding: 4px 12px;
    }

    .swiper-pagination-horizontal {
      width: max-content;
      height: max-content;
    }

    .swiper-horizontal {
      height: 100%;
      border-radius: 16px;

    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #bfbfbf;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .swiper-pagination-bullet-active {
      background-color: #1c1c1c;
      width: 8px;
      height: 8px;
    }
  }

  &.Banner {
    padding: 0;


    .swiper-slide {
      padding: 0 16px;
    }

    .GuestPromotionAndBannerSwiper {
      aspect-ratio: 39/9;

      .PromoAndBannerDetailsWrapper {
        max-width: 65%;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;

        [dir="rtl"] & {
          right: 16px;
          left: 0;
        }

        .PromoAndBannerTitle, .PromoAndBannerDescription {
          max-width: 100%;
          white-space: normal;
          word-wrap: break-word;
          align-self: stretch;
          line-height: 24px;
        }

        .PromoAndBannerTitle {
          color: var(--color-text-white);
          font-weight: 600;
          font-size: 18px;

        }

        .PromoAndBannerDescription {
          color: var(--color-text-medium);
          font-size: 13px;
          font-weight: 300;
        }
      }

    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}


.Delay {
  opacity: 0;
  transform: translateY(20px) scale(0.5);
  animation: SelectableChipAnimation 0.3s ease-out forwards;
}

@keyframes SelectableChipAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}