.DropdownOption {
  cursor: pointer;
  transition: 0.3s;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  h6 {
    color: var(--color-text-black) !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: var(--color-mini-button);
  }

  &.isActive {
    background: var(--color-admin-primary-15);
  }

  &.DropdownOptionGrouped {
    padding: 8px 30px;
  }

  &.isDisabled {
    pointer-events: none;
    h6 {
      color: var(--color-inverse-75) !important;
    }
    //h6 {
    //  color: var(--color-gray) !important;
    //}
  }

  &.hasCurrentDropdownOption {
    .OptionName {
      color: var(--color-text-black) !important;
    }
  }
}

.DropdownOptionWithIcon {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 11px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.DropdownOptionWithIconTitle {
  display: flex;
  flex-direction: column;
}

.WithIconOptionName {
  margin-bottom: 4px;
}

.DropdownOptionWithIconSymbol {
  margin-right: 18px;
}
