@import "src/assets/styles/_variables";

.SignUpStepper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  position: relative;

  .SignUpStepperWrapper {
    padding-top: 21px;
    overflow: hidden;
    display: flex;
    height: calc(100% - 119px);
    transition: margin-left 0.3s ease-in-out;
    &:dir(rtl) {
     transition: margin-right 0.3s ease-in-out;
    }
    justify-content: space-between;
    .SignUpFooterButton {
      position: absolute;
      width: 100%;
    }

    >.SignUpMainForm,.SignUpVerifyOtp,.SignUpDetailForm {
      width: calc(100% / 3);
    }
  }

  .StepperProgressBar {
    display: flex;
    justify-content: space-between;
  }

  .StepperProgressBarLine {
    width: 78px;
    height: 3px;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    margin-top: 16px;
    margin-bottom: 40px;
    position: relative;

    &.isPassed {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--color-success);
        width: 100%;
        height: 100%;
        border-radius: 8px;
        animation: progress 0.3s ease-in-out;
      }
    }
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
