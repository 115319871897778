@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.AdminOrderTablesSection {
  width: 100%;
  .ModalBackground {
    .ModalContainer {
      .Modal {
        .ModalBody {
          overflow-y: visible !important;
        }
      }
    }
  }
}

.AdminOrderButtonNotifications {
  width: 24px;
  height: 24px;
  background-color: var(--color-admin-primary);
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminOrderTables {
  width: 100%;
  padding: 0 28px 0 32px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-content: flex-start;
  gap: 16px;

  .EmptyStateContainer {
    width: 100%;
    height: calc(100vh - 200px);
  }
}
.AdminOrderTablesSectionGuests {
  padding: 12px 8px;
  right: 30px;
  height: calc(100dvh - 140px);
  width: 30%;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--color-pos-neutral-27);
  background: var(--color-text-white);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);
  gap: 8px;
  overflow: scroll;
  scrollbar-width: none;
  margin-right: 28px;
  h4{
    padding: 0 8px;
  }

  .EmptyStateContainer {
    img {
      width: 120px;
      height: 120px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .AdminOrderTablesSectionGuestsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .AdminOrderTablesSectionGuest {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      padding: 8px;

      &:hover {
        background-color: var(--color-pos-neutral-4);
      }
    }

    .AdminOrderTablesSectionGuestInfo {
      min-width: 132px;
      position: relative;
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;

      .GuestInfoProfilePicContainer {
        position: relative;

        .BirthdayHat {
          transform: rotate(-45deg);
          position: absolute;
          width: 36px;
          height: 36px;
          left: -16px;
          top: -18px;
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 2px;
        h6 {
          word-break: break-word;
          @include text-truncate-ellipsis();
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-text-black-medium);
          word-break: break-word;
          @include text-truncate-ellipsis();
        }
      }
    }

    .HappyBirthdayContainer {
      width: 50px;
      height: 34px;
      margin-right: 8px;

      .HappyBirthday {
        border-radius: 0;
        width: 40px;
        height: 30px;
      }
    }
  }
}

.AdminOrderTablesSections {
  display: flex;
  background-color: var(--color-mini-button);
  padding-bottom: 30px;
  min-height: calc(100vh - 112px);
}

.AdminOrderMapGuestsInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  align-self: flex-end;
}
@media (max-width: $mobile) {
  .AdminOrderTablesSections {
    .AdminOrderTables {
      gap: 8px;
      padding: 0 16px;
    }
  }

  .AdminOrderTablesSectionGuests {
    margin-right: 16px;
    padding: 12px 16px;
  }
}
