.AdminChat {
  width: 100%;
  height: 100%;

  .ChatFooter {
    margin-top: 8px;
    padding-bottom: 16px;

    .InputControlInput {
      height: 64px;
    }

    .ChatFooterFormSubmitButton {
      width: 48px;
      height: 48px;
      background-color: var(--color-admin-primary);

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}