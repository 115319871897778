
#spin_the_wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 30px;

  #canvas-container {
    background-color: var(--color-purple);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 320px;
    box-shadow: 0 0 3px 5px var(--color-dark-purple-25) inset,
    0 0 10px 2px var(--color-purple);
    border-radius: 50%;
  }

  #canvas-container canvas {
    width: 94%;
    height: 94%;
  }

  @media (max-width: 378px) {
    #canvas-container {
      width: 290px;
      height: 290px;
    }
  }

  @media (max-width: 344px) {
    #canvas-container {
      width: 240px;
      height: 240px;
    }
  }


  #spin {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    //top: 50%;
    width: 110px;
    height: 110px;
    margin: -15%;
    background: var(--color-gradient-main);
    color: var(--color-text-white);
    border-radius: 50%;
    border: 6px solid var(--color-dark-green);
    transition: 0.8s;

    .SpinText {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      background: var(--color-gradient-blue);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: -22px;
      border: 22px solid transparent;
      border-bottom-color: var(--color-dark-green);
      border-top: none;
    }
  }

  @media (max-width: 378px) {
    #spin {
      width: 90px;
      height: 90px;

      .SpinText {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 344px) {
    #spin {
      width: 80px;
      height: 80px;

      .SpinText {
        font-size: 14px;
      }

      &::after {
        content: "";
        top: -19px;
        border: 18px solid transparent;
        border-bottom-color: var(--color-dark-green);
        border-top: none;
      }
    }

  }
}

#wheel {
  display: block;
}


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 100%;
}
