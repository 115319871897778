.AdminPrinters {
  padding: 40px 32px 55px;
  .AdminConnectedPrinters,
  .AdminAvailablePrinters {
    margin-top: 16px;
    ul {
      overflow-x: scroll;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      list-style: none;
      margin-left: 20px;
      margin-top: 8px;
      gap: 20px;
      width: 100%;
      li {
        color: white;
        background: gray;
        padding: 10px 20px;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        &.Paused {
          background: var(--color-background-yellow);
        }
        &.Ready {
          background: var(--color-success);
        }
        &.Offline {
          background: var(--color-error);
        }

        p {
          color: inherit;
          font-size: 14px;
        }
      }
    }
  }
  .AdminAvailablePrinters {
    margin-top: 24px;
  }
  .AddPrinterHeader {
    //display: flex;
    //flex-direction: column;
    text-align: center;
  }
  .AddPrinterModalBody {
    min-width: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 0 0 30px;
  }
}
