.TermsAndConditionContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 30px;

  > h3 {
    color: var(--color-text-black);
    font-style: normal;
    line-height: 24px;
  }

  .TermsAndCondition {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .TermsAndConditionTitle {
      align-self: stretch;
      color: var(--color-inverse-100);
      font-style: normal;
      line-height: 20px;
    }

    .TermsAndConditionDescription {
      align-self: stretch;
      color: var(--color-inverse-100);
      font-size: 14px;
      font-style: normal;
      line-height: 22px;

      > span {
        color: var(--color-inverse-100);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .NewLine {
      white-space: pre-line;

    }
  }
}
