.ModificationsMenu {
  border-radius: 10px;
  background: var(--color-mini-button);

  padding: 0 16px;

  ul {
    padding: 16px 0;

    li {
      list-style: none;
    }

    & > * + * {
      margin-top: 16px;
    }
  }
}
.ModificationsMenuHeader {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-stroke);

  > h6 {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    color:rgba(0, 0, 0, 0.38);
    font-size: 12px;
    line-height: 16px;
  }
}

.ModificationsMenuHeaderControls {
  display: flex;
  align-items: center;

  > * {
    margin-left: 5px;
  }
}
