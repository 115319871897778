.BodySortItem {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 24px;
  border: 1px solid var(--color-gray-40);
  transition: all 0.2s ease-in-out;
  text-wrap: nowrap;

  &.isActive {
    background: var(--Brand-colors-Primary, #ffca40);
    border: 1px solid var(--Brand-colors-Primary, #ffca40);
  }
}
