@import "src/assets/styles/variables";

.MicrophoneModalContainer {
  background: linear-gradient(0deg, #383838 0%, #383838 100%), rgba(179, 179, 179, 0.82);
  background-blend-mode: color-dodge, normal;
  backdrop-filter: blur(25px);
  width: 270px;
  max-width: 270px;

  .MicrophoneHintModalHeader {
    padding: 12px 16px;

    .MicrophoneHintModalHeaderTitle {
      text-align: center;
      color: var(--color-background-modal);
      line-height: 20px;
    }
  }

  .MicrophoneHintModalBody {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 151px;
      height: 107px;
    }
  }

  .MicrophoneHintModalFooter {
    cursor: pointer;
    margin-top: 12px;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    border-top: 0.333px solid var(--miscellaneous-alert-menu-action-sheet-separators, rgba(128, 128, 128, 0.55));

    .MicrophoneHintModalFooterTitle {
      text-align: center;
      line-height: 24px;
      background: var(--Secondary, linear-gradient(90deg, #4042E2 0%, #4F52FF 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}


@media (max-width: $mobile) {
  .GuestModalBackground {
    &.isCentered {
      .MicrophoneModalContainer {
        width: 270px;
        max-width: 270px;
      }
    }
  }
}
