@import "src/assets/styles/variables";

.AdminMenuController {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .AdminMenuControllerSectionHeaderLink {
    display: block;

    color: var(--color-secondary);
  }

  .AdminMenuControllerSectionHeaderLink:hover {
    text-decoration: underline;
    opacity: 1;
    color: #e9801d;
  }

  .AdminMenuControllerBottomContainer {
    margin-top: auto;
    position: sticky;
    z-index: 10;
    bottom: 0;
    background-color: var(--color-text-white);
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .AdminMenuControllerSaveButton {
      width: auto;
      height: 46px;
      margin-right: 30px;
      padding: 16px 34px;
    }
  }
}

.DraggableCardActionButtons {
  .AdminMenuControllerDeleteButton {
    width: 46px;
    height: 46px;

    &.isDisabled {
      background-color: var(--color-text-white);

      > svg {
        path {
          fill: var(--color-inverse-50);
        }
      }
    }
  }

  .AdminMenuControllerEditButton {
    .EditButtonIcon {
      width: 24px;
      height: 24px;
    }

    &.isDisabled {
      background-color: var(--color-text-white);

      svg {
        path {
          stroke: var(--color-inverse-50);
          fill: var(--color-inverse-50);
        }
      }

    }

    background-color: var(--color-text-white);

    &:hover {
      background-color: var(--color-gray);
    }
  }

  .AdminMenuControllerExpandButton {
    .CTAButtonIcon {
      transition: 0.3s;
    }

    &.isExpanded {
      .CTAButtonIcon {
        transform: rotate(180deg);
      }
    }
  }
}

.AdminMenuControllerCategoriesDropdown {
  .DropdownLabel {
    color: var(--color-text-black);
    width: fit-content;
  }
}

.AdminMenuControllerCategories {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.DownloadMenuImagesButton{
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background: var(--color-mini-button);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.isDisabled{
    cursor: not-allowed;
    pointer-events: none;
    background-color: var(--color-text-white);
    border: 1px solid var(--color-gray);
    svg{
      path{
        fill: var(--color-inverse-50);
      }
    }
  }
}

.AdminMenuControllerHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 30px 32px 0;
  gap: 24px;

  .AdminMenuControllerHeaderActionButtons {
    display: flex;
    gap: 10px;
    .SpinnerContainer{
      .Spinner{
        width: 30px;
        height: 30px;
        border: 2px solid var(--color-stroke);
        border-top: 2px solid var(--color-admin-primary);
      }

    }
  }

  .AdminMenuControllerSectionHeader {
    margin-right: auto;
  }

  .AdminMenuControllerCategoriesDropdown {
    min-width: 190px;
    width: fit-content;
  }

  .AdminMenuControllerCategoriesPreviewButton,
  .AdminMenuControllerCategoriesAddButton {
    width: fit-content;
    height: fit-content;
    padding: 12px 16px;
    border-radius: 8px;
  }

  .AdminMenuControllerCategoriesAITranslateButton {
    width: fit-content;
    height: fit-content;
    padding: 9px 16px;
    background-color: white;
    border: 1px solid var(--color-purple-200);
    border-radius: 8px;

    &.isDisabled {
      border: 1px solid var(--color-gray);
      cursor: not-allowed;
      pointer-events: none;

      .CTAButtonName {
        color: var(--color-inverse-50);
      }

      .CTAButtonIcon {
        svg {
          stop {
            stop-color: var(--color-inverse-50);
          }
        }
      }
    }

    &:hover {
      background: var(--color-gradient-light-blue);
      color: var(--color-text-black);

      .CTAButtonIcon {
        svg {
          stop {
            stop-color: var(--color-inverse-100);
          }
        }

        .LoadingIcon {
          svg {
            stroke: black;
          }
        }
      }

      .CTAButtonName {
        color: var(--color-inverse-100);
      }
    }

    .CTAButtonIcon {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .LoadingIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .CTAButtonName {
      color: var(--color-purple-200);
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .AdminMenuControllerCategoriesAddButton {
    min-width: 176px;

    .PrimaryButtonIcon {
      margin-right: 5px;
    }
  }

  .AdminMenuControllerCategoriesPreviewButton,
  .AdminMenuControllerCategoriesAddButton {
    min-width: 130px;
    background-color: var(--color-mini-button);
    border-radius: 8px;
    padding: 12px 24px;

    .PrimaryButtonIcon {
      margin-right: 8px;
    }

    .PrimaryButtonText {
    }

    &:hover {
      background-color: var(--color-group-background-light-mode);
    }
  }
}

.AdminMenuControllerCategoryPublishStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: fit-content;
  height: 28px;
  background-color: var(--color-gray);
  margin: 0 10px;
  padding: 4px 8px;

  .AdminMenuControllerCategoryPublishStatusText {
    color: var(--color-inverse-85);
    padding-left: 4px;
  }
}

.AddMenuControllerTopSubTitle {
  color: var(--color-gray);
}

.AdminMenuControllerItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  button {
    background-color: var(--color-background-light-mode);
  }

  .AdminMenuControllerItemsAddContainer,
  .Item {
    width: 175px;
    min-width: 175px;
    height: 215px;
  }

  .AdminMenuControllerItemsAddButton {
    &.isDisabled {
      svg {
        path {
          stroke: var(--color-inverse-50);
        }
      }
    }
  }

  .AdminMenuControllerItemsAddContainer .AdminMenuControllerItemsAddButton {

    .AddTagButtonIcon {
      scale: 3;

      path {
        stroke-width: 1.2;
      }
    }
  }
}

.AdminMenuControllerEmptyMenuItemState {
  img {
    width: 90px;
  }
}

.AdminMenuControllerTagList {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.NoDraggableCardWrapper {
  .NoDraggableCardContainer {
    padding: 0;
    overflow: hidden;

    .NoDraggableCardHeader {
      background: var(--Component-color-Warning-color-100, #FDF4CF);
      padding: 12px 16px;
    }

    .NoDraggableCardContent {
      padding: 0 16px 12px;
    }
  }
}