.GuestAuthFooter {
  //margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  flex-direction: column;
  .GuestAuthSignUpWith {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    .LeftLine {
      width: 40%;
      height: 3px;
      border-radius: 10px;
      background: var(--color-mini-button);
    }
    .RightLine {
      width: 40%;
      height: 3px;
      border-radius: 10px;
      background: var(--color-mini-button);
    }
    .FooterText {
      h6 {
        white-space: nowrap;
        color: var(--color-inverse-50);
      }
    }
  }
}
.GuestAuthSocials {
  display: flex;
  gap: 20px;
  align-items: center;
  .GuestAuthSocial {
    width: 56px;
    height: 56px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--color-mini-button);
    //opacity: 0.6;
    cursor: not-allowed;
    &.apple {
      background-color: var(--color-gray-700);
    }

    &.google {
      background-color: var(--color-gray-20);
    }

    &.facebook {
      background-color: #0866ff;
    }
    &.isActive {
      opacity: 1;
      cursor: pointer;
    }
    > img {
      width: 32px;
      height: 32px;
    }
  }
}
