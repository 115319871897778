.SignUpDetailForm {
  .GuestProfileWithIconContainer {
    .GuestProfileWithIconProfilePicture {
      width: 100px;
      height: 100px;
    }
  }
}

.SignUpDetailFormHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    text-align: center;
    margin-bottom: 16px;
  }

  .GuestProfileWithIconContainer {
    background-color: var(--color-light-gray);
    width: 100px;
    height: 100px;
  }

  .GuestProfileWithIconAvatar {
    width: 80px;
    height: 80px;
  }

  .SignUpGuestImageContainerLoading {
    width: 100px;
    height: 100px;
  }
}

.SignUpGuestImageContainerEditButton {
  background-color: var(--color-primary);
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-text-white);
  position: absolute;
  bottom: 0;
  right: 0;

  .EditButtonIcon {
    width: 12px;
    height: 12px;
  }
}

.GuestProfileWithIconActionButtonWrapper {
  position: relative;

  .SignUpGuestProfileButton {
    border: 4px solid var(--color-text-white);
    background-color: var(--color-primary);
    position: absolute;
  }
}

.SignUpGuestDetailForm {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .SignUpGuestGenderDropdown {
    .DropdownSelectedItem {
      border-radius: 50px;
      background-color: var(--color-mini-button);
      border: none;

      .DropdownLabel {
        background-color: transparent;
        left: 17px;
        top: 8px;
        font-size: 9px;
        line-height: 16px;
        &:dir(rtl) {
          right: 17px;
          left: 0;
        }
        &.isCentered {
          top: 50%;
          font-size: 14px;
        }
      }

      .DropdownSelectedItemTitle {
        margin-left: 8px;
        &:dir(rtl) {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }

  .SignUpGuestGenderDateOfBirth {
    .InputControlInput {
      border-radius: 50px;
      padding-left: 23px;
      background-color: var(--color-mini-button);
      border: none;
      min-width: calc(100% - 16px);

    }

    .InputControlLabel {
      background-color: transparent;
      left: 17px;
      top: 8px;
      font-size: 9px;
      line-height: 16px;
      &:before{
        background-color: transparent;
      }

      &.isCentered {
        top: 50%;
        font-size: 14px;
      }
    }
  }

  .InputControlContainer {
    width: 100%;

  }
}
