@import "src/assets/styles/_variables";
.ReservationStepper {
  padding: 0 16px 16px;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  position: relative;
}

.StepperProgressBar{
  display: flex;
  justify-content: space-between;
}
.StepperProgressBarLine{
  width: 78px;
  height: 3px;
  border-radius: 8px;
  background-color: var(--color-light-gray);
  margin-top: 16px;
  margin-bottom: 40px;
  &.isPassed{
    background: var(--color-success);

  }
}