.Item {
  border-radius: 12px;
  border: 1px solid var(--color-stroke);
  background: var(--color-background-light-mode);
  padding: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;


  &:hover {
    background-color: var(--color-group-background-light-mode);
  }
}

.ItemImage {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
}

.ItemUnPublishedOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  width: calc(100% - 10px);
  background-color: black;
  opacity: 0.5;
  margin: 5px;
  border-radius: 8px;
}

.ItemClosedEye {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 55px;
}

.ItemInfo {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.ItemName {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ItemEditButton {
  margin-top: auto;
}

.ItemInfoPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  margin-right: 10px;
}

.ItemInfoPrice > .Price > h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ItemThreeDots {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
