@import "src/assets/styles/_variables";

.Dropdown {
  width: 100%;
  position: relative;
}

.DropdownSelectedItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 46px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--color-gray);

  &.hasError {
    border: 1px solid var(--color-error);
  }
  color: var(--color-text-black);
  cursor: pointer;

  &.isBorderless {
    border: none;

    .DropdownArrow {
      display: none;
    }

    h6 {
      margin-right: 0;
    }
  }

  &.isDisabled {
    h6{
      color: var(--color-inverse-50);
    }
    .DropdownArrow{
        filter: opacity(0.5);
    }
  }

  h6 {
    word-break: break-all;
    //margin-right: 10px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}

.DropdownOptions {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: var(--color-background-light-mode);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  overflow: hidden;
  z-index: 6;
  max-height: 240px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .DropdownSearch {
    margin: 8px;
    background-color: var(--color-mini-button);
    border: none;
    max-width: calc(100% - 16px);
  }
}

.DropdownArrow {
  transition: 0.3s;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.DropdownOptionsAddButton {
  display: flex;
  align-items: center;
  padding-top: 16px;
  justify-content: flex-start;
}

.DropdownOptionsAddButtonIcon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.DropdownSelectedItemSubTitle {
  color: var(--color-inverse-75);
}

.DropdownGroupName {
  padding: 16px 16px 8px 16px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.EmptyDropdown {
  padding: 8px 16px;
}

.DropdownSubOption {
  padding-left: 30px;
}

.DropdownSelectedItemTitle {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.DropdownSelectedItemTitleImage {
  width: 24px;
  height: 24px;
  margin-right: 11px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.DropdownSelectedItemTitleSymbol {
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.DropdownLabel {
  position: absolute;
  left: 8px;
  transform: translateY(-50%);
  padding: 0 8px;
  color: var(--color-inverse-50);
  background-color: var(--color-background-light-mode);
  top: -1px;
  transition: $defaultTransition;
  pointer-events: none;
  font-size: 12px;

  &.hasError {
    color: var(--color-error);
  }

  &.isCentered {
    top: 50%;
    font-size: 14px;
  }
}
