@import "src/assets/styles/variables";

.AdminOrderHeader {
  padding: 28px 28px 28px 32px;
  background: var(--color-mini-button);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .NewDropdown {
    height: 56px;
    width: 192px;
  }
}
.AdminOrderHeaderTitle {
  font-size: 24px;
}

.AdminOrderHeaderActionButtons {
  display: flex;
  align-items: center;
  gap: 16px;
  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .AdminOrderOption {
    width: 56px;
    height: 56px;
    svg {
      width: 28px;
      height: 28px;
      path {
        stroke: var(--color-pos-utility-colors-dark-gray);
      }
    }
    &.isActive {
      background-color: var(--color-pos-brand-colors-primary-normal);
      border: 1px solid var(--color-pos-brand-colors-primary-normal);
      svg {
        path {
          stroke: var(--color-text-black);
        }
      }
    }
  }
}
.AdminOrderHeaderChatButton {
  position: relative;
}

@media (max-width: 770px) {
  .AdminOrderHeader {
    padding: 16px;
    .NewDropdown {
      width: 100%;
    }
  }
  .AdminOrderHeaderTitle {
    display: none;
  }
  .AdminOrderHeaderGuestsButton,
  .AdminOrderHeaderChatButton {
    width: 50%;
    .AdminOrderOption {
      width: 100%;
    }
  }
  .AdminOrderHeaderActionButtons {
    width: 100%;
    align-items: flex-start;
    flex-direction: column-reverse;
    > div {
      gap: 8px;
      width: 100%;
    }
    > div:first-child {
      > div {
        width: 100%;
      }
    }
    > div:nth-child(2) {
      flex-direction: row-reverse;
    }
  }
}
@media (max-width: $tablet) {
  .AdminOrderHeaderTitle {
    display: none;
  }
}
