@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.OrderItem {
  padding: 10px;
  background-color: var(--color-background-light-mode);
  border-radius: 8px;
  position: relative;
  animation: OrderItemAnimation 0.2s ease;

  &.isPaid {
    display: flex;
    padding: 8px 10px;
    flex-direction: column;
    align-items: flex-start;

    .OrderItemDetails {
      width: 100%;
    }
  }

  &.isDeleted,
  &.isDenied,
  &.isPaid {
    background-color: transparent;
    border: 1px solid var(--color-gray);

    .UndoButton {
      background-color: var(--color-text-white);

      &:hover,
      &:active {
        background-color: var(--color-group-background-light-mode);
      }
    }
  }

  &.isPendingDelete,
  &.isPendingEdit {
    position: relative;
    pointer-events: none;
  }

  &.isPendingEdit::before,
  &.isPendingDelete::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
  }

  &.isPendingEdit::after {
    content: url("../../../../assets/icons/edit/editLight.svg");
  }

  &.isPendingDelete::after {
    content: url("../../../../assets/icons/trash/trashLight.svg");
  }

  &.isPendingDelete::after,
  &.isPendingEdit::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
  }
}

.RedBorderForDeletedItem {
  border: 1px solid var(--color-error);
}

.OrderItemDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  .OrderItemDetailCountAndName {
    display: flex;
  }

  .OrderItemDetailsCount {
    margin-right: 10px;
    color: var(--color-inverse-75);
  }

  .OrderItemDetailsCenter {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //margin-left: 16px;
  }

  .OrderItemDetailsLeft {
    margin-right: 16px;
  }

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    height: auto !important;
    //padding-bottom: 10px;
    .OrderItemDetailsCenter {
      order: 1;
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

.OrderItemDetailsDeleted {
  height: auto;
}

.OrderItemDetailsPrice {
  display: flex;
  align-items: center;
  gap: 4px;
}

.OrderItemDetailsNameContainer {
  max-height: 32px;
  display: flex;
  align-items: center;
}

.OrderItemDetailsName {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-right: 16px;
  word-break: break-word;
}

.OrderItemDetailsListModifications {
  list-style: none;
}

.OrderItemDetailsListModification {
  position: relative;
  margin-left: 14px;

  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-all;
  }

  &:before {
    position: absolute;
    width: 3px;
    height: 3px;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-text-black);
    content: "";
    border-radius: 50%;
  }

  h6 {
    color: var(--color-text-black);
  }
}

.OrderItemDetailsList {
  padding-top: 10px;
  border-top: 1px solid var(--color-stroke);
  width: 100%;
  @media (max-width: $mobile) {
    margin-top: 10px;
  }
}

.OrderItemDetailsListButtons {
  display: flex;
  align-items: center;
  margin-left: 16px;
  .DeleteButton{
    &.isDisabledWithFill{
      background-color: var(--color-mini-button);
      >svg{
        path{
          fill: var(--color-inverse-25);
        }

      }

    }
    &.isDisabledWithStroke{
      background-color: var(--color-mini-button);
      >svg{
        path{
          stroke: var(--color-inverse-25);
        }

      }


    }
  }


  > div:first-child {
    margin-right: 12px;
  }

  .EditButtonMargin {
    margin-right: 12px;
  }
}

.OrderItemDetailsListButtonsDontConfirm {
  width: 16px;
  height: 16px;

  path {
    stroke: var(--color-error);
  }
}

.OrderItemStatus {
  display: flex;
  align-items: center;

  &.isPaid {
    .OrderItemStatusCircle {
      background-color: var(--color-success);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      display: block;
    }

    .isPaid {
      color: var(--color-success);
      font-style: normal;
    }
  }

  &.isPaymentInProgress {
    .OrderItemStatusCircle {
      background-color: var(--color-admin-primary);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      display: block;
    }

    .isPaymentInProgress {
      font-style: normal;
    }
  }

  &.isDenied,
  &.isDeleted {
    .OrderItemStatusCircle {
      background-color: var(--color-error);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      display: block;
    }
  }
}

@keyframes OrderItemAnimation {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
