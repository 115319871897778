.TagWrapper {
  display: flex;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 11px;

  &.typeB {
    gap: 8px;

    .Tag {
      cursor: pointer;
      padding: 8px 16px;
    }
  }

  &.typeF {
    gap: 4px;

    .Tag {
      cursor: pointer;
      padding: 8px 16px;
    }
  }

  &.typeE {
    .TagItem {
      color: var(--color-text-black);
      &:not(:last-child)::before {
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: var(--color-text-black);
        border-radius: 50%;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }
    }
  }

  &.typeF {
    .TagItem {
      color: var(--color-text-black);
      .Chips {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 12px;
        background: var(--color-info-50);

        .h8 {
          color: #084db5;
        }
        svg {
          max-width: 12px;
          max-height: 12px;
        }
      }
    }
  }

  &.isWrapped {
    overflow: visible;
    flex-wrap: wrap;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .Tag {
    color: var(--color-inverse-85);
    padding: 4px 16px;
    border-radius: 32px;
    background: var(--color-blur-black-5);
    white-space: nowrap;

    &.isActive {
      border: 1px solid var(--color-admin-primary);
      background: var(--color-opacity-15);
    }

    &.typeC {
      display: flex;
      align-items: center;
      padding: 9px 10px;
    }

    &.typeD {
      padding: 12px 10px;
      cursor: pointer;
      background: var(--color-mini-button);
      border: none;
      gap: 4px;
      p {
        color: var(--color-inverse-75);
      }

      &.isActive {
        background-color: var(--color-text-black);

        p {
          color: var(--color-text-white);
        }
      }
    }
  }
}

.TagClose {
  margin-left: 10px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.TagItem {
  color: var(--color-inverse-50);
  position: relative;
  white-space: nowrap;

  &:not(:last-child)::before {
    display: none;
  }
}
