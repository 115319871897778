@import "src/assets/styles/variables";

.Root {
  width: 258px;
  height: 100%;
  display: none;
  background-color: #f7f7f7;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 28px 16px;
  position: absolute;
  top: 0;
  left: -100%;
  transition: left 0.2s;
  z-index: 7;

  &.isOpen {
    left: 0;
  }
}

.Icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Top {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 28px;
  cursor: pointer;
}

.Bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  .NetworkWrapper {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: $mobile) {
  .Root {
    display: flex;
  }
}
