.IconAskBill{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--color-pos-text-white-low);
  position: relative;

  .IconAskBillSymbol{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #8A8BEF;
    line-height: 14px;
    width: 14px;
    height: 14px;
    text-align: center;
  }
  &.isActive{
    border: 2px solid var(--color-text-white);
    .IconAskBillSymbol{
      color: var(--color-text-white);
    }
  }
}