@import "src/assets/styles/variables";

.ForgotPassword {
  .ForgotPasswordFormInput {
    margin-top: 40px;
  }

  .VerifyPasswordFormInput {
    margin-top: 40px;
  }

  .ForgotPasswordFormButton {
    margin-top: 56px;
  }
  .ForgotPasswordEmailOrPhone {
    margin-top: 40px;
    h5 {
      display: none;
    }
  }

  .LoginTitle {
    margin: 0 0 16px;
  }
}

.VerifyPasswordForm {
  .VerifyPasswordFormButton {
    margin-top: 56px;
  }
}
