@import "src/assets/styles/mixins";


.Option {
  cursor: pointer;
  transition: 0.3s;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  border-radius: 8px;
  h5{
    color: var(--color-text-black-medium);
  }
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:hover {
    background-color: var(--color-pos-neutral-4);
    h5{
      color: var(--color-text-black-high);
    }
  }

  &.isActive {
    background: var(--color-pos-brand-colors-secondary-low);
    h5{
      color: var(--color-text-black-high);
    }
  }

  &.OptionGrouped {
    padding: 8px 12px 8px 16px ;
    h5{
      @include text-truncate-ellipsis(2);
      word-break: break-word;
    }

  }

  &.isDisabled {
    pointer-events: none;
    h6 {
      color: var(--color-inverse-75) !important;
    }
  }

  &.hasCurrentDropdownOption {
    .OptionName {
      color: var(--color-text-black) !important;
    }
  }
}

.WithIconOptionName {
  margin-bottom: 4px;
}
