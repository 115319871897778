@import "src/assets/styles/variables";

.AdminOrderPage {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  background: var(--color-background-light-mode);

  .AdminOrderPageHeader {
    height: 116px;
    box-shadow: 1px 1px 69px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    padding: 16px 30px;
    position: relative;
    > div {
      display: flex;
    }
    .AdminOrderPageHeaderReturnButton {
      //padding: 0 16px;
      //border-left: 1px solid var(--color-gray);
      //border-right: 1px solid var(--color-gray);
      margin-left: 16px;
      @media (max-width: $tablet) {
        padding: 0;
        border: none;
        height: 84px;
      }
      .AdminOrderPageHeaderTableIcon {
        background: var(
          --dsd,
          linear-gradient(148deg, #5f5ff6 19.23%, #5c2bc4 80.99%)
        );
        p {
          color: var(--color-background-light-mode);
        }
        @media (max-width: $tablet) {
          max-width: 100px;
          width: 100%;
        }
      }
    }

    .AdminOrderPageHeaderActionButtons {
      width: calc((100px * 4) + 32px);
      display: flex;
      //justify-content: flex-end;
      .AdminOrderPageHeaderDeleteIcon {
        background: var(--color-red-15);
        &.isDisabled{
          background-color: var(--color-mini-button);
          svg{
            path{

              fill: var(--color-inverse-50);
            }

          }
          p{
            color: var(--color-inverse-50);
          }
          &:hover {
            background: var(--color-mini-button);

            p {
              color: var(--color-inverse-50);
            }

            svg path {
              fill: var(--color-inverse-50);
            }
          }

        }
        svg {
          width: 26px;
          height: 26px;
        }
        p {
          color: var(--color-dark-mode-error);
        }
        &:hover {
          background: var(--color-error);
          p {
            color: var(--color-text-white);
          }

          svg path {
            fill: var(--color-background-light-mode);
          }
        }
      }
      .AdminOrderPageHeaderFinishIcon {
        svg {
          width: 26px;
          height: 26px;
        }
        background: var(--color-green-15);
        p {
          color: var(--toastify-color-success);
        }

        &:hover {
          background: var(--color-success);
          p {
            color: var(--color-text-white);
          }

          svg path {
            stroke: var(--color-background-light-mode);
          }
        }
      }
      @media (max-width: $tablet) {
        width: 100%;
        height: 84px;
        p {
          width: 90%;
          overflow: hidden;
          white-space: nowrap;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        .AdminOrderPageHeaderDeleteIcon {
          max-width: 100px;
          width: 100%;
        }
        .AdminOrderPageHeaderFinishIcon {
          max-width: 100px;
          width: 100%;
        }
      }
    }
    @media (max-width: $tablet) {
      height: 220px;
      padding: 36px 16px;
      .AdminOrderPageHeaderTabs {
        position: absolute;
        left: 0;
        bottom: 16px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        width: 100%;
        padding: 0 16px;
      }
    }
  }
  .SpinnerHeight {
    height: calc(100vh - 116px);
  }
  .AdminOrderPageContent {
    width: 100%;
    height: calc(100% - 116px);
    display: flex;
    justify-content: space-between;
    .AdminOrderHomeDisplay {
      background: var(--color-mini-button);
      width: 60%;
      height: 100%;
      display: flex;
      padding: 24px 30px;
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (max-width: $desktop) {
        flex-direction: column;
        display: block;
      }
      @media (max-width: $tablet) {
        width: 100%;
        padding: 16px 16px;
        display: flex;
        gap: 16px;
        .AdminOrderPageHomeInfoBox {
          grid-row: 2;
        }
        .AdminOrderPageHomeInfo {
          grid-row: 1;
        }
      }
    }
    .AdminOrderMenuDisplay {
      background-color: var(--color-mini-button);
      width: 60%;
      padding: 0 30px;
      display: grid;
      .AdminOrderMenuDisplayContainer {
        width: 100%;
        height: calc(100% - 50px);
        margin-top: 24px;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @media (max-width: $tablet) {
        width: 100%;
        padding: 0 16px;
      }
    }
    .AdminOrderChatDisplay {
      background: var(--color-mini-button);
      width: 60%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
    .AdminOrderPrintsDisplay {
      background: var(--color-mini-button);
      width: 60%;
      height: 100%;
      padding: 24px 30px 0;
      @media (max-width: $tablet) {
        width: 100%;
        padding: 24px 16px 0;
      }
    }
    .AdminOrderDetail {
      flex: 1;
      padding: 0 30px;
      overflow-x: auto;
      height: calc(100% - 20px);
      min-width: 550px;
      @media (max-width: $tablet) {
        //position: absolute;
        //left: 100%;
        display: none;
      }
    }
    @media (max-width: $tablet) {
      height: calc(100% - 220px);
    }
  }
}
