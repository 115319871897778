.OrderMenuItem {
  border-radius: 8px;
  background: var(--color-background-light-mode);
  padding: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
}

.OrderMenuItemBottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.OrderMenuItemPrice{
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex-direction: column;
}
.OrderMenuItemBottomAdd {
  button {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    background-color: var(--color-text-white);
  }
}
