@import "src/assets/styles/_variables";

.PageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  background-color: var(--color-background-light-mode);

  .PageTitleText {
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

@media (max-width: $mobile) {
  .PageTitle {
    padding-bottom: 27px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--color-stroke);
  }
}
