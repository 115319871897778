.InfraCard {
  width: 172px;
  height: 104px;
  border-radius: 16px;
  background: var(--color-text-white);
  box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  cursor: pointer;
  transition: 50ms all ease-out;

  &:hover{
    .InfraCardContentOnHover{
      display: block;
    }
  }

  &:hover {
    scale: 1.1;
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.09);
  }
}

.InfraCardContentOnHover{
  display: none;
  position: absolute;
  top: 0;
  left: 33px;
  background-color: var(--color-text-white);
  padding: 8px;
  text-wrap: wrap;
  width: 95px;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  h6{
    word-break:break-word;
  }
}
.InfraCardCount{
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
