@import "src/assets/styles/variables";

.AddEmployeeModal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  z-index: 100;

  .AddEmployeeModalBackground {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background-modal);
    color: white;
  }

  .AddEmployeeModalContainer {
    border-radius: 16px;
    padding: 30px;
    min-width: 560px;
    min-height: 560px;
    overflow: hidden;
    background-color: var(--color-background-light-mode);
  }
}
