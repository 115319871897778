@import "src/assets/styles/_variables";

.Root {
  width: 100%;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  height: 68px;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NetworkContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.BtnContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  .Button {
    height: 52px;
    min-width: 160px;
  }
}

@media (max-width: $mobile) {
  .Root {
    display: none;
  }
}
