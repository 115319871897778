.ScrollViewWrapper {
  display: flex;
  gap: 16px;
  scrollbar-width: none;
  overflow: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}