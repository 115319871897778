@import "src/assets/styles/_variables";

.AdminDashSalesStats {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .AdminDashboardChartsDonutChart {
    width: 50%;
    .StatsCardChartWrapper {
      width: initial;
      height: 460px;
    }
    .StatsCardChart {
      display: flex;
      align-self: center;
    }
  }

  .AdminSalesDashboardEmptyStateDescription {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  @media (max-width: $tablet) {
    .AdminDashboardChartsDonutChart {
      width: 100%;
    }
  }
}
