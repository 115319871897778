.TagsWithIconWrapper {
  display: flex;
  gap: 8px
}

.TagWithIcon {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &.typeB {
    padding: 4px 8px;
    border-radius: 32px;
    border: 1px solid var(--color-gray-30);

    h6 {
      text-wrap: nowrap;
    }
  }

  &.active {
    background: var(--color-light-mode-black);

    h6 {
      color: var(--color-text-white);
    }
  }

  .tagIcon {
    width: 20px;
    height: 20px;
  }
}
