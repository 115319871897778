@import "src/assets/styles/_variables";

.AdminDashAllMenus {
  .AdminDashMenuStatFormCustomTable {
    .TableCustomRowItem {
      position: relative;
    }
    .TableCustomRowItem:nth-child(1) {
      position: initial;
    }
    .TableCustomRowItem:nth-child(2) {
      position: initial;
    }
  }

  .TableItemInfo {
    .TableItemImage {
      width: 36px;
      height: 36px;
      img {
        width: inherit !important;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
    .MenuItemName {
      width: 58%;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: initial;
    }
  }

  .TableMainInfoPart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 9px;

    .ImageNameContainer {      
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 9px;
      width: 80%;

      img {
        width: 30px;
        height: 30px;
        border-radius: 20%;
      }
    }
  }

  .headerCell{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .TableSpinner {
    max-height: 250px
  }

  p {
    font-size: 12px;
    font-weight: 600;
  }
}

.AdminDashHistoryFormAndTableEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 48px;

  .AdminDashHistoryFormAndTableEmptyInfo {
    color: var(--color-inverse-50);
  }
}