@import "src/assets/styles/_variables";

.Root {
  width: 100%;
  position: relative;
}

.SelectedItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  border-radius: 1000px;
  background: var(--color-pos-neutral-4);
  color: var(--color-text-black);
  cursor: pointer;

  h6 {
    word-break: break-all;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}

.Options {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background: var(--color-background-light-mode);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);

  border: 1px solid var(--color-pos-neutral-3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  z-index: 6;
  max-height: 240px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  .Search {
    background-color: var(--color-mini-button);
    border: none;
    width: 100%;
  }
}

.Arrow {
  transition: 0.3s;
  width: 24px;
  height: 24px;
  &.isOpen {
    transform: rotate(180deg);
  }
}

.EmptyDropdown {
  padding: 8px 16px;
}

.SelectedItemTitle {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.SelectedItemTitleImage {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.SelectedItemTitleSymbol {
  margin-right: 18px;
  display: flex;
  align-items: center;
}

@media (max-width: $mobile) {
  .Arrow {
    display: none;
  }
  .selectedLanguage {
    display: none;
  }
  .SelectedItem {
    background-color: var(--color-pos-neutral-3);
  }
  .SelectedItemTitleImage {
    margin-right: 0;
    width: 24px;
    height: 24px;
  }
  .DropDownOptions {
    padding: 4px;
    justify-content: center;
    div {
      img {
        margin-right: 0;
        width: 20px;
        height: 20px;
      }
      h6 {
        display: none;
      }
    }
  }
}
