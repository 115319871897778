@import "src/assets/fonts/fonts";

//Menu Settings
$preferred_primary_color_bg: #FFCA40;
$preferred_secondary_color_bg: #FFCA40;
$preferred_primary_color_text: rgba(0, 0, 0, 0.87);
$preferred_secondary_color_text: rgba(0, 0, 0, 0.87);

$preferred_primary_border_radius: 12px;

$preferred_border_radiusA: 8px;
$preferred_border_radiusB: 8px;
$preferred_border_radiusC: 8px;
$preferred_border_radiusD: 8px;

$preferred_border_radiusE: 8px;
$preferred_border_radiusF: 8px;
$preferred_border_radiusG: 8px;
$preferred_border_radiusL: 4px;
$preferred_border_radiusM: 4px;
$preferred_border_radiusK: 3px;
$preferred_border_radiusS: 4px;
$preferred_border_radiusT: 0px;
$preferred_border_radiusZ: 0px;

$preferred_marginMR: 0px;

$width_Inner_Width: 390;

//Light mode colors
$colorL_Mode: #ababab;
$colorL_Primary: #ffdd00;
$colorL_Primary_15: #fffad9;
$colorL_Primary_5: #ffca400d;
$colorL_Admin_Primary: #ffca40;
$colorL_Admin_Primary_15: rgba(255, 202, 64, 0.15);
$colorL_Admin_Primary_Hover: #ffb92a;
$colorL_Admin_Primary_Disabled: #ffe399;
$colorL_Admin_Primary_15: rgba(255, 202, 64, 0.15);

$colorL_Inverse_100: #000000;
$colorL_Inverse_85: #414141;
$colorL_Inverse_75: #676767;
$colorL_Inverse_50: #8d8d8d;
$colorL_Inverse_25: #b3b3b3;
$colorL_Blur_Black_5: rgba(0, 0, 0, 0.05);
$colorL_Blur_White_30: rgba(255, 255, 255, 0.3);
$colorL_Glass_White_90: rgba(245, 245, 245, 0.90);

$colorL_Light_Black: rgba(0, 0, 0, 0.09);
$colorL_Text_Black: #1f1f1f;
$colorL_Text_Black_High: rgba(0, 0, 0, 0.87);
$colorL_Text_Black_Medium: rgba(0, 0, 0, 0.60);
$colorL_Text_White: #fff;
$colorL_Text_White_Low: rgba(255, 255, 255, 0.38);
$color-text-yellow: #fed905;
$colorL_Discount_Price: rgba(0, 0, 0, 0.38);

$colorL_Text_Medium: rgba(255, 255, 255, 0.60);
$colorL_Text_Low: rgba(255, 255, 255, 0.38);
$colorL_Text_Black_Low: rgba(0, 0, 0, 0.38);


$colorL_Gray: #d9d9d9;
$colorL_Gray_800: #0D0D0D;
$colorL_Gray_700: #1C1C1C;
$colorL_Gray_600: #2E2E2E;
$colorL_Gray_20: #F5F5F5;
$colorL_Gray_30: #ebebeb;
$colorL_Gray_40: #DEDEDE;
$colorL_Gray_50: #BFBFBF;
$colorL_Gray_80: #949494;
$colorL_Light_Gray: #e9e9e9;
$colorL_Dark_Gray: #3E3E3E;
$colorL_Very_Dark_Gray: #2E2E2E;
$colorL_Stroke: #efeded;
$colorL_Mini_Button: #f7f7f7;
$colorL_Mini_Button_Reverse: #383838;

$colorL_Secondary: #f39237;

$colorL_Error: #f34237;
$colorD_Error: #ff6b6b;
$colorL_Semantic_Error: #E3152E;
$color_Deleted_Border_Unselect: #ecc0bd;
$color_Deleted_Background_Unselect: #ffe3e1;

$colorL_Red_15: rgba(243, 66, 55, 0.15);

$colorL_Success: #4cbf4a;
$colorL_Info_50: #E7F0FF;


$colorL_Dark_Green: #2F3128;
$colorL_Green: rgba(76, 191, 74, 0.3);
$colorL_Green_15: rgba(76, 191, 74, 0.15);
$colorL_Green_30: rgba(76, 191, 74, 0.3);

$colorL_Opacity_5: rgba(255, 202, 64, 0.05);
$colorL_Opacity_15: rgba(255, 202, 64, 0.15);
$colorL_Indigo-100: #d2d6ef;

$colorL_Gradient_Main: linear-gradient(90deg, #ffdd00 0%, #fbb034 100%);

$colorL_Gradient_Blue: linear-gradient(90deg, #5f5ff6 0%, #5c2bc4 100%);

$colorL_Gradient_LightBlue: linear-gradient(270deg, #9288FF 0%, #92F2FF 100%);

$colorL_Gradient_Brand_Secondary: linear-gradient(180deg, #4042E2 0%, #4F52FF 100%);

$colorL_Blue: #5f5ff6;
$colorL_Purple: #9747FF;
$colorL_Purple_200: #9288FF;

$colorL_Purple_Opacity_20: rgba(151, 71, 255, 0.20);
$colorL_Dark_Purple_Opacity_25: rgba(23, 12, 32, 0.25);

$colorL_Gradient_Menu_Item_Page: linear-gradient(
                180deg,
                #c3c3c3 0%,
                #f2f2f2 100%
);


$colorL_Background_Light_Mode: #fff;
$colorL_Background_Light_Mode_Black: #101010;

$colorL_Group_Background_Light_Mode: #ededed;

$colorL_Background_Dark_Mode: #404040;
$colorL_Background_Black: $colorL_Text_Black;

$colorL_Background_Modal: rgba(0, 0, 0, 0.6);

$colorL_Background_Modal_Blur: rgba(255, 255, 255, 0.05);

$colorL_Background_Yellow: #d9d43f; //for info page

$color_Under_Construction: #fdc71a;

//Dark mode colors
$colorD_Mode: #3a3a3a;

// font families
$fontBody: "Inter";
$fontTTHoves: "TTHoves";
$fontCabinet: "Cabinet";

//border radius
$borderRadiusM: 8px;

//modal
$modalHeaderHeight: 70px;
$modalTopPaddingMobile: 64px;

//breakpoints
$desktop: 1280px;
$tablet: 1000px;
$mobile: 600px;
$mobileXS: 350px;

:export {
  desktopBreakpoint: $desktop;
  tabletBreakpoint: $tablet;
  mobileBreakpoint: $mobile;
}

// default transition
$defaultTransition: 150ms;

// default tab bar transition
$defaultTabBarTransition: 0.6s;


//POS

$color-pos-text-black-high: rgba(0, 0, 0, 0.87);
$color-pos-text-black-medium: rgba(0, 0, 0, 0.60);
$color-pos-text-black-low: rgba(0, 0, 0, 0.38);


$color-pos-text-white-high: #FFF;
$color-pos-text-white-medium: rgba(255, 255, 255, 0.60);
$color-pos-text-white-low: rgba(255, 255, 255, 0.38);


$color-pos-gray-0: #FFF;
$color-pos-gray-10: #FAFAFA;
$color-pos-gray-20: #F5F5F5;
$color-pos-gray-30: #EBEBEB;
$color-pos-gray-40: #DEDEDE;
$color-pos-gray-50: #BFBFBF;
$color-pos-gray-60: #B0B0B0;
$color-pos-gray-70: #A3A3A3;
$color-pos-gray-80: #949494;
$color-pos-gray-90: #858585;
$color-pos-gray-100: #757575;
$color-pos-gray-200: #666;
$color-pos-gray-300: #575757;
$color-pos-gray-400: #4A4A4A;
$color-pos-gray-500: #3B3B3B;
$color-pos-gray-600: #2E2E2E;
$color-pos-gray-700: #1C1C1C;
$color-pos-gray-800: #0D0D0D;
$color-pos-gray-900: #000;


$color-pos-red-50: #FFECEC;
$color-pos-red-100: #FFC3C3;
$color-pos-red-200: #FFA6A6;
$color-pos-red-300: #FF7E7E;
$color-pos-red-400: #FF6565;
$color-pos-red-500: #FF3E3E;
$color-pos-red-600: #E83838;
$color-pos-red-700: #B52C2C;
$color-pos-red-800: #8C2222;
$color-pos-red-900: #6B1A1A;


$color-pos-blue-50: #E7F0FF;
$color-pos-blue-100: #B3D2FF;
$color-pos-blue-200: #8FBCFF;
$color-pos-blue-300: #5C9DFF;
$color-pos-blue-400: #3C8AFF;
$color-pos-blue-500: #0B6DFF;
$color-pos-blue-600: #0A63E8;
$color-pos-blue-700: #084DB5;
$color-pos-blue-800: #063C8C;
$color-pos-blue-900: #052E6B;


$color-pos-yellow-50: #FFFCEF;
$color-pos-yellow-100: #FDF4CF;
$color-pos-yellow-200: #FDEFB7;
$color-pos-yellow-300: #FCE896;
$color-pos-yellow-400: #FBE382;
$color-pos-yellow-500: #FADC63;
$color-pos-yellow-600: #E4C85A;
$color-pos-yellow-700: #B29C46;
$color-pos-yellow-800: #8A7936;
$color-pos-yellow-900: #695C2A;


$color-pos-green-50: #EEF2EE;
$color-pos-green-100: #C9D5CB;
$color-pos-green-200: #AFC1B2;
$color-pos-green-300: #8AA58E;
$color-pos-green-400: #749379;
$color-pos-green-500: #517857;
$color-pos-green-600: #4A6D4F;
$color-pos-green-700: #3A553E;
$color-pos-green-800: #2D4230;
$color-pos-green-900: #223225;


$color-pos-neutral-1: #C0C0CC;
$color-pos-neutral-2: #DFDEE7;
$color-pos-neutral-3: #EEEDF2;
$color-pos-neutral-4: #F5F5F7;


$color-pos-utility-colors-white: #FFF;
$color-pos-utility-colors-dark-gray: #57636C;
$color-pos-utility-colors-gray: #9BA5B5;
$color-pos-utility-colors-black: #13171A;


$color-pos-semantic-colors-error: #E3152E;
$color-pos-semantic-colors-success: #02C04A;
$color-pos-semantic-colors-warning: #EF8F00;
$color-pos-semantic-colors-info: #218AEB;


$color-pos-brand-colors-primary-normal: #FFCA40;
$color-pos-brand-colors-primary-pressed: #F3BB28;


$color-pos-brand-colors-secondary-normal: linear-gradient(90deg, #4042E2 0%, #4F52FF 100%);
$color-pos-brand-colors-secondary-medium: rgba(64, 66, 226, 0.32);
$color-pos-brand-colors-secondary-low: rgba(204, 205, 255, 0.24);
