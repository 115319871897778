@import "src/assets/styles/_variables";

.AdminOrderMenuDisplayWrapper {
  width: 100%;

  &.isAdmin {
    .AdminOrderMenuDisplayCategorySelection {
      position: sticky;
      top: 0;
      background: transparent;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .CategoryItem {
        height: 50px;
        font-size: 18px;
        line-height: 24px;

        &.isActive {
          background-color: var(--color-inverse-85);
        }
      }

      .Search {
        border: none;
        height: 50px;
      }
    }

    .AdminMenuAdminOrderMenuDisplayEmptyState {
      height: 80%;
    }
  }

  &.isGuest {
    .AdminOrderMenuDisplayCategorySelectionSection {
      position: fixed;
      z-index: 5;
    }
  }

  &.hideOverflowY {
    overflow-y: hidden;
  }
}

.AdminOrderMenuDisplayNoResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 95px;
}

.AdminOrderMenuDisplayNoResultTitle {
  color: var(--color-text-black);
  margin-top: 32px;
}

.AdminOrderMenuDisplayNoResultDescription {
  color: var(--color-inverse-50);
  margin: 16px 0 32px 0;
  max-width: 200px;
  text-align: center;
}

.AdminOrderMenuDisplayCategorySelectionSection {
  padding: 40px 0 16px 0;
  width: 100%;
  background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 1) 40%,
                  rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(4px);
}

.AdminOrderMenuDisplayCategory {
  overflow-x: hidden;
  height: 100%;
  scrollbar-width: none;

  .MenuCategories:first-child {
    .AdminOrderMenuDisplaySectionName {
      margin-top: 48px;
    }

    .AdminOrderMenuDisplaySectionNameForOrderPage {
      margin-top: 40px;
    }
  }

  .MenuCategories:last-child {
    .MenuViewList {
      border: none;
    }
  }
}

.AdminOrderMenuDisplayEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  gap: 16px;
  text-align: center;

  img {
    width: 100%;
    max-height: 120px;
  }
}
