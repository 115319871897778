@import "src/assets/styles/_variables";

@mixin backgroundStyles {
  background: var(--color-primary);
  //box-shadow: none;
  border: none;
}

.RadioButtonContainer {
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-inverse-85);
  border-radius: 50%;
  background-color: var(--color-background-light-mode);
}

.RadioButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: var(--color-inverse-85);
  background-color: var(--color-background-light-mode);
  border: 1px solid var(--color-stroke);
  img {
    width: 13px;
  }
}

.isCountable,
.isUncountable {
  background-image: none;
  padding: 0;

  .RadioButton {
    @include backgroundStyles();
  }
}
