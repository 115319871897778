@import "src/assets/styles/_variables";

.InputControl {
  position: relative;
  display: flex;
  flex-direction: column;
}

.InputControlContainer {
  display: flex;
  flex-direction: column;
}

input::-webkit-date-and-time-value {
  text-align: left;
  padding-left: 17px;
}

.InputControlLabel {
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: 8px;
  transform: translateY(-50%);
  padding: 0 8px;
  color: var(--color-inverse-50);
  top: 0;
  transition: $defaultTransition;
  pointer-events: none;
  font-size: 12px;
  font-weight: 500;

  &:dir(rtl) {
    right: 8px;
    left: auto;
  }

  &.typeA {
    &:before {
      background-color: var(--color-mini-button);
    }
  }

  &.typeB {
    &:before {
      background-color: var(--color-background-light-mode);
    }
  }

  &.noFloating {
    display: none;
    background: transparent;

    &:before {
      background-color: transparent;
    }
  }

  &.hasError {
    color: var(--color-error);
  }

  &:before {
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 50%;
    //background-color: var(--color-background-light-mode);
    //transform: translateY(-50%);
    content: "";
    z-index: -1;
  }
}

.InputControlRequiredSign {
  color: var(--color-error);
  line-height: 1;
  margin-left: 4px;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.InputControlInput {
  padding: 0 16px;
  height: 46px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-gray);
  background: transparent;
  font-size: 14px;
  font-weight: 500;

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
  }

  &[type="number"] {
    /* Remove number input arrows for WebKit browsers */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &.hasExtraRightPadding {
    padding-right: 40px;
  }

  &.hasExtraPadding {
    padding-right: 64px;
  }

  &::placeholder {
    color: transparent;
  }

  &.noFloating {
    &::placeholder {
      color: var(--color-inverse-50);
      line-height: 20px;
      font-size: 14px;
    }
  }

  &.isReadOnly {
    color: var(--color-inverse-50);
  }

  &:placeholder-shown {
    ~ .InputControlLabel {
      top: 50%;
      font-size: 14px;
    }
  }

  &:focus {
    border: 1px solid var(--color-admin-primary);

    ~ .InputControlLabel {
      top: 0;
      font-size: 12px;
    }
  }

  &.isBorderless {
    border: none;
  }

  &.hasError {
    border: 1px solid var(--color-error);
  }

  &.hasLabel {
    &::placeholder {
      opacity: 0;
    }
  }

  &.infoText {
    padding-right: 40px;
  }
}

.InputControlTextarea {
  display: block;
  resize: none;
  padding: 10px 16px;
  height: 111px;
  font-family: $fontBody;
  font-weight: 500;

  &.noFloating {
    &::placeholder {
      color: var(--color-inverse-50);
      line-height: 20px;
      font-size: 14px;
    }
  }

  &:placeholder-shown {
    ~ .InputControlLabel {
      top: 20px;
      font-size: 14px;
    }
  }

  &:focus {
    ~ .InputControlLabel {
      top: 0;
      font-size: 12px;
    }
  }
}

.InputControlPasswordEye {
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 11px;
  width: 24px;
  height: 24px;
}

.InputControlDefinition {
  position: absolute;
  right: 0;
  top: 8px;
  height: 30px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color-gray);
}

.InputControlInfoText {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    .InputControlInfoTextTooltip {
      display: block;
    }
  }
}

.InputControlVRLink {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);

  &.isActive {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--color-text-black);
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--color-inverse-50);
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

.InputControlInfoTextTooltip {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-background-light-mode);
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 8px;
  width: 232px;
  overflow: auto;
}

.InputControlLabelTop {
  color: var(--color-text-white);
  margin-bottom: 10px;
}

@media (max-width: $mobile) {
  .InputControlInput {
    padding: 12px 14px;
  }
}

