.AdminBusinessGuests {
  padding: 40px 32px 100px;

  .BodyRow {
    position: relative;

    .BirthdayHat {
      transform: rotate(-45deg);
      position: absolute;
      width: 32px;
      height: 32px;
      top: -17px;
      left: -15px;
    }
  }

  .ModalBackground {
    .ModalContainer {
      .Modal {
        padding-top: 0;
        overflow: scroll;
        scrollbar-width: none;

        .ModalHeader {
          position: sticky;
          z-index: 2;
        }

        .ModalBody {
          overflow-y: visible !important;
        }

        .ModalFooter {
          position: static;
        }
      }
    }
  }

  .ThreeDotsContainer {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4.8px;
    background: var(--color-mini-button);

    svg {
      width: 24px;

      circle {
        fill: transparent;
        stroke: var(--color-text-black);
      }
    }
  }

  .AdminBusinessGuestsHeader {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .AdminBusinessGuestsFilters {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      justify-content: flex-end;

      .Dropdown {
        max-width: 150px;
        width: 100%;
      }

      .AdminFiltersSearch.isDisabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .AdminBusinessGuestsTitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      .AdminBusinessGuestsTitleText {
        color: var(--color-text-black);
      }

      .AdminBusinessGuestsCount {
        color: var(--color-inverse-50);
      }
    }
  }
  .AdminBusinessGuestProfileContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .AdminBusinessGuestProfileImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.AdminBusinessGuestTableEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 48px;

  .AdminBusinessGuestTableEmptyInfo {
    color: var(--color-inverse-50);
  }
}
.AdminBusinessSelectedGuest {
  position: fixed;
  top: 90%;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  .SelectedGuestsContainer {
    padding: 8px;
    border: 1px solid var(--color-pos-neutral-2);
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 16px;
    background: rgba(19, 23, 26, 0.72);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(24px);
  }
}
.AdminBusinessSelectedGuestCountContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  border-right: 1px solid var(--Neutral-colors-Neutral-2, #dfdee7);
  h6 {
    color: var(--color-pos-gray-40);
  }
}

.AdminBusinessGuestsCancelButton {
  background: none;
  padding: 12px;
  width: max-content;
  gap: 0;
  svg:first-child {
    margin-right: 0;
  }
}
.SelectedGuestsDiscountButton {
  width: max-content;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  color: var(--color-pos-utility-colors-dark-gray);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 12px;
}

.SelectedGuestsSendButton {
  width: max-content;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  //color: var(--color-pos-utility-colors-black);
  border-radius: 12px;
  //background: var(--color-pos-brand-colors-primary-normal);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.AdminBusinessGuestsModal {
  padding: 24px;
  background-color: #f7f7f7;
  .AdminBusinessGuestsModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    .AddItemModalHeaderCloseButton {
      border-radius: 100px;
      border: 1px solid var(--color-pos-neutral-2);
      background: var(--color-pos-neutral-3);
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  .AdminBusinessGuestsModalBody {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 36px;
    .AdminBusinessGuestsMessageComponent {
      width: 292px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .AdminBusinessGuestsPhone {
      min-width: 274px;
      max-width: 274px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .AdminBusinessGuestPhone {
        width: 100%;
      }
      .AdminBusinessGuestsPhoneMessage {
        width: 227px;
        height: 227px;
        position: absolute;
        z-index: 9;
        .AdminBusinessGuestsPhoneMessageBlock {
          color: var(--Text-White-High, #fff);
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          display: flex;
          width: max-content;
          max-width: 165px;
          word-break: break-word;
          padding: 4px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border-radius: 2px 8px 8px 8px;
          background: #262628;
          margin-top: 15px;
          overflow: hidden;
        }
      }
    }
  }

  .AdminBusinessGuestsModalFooter {
    margin-top: 32px;
    .AdminBusinessGuestsModalFooterButton {
      width: 100%;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .AdminBusinessGuestsModalFooterButton:disabled,
    .AdminBusinessGuestsModalFooterButton.disabled {
      background: var(--color-pos-utility-colors-gray);
      color: var(--color-pos-utility-colors-black);
      cursor: not-allowed;
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.AdminBusinessGuestsCloseModal {
  fill: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  .Modal {
    width: 384px;
    padding: 24px;
    border-radius: var(--l, 20px);
    background: #fff;
    .ModalHeader {
      padding: 24px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: max-content;
      .AdminBusinessGuestsCloseModalHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        div {
          width: min-content;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 6px;
          background: rgba(239, 143, 0, 0.12);
          div {
            width: max-content;
            border-radius: 50%;
            display: flex;
            padding: 4px;
            justify-content: center;
            align-items: center;
            background: rgba(239, 143, 0, 0.16);
          }
        }
      }
    }
    .ModalBody {
      padding: 0;
      margin-top: 24px;
      .AdminBusinessGuestsCloseModalBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        h2 {
          color: var(--color-text-black-high);
          font-size: 24px;
          line-height: 32px;
          text-align: center;
        }
        h4 {
          color: var(--color-text-black-medium);
          text-align: center;
        }
      }
    }
    .ModalFooter {
      width: 100%;
      padding: 40px 0 0 0;
      .AdminBusinessGuestsCloseModalFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .AdminBusinessGuestsCloseModalFooterCancelButton {
          display: flex;
          width: 160px;
          padding: 14px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          border: 1px solid var(--color-pos-neutral-2);
          background: var(--color-pos-neutral-3);
          color: var(--color-pos-utility-colors-dark-gray);

          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .AdminBusinessGuestsCloseModalFooterConfirmButton {
          display: flex;
          width: 160px;
          padding: 14px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          background: var(--color-pos-brand-colors-primary-normal);

          color: var(--color-pos-utility-colors-black);

          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}
.AdminBusinessSuccessModal {
  fill: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  .Modal {
    width: 384px;
    padding: 24px;
    border-radius: var(--l, 20px);
    background: #fff;
  }
  .ModalHeader {
    padding: 24px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    .AdminBusinessGuestsSuccessModalHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 72px;
      div {
        width: min-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 6px;

        background: rgba(2, 192, 74, 0.16);
        div {
          width: max-content;
          border-radius: 50%;
          display: flex;
          padding: 4px;
          justify-content: center;
          align-items: center;
          background: rgba(2, 192, 74, 0.12);
        }
      }
    }
  }
  .ModalBody {
    padding: 0;
    margin-top: 24px;
    .AdminBusinessGuestsSuccessModalBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      h2 {
        color: var(--color-text-black-high);
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
      h4 {
        color: var(--color-text-black-medium);
        text-align: center;
      }
    }
  }
  .ModalFooter {
    width: 100%;
    padding: 40px 0 0 0;
    .SuccessModalButton {
      display: flex;
      width: 100%;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      color: var(--color-pos-utility-colors-white);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      background: var(--color-pos-semantic-colors-success);
    }
  }
}
.AdminBusinessFailModal {
  fill: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  .Modal {
    width: 384px;
    padding: 24px;
    border-radius: var(--l, 20px);
    background: #fff;
  }
  .ModalHeader {
    padding: 24px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    .AdminBusinessGuestsFailedModalHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 72px;
      div {
        width: min-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 6px;
        background: rgba(227, 21, 46, 0.16);
        div {
          width: max-content;
          border-radius: 50%;
          display: flex;
          padding: 4px;
          justify-content: center;
          align-items: center;
          background: rgba(227, 21, 46, 0.12);
        }
      }
    }
  }
  .ModalBody {
    padding: 0;
    margin-top: 24px;
    .AdminBusinessGuestsFailModalBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      h2 {
        color: var(--color-text-black-high);
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
      h4 {
        color: var(--color-text-black-medium);
        text-align: center;
      }
    }
  }
  .ModalFooter {
    width: 100%;
    padding: 40px 0 0 0;
    .FailModalButton {
      display: flex;
      width: 100%;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      color: var(--color-pos-utility-colors-white);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      background: var(--color-pos-semantic-colors-error);
    }
  }
}
