.ReservationStepperPreview {
  border-radius: 12px;
  background: var(--color-mini-button-reverse);
  padding: 16px;
}

.ReservationStepperPreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ReservationStepperPreviewBusiness {
    display: flex;
    align-items: center;
    width: calc(100% - 50px);

    .ReservationStepperPreviewBusinessImage {
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-text-white);
      margin-left: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 40px);

      &:dir(rtl) {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  .ReservationStepperPreviewHeaderThreeDots {
    button {
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
    }
  }
}

.ReservationStepperPreviewDetail {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 8px;

  .ReservationStepperPreviewDetailDate,
  .ReservationStepperPreviewDetailGuest {
    display: flex;
    align-items: center;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--color-text-white);

      &:not(:nth-child(1)) {
        margin-left: 8px;

        &:dir(rtl) {
          position: relative;
          margin-left: 0;
          margin-right: 8px;
        }
      }

      span {
        color: inherit;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;

        &:dir(rtl) {
          margin-left: 0;
          margin-right: 8px;
        }
      }

      .ReservationStepperPreviewDetailCalendarIcon {
        margin-left: 2px;
        flex-shrink: 0;

        &:dir(rtl) {
          margin-left: 0;
          margin-right: 2px;
        }

        path {
          fill: var(--color-text-white);
        }
      }

      .ReservationDetailInputCalendar {
        background-color: transparent;
        color: inherit;
        font-size: 14px;
        font-weight: 500;

        [dir="rtl"] & {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          text-align: right;
          margin-right: 12px;
        }

        &::-webkit-date-and-time-value {
          text-align: left;
          padding: 0;
        }
      }

      .ReservationStepperPreviewDetailTableInfo {
        color: var(--color-text-white);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 8px;

        &:dir(rtl) {
          margin-right: 8px;
          margin-left: 0;
        }

        h5 {
          color: var(--color-text-white);
        }

        h6 {
          color: var(--color-text-white);
          font-weight: 500;
          max-width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          position: relative;

          &:first-child {
            margin-right: 5px;

            &:dir(rtl) {
              margin-left: 5px;
              margin-right: 0;
            }
          }
        }

        div {
          display: flex;
          align-items: center;

          h6 {
            max-width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
          }
        }
      }

      .ReservationStepperPreviewDetailClockIcon {
        margin-left: 2px;
        flex-shrink: 0;

        &:dir(rtl) {
          margin-left: 0;
          margin-right: 2px;
        }

        path {
          fill: var(--color-text-white);
        }
      }

      .ReservationTableAndZoneName {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 8px;
        color: var(--color-text-white);

        h5 {
          color: inherit;
        }

        h6 {
          max-width: 50%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          position: relative;
          color: inherit;

          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.ReservationStepperPreviewDetailGuestContact {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .phone-input {
    .react-international-phone-country-selector {
      button {
        display: none;
      }
    }

    .react-international-phone-input {
      background: transparent;
      border: none;
      padding: 0;
      height: 100%;
    }
  }

  > div {
    display: flex;
    align-items: center;

    span {
      color: var(--color-text-white);
    }
  }

  svg {
    margin-right: 8px;

    &:dir(rtl) {
      margin-left: 8px;
      margin-right: 0;
    }

    path {
      fill: var(--color-text-white);
    }
  }
}
