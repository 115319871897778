.Background {
  height: 100dvh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  --s: 200px;
  --c1: #1d1d1d;
  --c2: #4e4f51;
  --c3: #3c3c3c;

  background: repeating-conic-gradient(
        from 30deg,
        #0000 0 120deg,
        var(--c3) 0 180deg
      )
      calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
    repeating-conic-gradient(
      from 30deg,
      var(--c1) 0 60deg,
      var(--c2) 0 120deg,
      var(--c3) 0 180deg
    );
  background-size: var(--s) calc(var(--s) * 0.577);
}

.Root {
  width: 450px;
  height: 85%;
  max-height: 750px;
  padding: 26px;
  background: #fdfdfd;
  box-shadow: inset 0 0 11px 0 #000;
  border-radius: 66px;
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;

  &:before {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    top: 5px;
    content: "";
    left: 5px;
    border-radius: 61px;
    background: #000;
    z-index: 1;
  }
}

.Iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.TopBar {
  top: 80px;
  width: 100%;
  position: absolute;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
}
.BottomBar {
  width: 100%;
  position: absolute;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  bottom: 80px;
}
.InnerShadow {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  overflow: hidden;
  left: 10px;
  border-radius: 56px;
  box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
  z-index: 1;
}

.Volume {
  left: -3px;
  top: 116px;
  height: 32px;
  width: 3px;
  background: #b5b5b5;
  position: absolute;
  &:before {
    content: "";
    left: 0;
    top: 62px;
    height: 62px;
    width: 3px;
    background: #b5b5b5;
    position: absolute;
  }
  &:after {
    content: "";
    left: 0;
    top: 140px;
    height: 62px;
    width: 3px;
    background: #b5b5b5;
    position: absolute;
  }
}

.Sleep {
  width: 3px;
  background: #b5b5b5;
  position: absolute;
  height: 96px;
  top: 200px;
  right: -3px;
}

.Screen {
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 3;
  background: #fff;
  overflow: hidden;
  display: block;
  border-radius: 40px;
  box-shadow: none;
}
