.Root {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  height: 44px;
}
.isClickable {
  cursor: pointer;
}
.Left {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.Center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Right {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
