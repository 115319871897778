@import "src/assets/styles/variables";

.AddMenuItemModalHeader {
  display: flex;
  align-items: center;
  .DeleteButton {
    &.isDisabled {
      background-color: var(--color-mini-button);
      > svg {
        path {
          fill: var(--color-inverse-25);
        }
      }
    }
  }
  > button,
  > div {
    margin-left: 8px;
    flex: 0 0 30px;
  }

  h3 {
    margin-right: 10px;
  }
}

.AddMenuItemModalBodyFormDescriptionInput {
  margin-top: 14px;
}

.AddMenuItemModalHeaderTitle {
  flex-grow: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.AddMenuItemModalHeaderSubTitle {
  color: var(--color-inverse-25);
}

.AddMenuItemModalBody.general {
  padding-bottom: 40px;

  .AddMenuItemModalBodyForm {
    > * {
      margin-bottom: 24px;
    }

    * .InputControlLabel {
      &:before {
        //background-color: var(--color-background-light-mode);
      }
    }

    .DropdownLabel {
      background-color: var(--color-background-light-mode);
    }
  }

  .AddMenuItemModalBodyFormNameInput {
    .InputControlLabel {
      &:before {
        //background-color: var(--color-mini-button);
      }
    }
  }
}

.AddMenuItemModalBodyMethodTab {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-mini-button);
}

.AddMenuItemModalBodyForm {
  > * {
    margin-bottom: 24px;
  }

  * {
    .InputControlLabel {
      &:before {
        //background-color: var(--color-mini-button);
      }
    }

    .DropdownLabel {
      background-color: var(--color-mini-button);
    }
  }
}

.AddMenuItemTags {
  margin-bottom: 40px;
}

.AddMenuItemModalBodyPhotoAdd {
  display: flex;
  margin: 0 0 24px;

  .SpinnerContainer {
    height: 100%;

    .Spinner {
      width: 56px;
      height: 56px;
    }
  }
}

.AddMenuItemModalBodyPhotoAddNewComponent {
  margin-top: 16px;
  margin-right: 10px;
  width: 80px;
  height: 80px;
}

.AddMenuItemModalBodyPhotoAddExisting {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.AddMenuItemModalBodyPhotoAddExistingPhotos {
  display: flex;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 16px;
  padding-left: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    height: 80px;
    width: 80px;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.AddMenuItemModalBodyModificationsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h5 {
    flex-grow: 1;
  }

  > button {
    &:last-child {
      margin-left: 10px;
    }
  }
}

.AddMenuItemModalBodyModificationsList {
  > div {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}

.AddMenuItemModalBodyPhotosAdditionalInfo {
  color: var(--color-inverse-50);
}

.AddMenuItemModalBodyNoModifications {
  color: var(--color-inverse-50);
  margin-top: 16px;
}

@media (max-width: $mobile) {
  .AddMenuItemModalBodyPhotoAdd {
    flex-direction: column;
    margin: 10px 0 40px;
  }

  .AddMenuItemModalBodyPhotoAddExistingPhotos {
    margin-left: 0;

    > div:first-child {
      margin-left: 0;
    }
  }

  .AddMenuItemModalBodyPhotoAddNew {
    h6 {
      display: none;
    }
  }

  .AddMenuItemModalBodyPhotoAddNewComponent {
    width: 100%;
    height: 100px;
  }

  .AddMenuItemModalBodyPhotoAddExisting {
    h6 {
      margin-left: 0;
    }
  }

  .AddMenuItemModalBodyModificationsList {
    margin-top: 16px;
  }
}

.AddMenuItemModalBody {
  width: 500px;
  padding-bottom: 90px;
}

.AddMenuItemModalBodyFormBox {
  border-radius: 12px;
  background: var(--color-mini-button);
  padding: 14px 8px;
  display: flex;
  flex-direction: column;

  .AddMenuItemModalBodyFormBoxRow {
    &:not(:first-child) {
      margin-top: 14px;
    }
  }
}

.AddMenuItemModalBodyFormBoxDropdown {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  //> div {
  //  &:not(:first-child) {
  //    margin-top: 14px;
  //  }
  //}
}

.AddMenuItemModalBodyFormBoxRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    width: calc((100% - 10px) / 2);
  }
}

.AddMenuItemModalBodyFormInputHalfWidth {
  width: 100%;
}

.RateExtraComponentBodyIsFixed {
  display: flex;
  justify-content: space-between;

  > * {
    width: calc(50% - 5px);
  }
}

.RateExtraComponentItemInput {
  margin-top: 20px;
}

.AddMenuItemModalBodyFormPrice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  > div {
    width: calc((100% - 10px) / 2);
  }
}

.RateExtraComponentTitle {
  display: flex;
  align-items: center;

  h6 {
    margin-left: 15px;
  }
}

.RateExtraComponentBody {
  border-bottom: 1px solid var(--color-inverse-25);
  padding-top: 20px;
  padding-bottom: 32px;
  margin-bottom: 32px;
}
