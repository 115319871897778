.MemoryCardWinModalHeader {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  .MemoryCardWinModalHeaderMessage {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    top: 17px;
    font-size: 28px;
    h1 {
      color: var(--color-text-white);
    }
  }
}
.MemoryCardWinModalBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px;
  border-radius: 8px;
  margin-top: 30px;

  background-color: transparent;
  .MemoryCardGameSeconds {
    h2 {
      font-size: 32px;
    }
    h2,
    h4 {
      color: var(--color-text-white);
    }
    h4 {
      margin-top: 6px;

    }
    .loserColor {
      color: var(--color-dark-mode-error);
    }
  }
  .MemoryCardGameMatchs {
    p {
      font-size: 42px;
      color: var(--color-text-yellow);
    }
  }
  .MemoryCardGameTries {
    h2 {
      font-size: 32px;
    }
    h2,
    h4 {
      color: var(--color-text-white);
      text-align: end;
    }
    h4 {
      margin-top: 6px;
    }
    .loserColor {
      color: var(--color-dark-mode-error);
    }
  }
}

.MemoryCardWinModalFooter {
  padding: 24px 16px 16px;
  margin-top: 100px;
}
