@import "src/assets/styles/variables";

.SignUpMethod {
  .ModalBody {
    padding: 0;
  }

  .SignUpFormTermsAndConditions {
    margin-top: 56px;
    margin-bottom: 16px;

    .CheckboxError {
      .Checkbox {
        .CheckboxIcon {
          border: 1px solid var(--color-error);
        }
      }
    }

    .SignUpFormError {
      color: var(--color-error);
      margin-top: 3px;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .SignUpFormTermsAndConditionsText {
        font-size: 12px;
        font-weight: 500;

        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .h7 {
        color: var(--color-light-mode-black);
      }

      .CheckboxIcon {
        border: 1px solid var(--color-light-mode-black);
      }

      &.hasError {
        .CheckboxIcon {
          border: 1px solid var(--color-error);
        }

        h6 {
          color: var(--color-error);

          .CheckboxModalLabel {
            color: var(--color-error);
          }
        }
      }

      .isChecked {
        .CheckboxIcon {
          border: none;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .PolicyModalBody {
      width: auto;
    }

    .PrimaryButton {
      position: fixed;
      width: calc(100% - 32px);
      bottom: 32px;
      left: 0;
      margin: 0 16px;
      z-index: 1;
    }
  }
}

@media (max-width: $mobile) {
  .SignUpMethod {
    .Modal.hasOnlyHeader .ModalBody {
      padding: 0;
    }
  }
}