.AdminDashMenuStats {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .AdminDashMenuStatsMenuByOrder {
    width: 100%;
    margin-top: inherit;
  }
  .AdminDashMenuStatsEmptyStateDescription {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .AdminDashMenuByOrderDropDown {
    width: 440px;
  }
}
