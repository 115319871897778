.WheelOfFortune {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  background: var(
    --Light-Mode-Text-Black,
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    var(--color-text-black)
  );
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
}

.WheelOfFortuneHeader {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.WheelOfFortuneHeaderText {
  color: var(--color-text-white);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.WheelOfFortuneHeaderOperations {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    path {
      stroke: var(--color-text-white);
    }
  }

  .CloseButton {
    background: var(--color-mini-button-reverse);
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }

    svg path {
      stroke: var(--color-text-white);
    }

    &:hover {
      border: none;
    }
  }
}

.WheelOfFortuneHeaderOperationsSetting {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--color-mini-button-reverse);
  cursor: pointer;
}
.WheelOfFortuneHeaderOperationsSettingSpinnerActive {
  cursor: not-allowed;
  background-color: var(--color-very-dark-gray);
}

.WheelOfFortuneSettingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WheelOfFortuneSettingsHeaderTitle {
  color: var(--color-text-white);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.WheelOfFortuneSettingsHeader {
  .CloseButton {
    background: var(--color-inverse-75);
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }

    svg path {
      stroke: var(--color-text-white);
    }

    &:hover {
      border: none;
    }
  }
}

.WheelOfFortuneSettingsBodyGuestsList {
  padding-bottom: 56px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.WheelOfFortuneSettingsBodyGuestsListItem,
.WheelOfFortuneSettingsBodyAddNewType {
  cursor: pointer;
  display: flex;
  height: 46px;
  padding: 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  box-shadow: 0 4px 15px 0 var(--color-light-black);
  border-radius: 12px;
  background: var(--color-dark-gray);

  &.isChecked {
    background: var(--color-inverse-75);
    box-shadow: 0 4px 15px 0 var(--color-light-black);
  }
}

.WheelOfFortuneSettingsBodyGuestsListTitle {
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.WheelOfFortuneSettingsBodyGuestsListTitleLeft {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.WheelOfFortuneSettingsBodyGuestsListTitleLeftImage {
  width: 26px;
  height: 26px;
  border-radius: 183px;
}

.WheelOfFortuneSettingsBodyGuestsListTitleLeftText {
  color: var(--color-text-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
  margin-right: 5px;
}

.WheelOfFortuneSettingsBodyGuestsListTitleController {
  display: flex;
  align-items: center;
  gap: 12px;
}

.WheelOfFortuneSettingsBodyGuestsListTitleControllerDelete {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  background: var(--color-background-light-mode);
}

.WheelOfFortuneSettingsBodyGuestsListTitleControllerDeleteImage {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.WheelOfFortuneSettingsBodyAddNewType {
  padding-left: 16px;
  padding-right: 16px;
}

.WheelOfFortuneSettingsBodyAddNewTypeForm {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WheelOfFortuneSettingsBodyAddNewTypeFormInput {
  height: 100%;
  width: 80%;
  background-color: transparent;
  color: var(--color-text-white);
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActions {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActionCancel,
.WheelOfFortuneSettingsBodyAddNewTypeFormActionAdd {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActionCancel {
  background: var(--color-error);

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: var(--color-text-white);
    }
  }
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActionCancel:hover {
  background-color: var(--color-error);
  border: none;
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActionCancel:active {
  background-color: var(--color-error);
  border: none;

  svg path {
    stroke: var(--color-text-white);
  }
}

.WheelOfFortuneSettingsBodyAddNewTypeFormActionAdd {
  background: var(--color-success);
  width: 30px;
  height: 30px;
}

.WheelOfFortuneSettingsBodyGuestsListNewType {
  cursor: pointer;
  display: flex;
  height: 46px;
  padding: 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--color-dark-gray);
  box-shadow: 0 4px 15px 0 var(--color-light-black);
}

.WheelOfFortuneSettingsBodyGuestsListNewTypeAction {
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.WheelOfFortuneSettingsBodyGuestsListNewTypeActionText {
  color: var(--color-text-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.WheelOfFortuneSettingsFooter {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--color-success);
  cursor: pointer;
}

.WheelOfFortuneSettingsFooterSaveButton {
  color: var(--color-text-white);
  background-color: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.WheelOfFortuneSpinnerContainer {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WheelOfFortuneSelectParticipants {
  .ModalContainer {
    width: 500px;
  }

  .Modal,
  .ModalHeader,
  .ModalFooter {
    background: var(--color-mini-button-reverse);
  }

  .ModalFooter {
    padding-top: 0;
    padding-bottom: 16px;
    height: auto;
  }

  .ModalBody,
  .ModalHeader {
    padding: 16px;
  }

  .ModalBody {
    padding-bottom: 0;
  }
}

.CongratulateModal {
  .ModalContainer {
    width: 500px;
  }

  .Modal,
  .ModalHeader,
  .ModalFooter {
    background: var(--color-mini-button-reverse);
  }

  .ModalHeader {
    padding: 0 16px;
  }

  .ModalFooter {
    padding: 0 16px 16px;
  }
}

.CongratulateHeader {
  padding: 24px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  .CongratulateHeaderText {
    flex: 1 0 0;
    color: var(--color-text-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.CongratulateHeader {
  .CloseButton {
    background: var(--color-inverse-75);
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }

    svg path {
      stroke: var(--color-text-white);
    }

    &:hover {
      border: none;
    }
  }
}

.CongratulateBody {
  padding: 20px 16px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  .CongratulateBodyAvatar {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
  }

  .CongratulateBodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .Title {
      align-self: stretch;
      color: var(--color-text-white);
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .Description {
      align-self: stretch;
      color: var(--color-text-white);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.CongratulateFooter {
  cursor: pointer;
  display: flex;
  padding: 16px 108px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  background: var(--color-inverse-75);

  .CongratulateCloseBtn {
    background-color: transparent;
    color: var(--color-text-white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-scroll {
  overflow: hidden;
}
