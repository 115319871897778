.AdminTableModalBodyForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;

  > div:nth-child(2),
  > div:nth-child(3) {
    width: calc(50% - 5px);
  }

  > div {
    margin-bottom: 16px;
    width: 100%;
  }
}

.AdminTableModalTitleRight {
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }

  .AdminTableModalVRLink {
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--color-mini-button);
    cursor: pointer;

    .VRLinkCopyButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      svg {
        width: 19.13px;
        height: 19.13px;
        flex-shrink: 0;
      }
    }

    .VRLinkTitle {
      color: var(--color-text-black);
    }

    &:hover {
      background: var(--color-group-background-light-mode);
    }

    .VRLinkModalCopiedText {
      position: absolute;
      color: var(--color-success);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
      margin-top: 3px;
    }
  }
}

.AdminTableModalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: var(--color-text-black);
  }
}

.AdminTableModalBody {
  width: 500px;
  padding-bottom: 56px;

  .AdminTableModalBodyQR {
    min-height: 186px;
  }
}
