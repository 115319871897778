@import "src/assets/styles/mixins";
.RenderedOrderListGuestComments {
  margin-bottom: 0;
}
.AdminOrderDetailsOrdersGuestDetailsContainer {
  h6 {
    @include text-truncate-ellipsis(1);
    word-break: break-word;

  }
}
.AdminOrderDetailsOrdersGuestTotal {
  display: flex;
  gap: 5px;
  text-wrap: nowrap;
}
