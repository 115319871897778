@import "src/assets/styles/_variables";

.OfflineMenu {
  height: 100%;
  width: 100%;
  background-color: var(--color-text-black);
  overflow: hidden;
  position: relative;
}

.OfflineMenuBody {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OfflineMenuBodyText {
  color: var(--color-text-white);
  text-align: center;
}

.OfflineMenuBodyLogoImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  margin-bottom: 24px;
}
