@import "src/assets/styles/mixins";

.Category {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  scrollbar-width: none;
}

.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}

.Name {
  color: var(--color-text-black-high);
  @include text-truncate-ellipsis(1);
  word-break: break-word;
  padding-right: 8px;
}

.All {
  color: #084DB5;
  cursor: pointer;
  min-width: max-content;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: var(--Component-color-Info-color-50, #E7F0FF);
  line-height: 20px;
}