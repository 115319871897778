.AdminDashboard {
  padding: 30px;
  background-color: var(--color-mini-button);
  min-height: 100vh;

  .checkFromToDate {
    background: var(--color-admin-primary) !important;
  }
  .checkFromToDate:hover {
    background: var(--color-admin-primary-hover) !important;
  }
  .AdminDashboardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .AdminDashboardTitleText {
      font-weight: 800;
      opacity: 0.7;
      font-size: 28px;
      line-height: 32px;
      text-transform: uppercase;
    }

    .CalendarRangePickerAndButton {
      display: flex;
      align-items: center;
      gap: 8px;

      .AdminDashboardCalendarRangePicker {
        input {
          height: 46px;
          padding: 14px 16px;
          border-radius: 8px;
          border: 1px solid var(--color-gray);
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          background: none;
        }
      }
      .CalendarRangePickerButton {
        height: 46px;
        padding: 12px 16px;
        font-weight: 600;
        border-radius: 8px;
        background: var(--color-light-gray);
        line-height: 20px;
      }
    }
  }
  .AdminDashMenuStatForm,
  .AdminDashHistoryForm,
  .AdminDashAllMenus {


    .Form {
      display: flex;
      gap: 16px;
      .CustomSearch {
        max-width: 180px;
        border: 1px solid var(--color-gray);
        background: var(--color-mini-button);
        .SearchInput {
          color: var(--color-text-black);
          font-size: 14px;
          padding: 11px 16px 11px 48px;
        }
        svg {
          width: 24px;
          height: 24px;
          path {
            stroke: var(--color-inverse-50);
          }
        }
      }

      .CustomDropDown {
        max-width: 180px;
        max-height: 46px;

        .DropdownLabel {
          background: var(--color-mini-button);
        }

        .DropdownSelectedItem {
          height: initial;
          padding: 11px 16px;
        }
      }
    }
  }
}
