.AdminOrderDetail {
  .EmptyStateContainer {
    height: calc(100% - 176px);
  }
  .ModalFooter{
    .CTAButton{
      padding: 20px 16px;
      background-color: var(--color-admin-primary);
    }
  }
}

.AdminOrderPendingItemsContainer {
  margin-top: 24px;
}

.AdminOrderDetailsOrders {
  margin-top: 24px;
}

.AdminOrderNotificationsContainer {
  margin-top: 24px;
}
