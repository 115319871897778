@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.LandingPage {
  .LandingPageHeader {
    background-color: var(--color-text-black);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .LandingPageLanguageDropDownAndSignIn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 250px;

      .LandingPageSignInButton {
        background-color: var(--color-background-light-mode);
        padding: 12px 24px;
        width: auto;
        min-width: 110px;
        border-radius: 40px;
      }
    }
    .DropdownSelectedItem {
      border: none;
      .DropdownArrow {
        filter: invert(1) brightness(1000%);
      }
      .DropdownSelectedItemTitle {
        .DropdownSelectedItemTitleImage {
          border-radius: 4px;
          width: 32px;
        }
        h6 {
          color: var(--color-text-white);
        }
      }
    }
    .Search {
      width: 100%;
      max-width: none;
      margin-top: 56px;
    }
  }
  .LandingPageHeaderTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    h4 {
      color: var(--color-text-white);
    }
  }
  .LandingPageImage {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 900px;
      object-fit: contain;
    }
  }
  .FooterContainer {
    margin-top: 40px;
    min-height: 285px;
    width: 100%;
    background: var(--Light-Mode-Text-Black, #1f1f1f);
    padding-top: 55px;
    position: relative;
    h1,
    h6,
    h4,
    h5,
    a {
      color: var(--color-text-white);
    }

    a {
      font-size: 14px;
    }

    > div {
      display: flex;
    }

    .FooterLogoContainer {
      .FooterLogoFront {
        position: absolute;
        top: -8%;
        left: 16px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 7px solid var(--color-text-white);
        background-color: var(--color-text-black);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .FooterLogoImage {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
      }

      h4 {
        position: absolute;
        top: 3%;
        left: 112px;
        @include text-truncate-ellipsis(1);
        word-break: break-word;
        margin-right: 16px;
      }
    }

    .FooterLinksContainer {
      display: flex;
      padding: 24px 16px;
      gap: 16px;

      .FooterLinksPages {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;

        h6 {
          text-wrap: nowrap;
        }
      }

      .FooterLinksContact {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;

        h6 {
          white-space: initial;
          display: -webkit-box;
          word-break: break-word;

          input {
            width: 100%;
          }
        }

        h6:active {
          color: var(--Light-Mode-Primary, #fd0);
        }
      }
    }

    .FooterSocialsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin: 16px 0 24px 0;
    }

    .FooterPrivacyAndTerms {
      border-top: 1px solid var(--color-text-white);
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .CompanyName {
        display: flex;
        gap: 4px;
        align-items: center;

        .CompanyNameLink {
          color: var(--Light-Mode-Primary, #fd0);
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .FooterCopyRightContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      a:active {
        color: var(--Light-Mode-Primary, #fd0);
      }

      .FooterCopyRightPrivacyAndTerms {
        cursor: pointer;

        .FooterCopyRightPrivacyAndTermsText {
          color: var(--color-text-white);
          text-align: center;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
      }
    }

    .FooterCopyRight {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .ModalBody {
      padding: 0;
    }
  }
}





@media (max-width: $mobile) {
  .LandingPage {
    .LandingPageHeader {
      .LandingPageHeaderTitle {
        svg {
          width: 32px;
          height: 32px;
        }
      }
      .LandingPageLanguageDropDownAndSignIn {
        width: 218px;
      }

      .LandingPageSignInButton {
        padding: 12px 16px;
      }
      .DropdownSelectedItemTitle {
        h6 {
          display: none;
        }
      }
    }
  }
}
@media (max-width: $mobile) {
  .FooterContainer {
    .Modal.hasOnlyHeader .ModalBody {
      padding: 0;
    }
  }
}
