.SignInMainForm {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-bottom: 90px;

  .SignInFormForgotPassword {
    margin: 8px 0 56px auto;
    text-decoration: underline;
    width: fit-content;
    cursor: pointer;

    &:dir(rtl) {
      margin: 8px auto 56px 0;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 40px;
  }

  .ChooseSignInMethod {
    display: flex;
    border-radius: 28px;
    background: var(--color-mini-button);
    padding: 8px;
    height: 52px;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    margin-bottom: 16px;

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      height: calc(100% - 10px);
      background-color: #dcdcdc;
      border-radius: 48px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      transition: all 0.2s ease-in-out;
    }

    &.isPhoneNumber {
      &:after {
        left: 8px;
      }
    }

    &.isEmail {
      &:after {
        left: calc(50% - 8px);
      }
    }

    .SignInMethod {
      width: 50%;
      text-align: center;
      border-radius: 48px;
      cursor: pointer;
      text-wrap: nowrap;
      z-index: 2;
      padding: 5px 0;
    }
  }

  .SignInCreateAnAccountForm {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .SignUpPhoneOrEmailSwitch {
      .FilterSwitchWrapper {
        > label {
          font-size: 14px;
        }
      }
    }

    .react-international-phone-country-selector-button {
      border-radius: 30px 0 0 30px;
      background-color: var(--color-mini-button);
      border-left: 1px solid var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);

      &:dir(rtl) {
        border-radius: 0 30px 30px 0;
        border-left: none;
        border-right: 1px solid var(--color-mini-button);
      }
    }

    .react-international-phone-input {
      border-radius: 0 30px 30px 0;
      background-color: var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);

      [dir="rtl"] & {
        border-radius: 30px 0 0 30px;
        border-left: 1px solid var(--color-mini-button);
      }

      [dir="ltr"] & {
        border-right: 1px solid var(--color-mini-button);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 1000px var(--color-mini-button) inset;
      }
    }

    .InputPasswordContainer {
      margin-top: 40px;
    }

    .SignInPasswordForm {
      margin-top: 40px;
    }
  }

  .SignInSubTitle {
    text-align: center;
    margin: 25px 0;
    color: var(--color-inverse-50);
  }

  .SignUpLink {
    margin-left: 12px;
    color: var(--color-text-black);

    &:dir(rtl) {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}
