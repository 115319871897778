.QrZoneItem {
  padding: 8px;
  border: 1px solid var(--color-stroke);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  > div {
    &:first-child {
      margin-right: 16px;
    }
  }
}

.QrZoneItemTitle {
  color: var(--color-text-black);
  white-space: nowrap;
}

.QrZoneItemStatus {
  padding: 4px 16px;
  border-radius: 10px;
  margin-top: 4px;
  white-space: nowrap;
  width: fit-content;

  &.danger {
    background-color: var(--color-red-15);
    color: var(--color-error);
  }

  &.success {
    background-color: var(--color-green-15);
    color: var(--color-success);
  }
}
