.ForgetPasswordMainForm {
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h6 {
      margin-bottom: 80px;

    }
  }

  .ChooseForgetPasswordMethod {
    display: flex;
    border-radius: 28px;
    background: var(--color-mini-button);
    padding: 8px;
    height: 52px;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    margin-bottom: 16px;
    margin-top: 52px;

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      height: calc(100% - 10px);
      background-color: #dcdcdc;
      border-radius: 48px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      transition: all .2s ease-in-out;
    }

    &.isPhoneNumber {
      &:after {
        left: 8px;
      }
    }

    &.isEmail {
      &:after {
        left: calc(50% - 8px);
      }
    }

    .ForgetPasswordMethod {
      width: 50%;
      text-align: center;
      border-radius: 48px;
      cursor: pointer;
      text-wrap: nowrap;
      z-index: 2;
      padding: 5px 0;
    }
  }

  .ForgetPasswordCreateAnAccountForm {
    display: flex;
    flex-direction: column;

    .SignUpPhoneOrEmailSwitch {
      .FilterSwitchWrapper {
        > label {
          font-size: 14px;
        }
      }
    }

    .react-international-phone-country-selector-button {
      border-radius: 30px 0 0 30px;
      background-color: var(--color-mini-button);
      border-left: 1px solid var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);

      &:dir(rtl) {
        border-radius: 0 30px 30px 0;
        border-left: transparent;
        border-right: 1px solid var(--color-mini-button);

      }
    }

    .react-international-phone-input {
      border-radius: 0 30px 30px 0;
      background-color: var(--color-mini-button);
      border-top: 1px solid var(--color-mini-button);
      border-bottom: 1px solid var(--color-mini-button);

      [dir="rtl"] & {
        border-radius: 30px 0 0 30px;
        border-left: 1px solid var(--color-mini-button);
      }

      [dir="ltr"] & {
        border-right: 1px solid var(--color-mini-button);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 1000px var(--color-mini-button) inset;
      }
    }

    .ForgetPasswordMethodInputGroups {
      display: flex;
      margin-top: 40px;
      flex-direction: column;
      gap: 16px;
    }
  }

  .ForgetPasswordSubTitle {
    text-align: center;
    margin: 25px 0;
    color: var(--color-inverse-50);
  }

  .EmailOrPhoneForgetPasswordMethodError {
    .error-message {
      color: var(--color-error);
      font-size: 12px;
      margin-top: 3px;
      margin-left: 16px;

      &:dir(rtl) {
        margin-left: 0;
        margin-right: 16px;
      }
    }
  }
}
