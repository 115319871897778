.AdminZoneSection {
  margin-top: 30px;
  padding-left: 32px;
  padding-right: 32px;
}
.AdminZoneCaption {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .AdminZoneCaptionTitleText {
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.AdminZoneCount {
  color: var(--color-text-black);
}

.AdminZoneContainer {
  margin-top: 16px;
  display: flex;
  margin-left: 9px;
}
.AdminZoneItems {
  display: flex;
  width: 100%;
  overflow: auto;
  .AdminZoneItemBox {
    padding: 8px;
    &.isActive {
      position: relative;
      background-color: var(--color-mini-button);
      border-radius: 10px 10px 0 0;
      .QrZoneItem {
        border-radius: 10px;
        border: 1px solid var(--color-stroke);
        background: var(--color-text-white);
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        left: -20px;
        border-bottom-right-radius: 8px;
        box-shadow: 8px 8px 0 0 var(--color-mini-button);
      }
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        right: -20px;
        border-bottom-left-radius: 8px;
        box-shadow: -8px 8px 0 0 var(--color-mini-button);
      }
    }
  }
  .AdminZoneItemBox:first-child {
    margin-left: 8px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.AdminZoneAdd {
  width: 56px;
  height: 56px;
  margin: 8px 0;
}
