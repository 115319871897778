@import "src/assets/styles/mixins";

.BasketMessageSection {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-text-white);
  margin-bottom: 40px;
  border-radius: 12px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    &:dir(rtl) {
      transform: rotate(180deg);
    }
  }
}

.BasketMessageSectionContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.BasketMessageSectionTitle {
  display: flex;
  flex-direction: column;

  h6 {
    color: var(--color-text-black-high);
  }

  p {
    color: var(--color-text-black-low);
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    @include text-truncate-ellipsis(1);
    word-break: break-all;
  }
}
