.TowerBlock {
  height: 100%;

  .TowerGameHeader {
    z-index: 1;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 17px 0;

    .SemiBold {
      color: var(--color-text-white);
      text-align: center;
      font-style: normal;
    }

    .BackButton {
      cursor: pointer;
      background-color: var(--color-text-black);

      svg {
        path {
          fill: var(--color-text-white);
        }
      }

      &:active {
        background: var(--color-text-black);
      }
    }

    .RefreshButton {
      cursor: pointer;
      display: flex;
      width: 44px;
      height: 44px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: var(--color-text-black)
    }
  }

  .interface {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .restart {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      color: #ffffff;
      padding: 10px 0;
      pointer-events: auto;
      cursor: pointer;
      font-size: 30px;
      text-align: center;
    }

    .count {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 10%;
      left: 0;
      width: 100%;
      color: #ffffff;
      font-size: 80px;
      padding-top: 10px;
      pointer-events: none;
    }
  }

}
