@import "src/assets/styles/_variables";

.AddButton {
  border-radius: 10px;
  background: var(--color-mini-button);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  svg path {
    transition: $defaultTransition;
  }

  &.typeA {
    .AddButtonIcon {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: var(--color-group-background-light-mode);
    }
  }

  &.typeB {
    .AddButtonIcon {
      width: 32px;
      height: 32px;
    }
  }

  &.typeC,
  &.typeD {
    width: 25px;
    height: 25px;
    border-radius: 50%;

    .AddButtonIcon {
      path {
        stroke: var(--color-inverse-75);
      }
    }
  }

  &.typeC {
    background-color: var(--color-mini-button);

    &:hover {
      background: var(--color-background-light-mode);
      border: 2px dashed var(--color-inverse-25);
  
      svg path {
        stroke: var(--color-inverse-25);
      }
    }
  }

  &.typeD {
    background-color: var(--color-background-light-mode);

    .AddButtonIcon {
      path {
        stroke: var(--color-inverse-75);
      }
    }
  }

  &:active {
    background: var(--color-stroke);
    border: 1px solid transparent;

    svg path {
      stroke: var(--color-text-black);
    }
  }
}
