.Root {
  width: 100%;
}

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoadingMessage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-text-black);
  font-size: 18px;
  text-wrap: nowrap;
  font-weight: 600;
}

.ARButton {
  border-radius: 16px;
  background: var(--color-pos-utility-colors-black);
  padding: 12px 24px;
  color: var(--color-pos-brand-colors-primary-normal);
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  border: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  .Text{
    color: #fff;
  }
}

.Logo{
  width: 32px;
  height: 22px;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
}
