@import "src/assets/styles/variables";

.AddPhoto {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 43px 0;
  background-color: var(--color-primary-5);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--color-admin-primary);
  &:hover {
    background-color: var(--color-admin-primary);
    .AddPhotoInner {
      h6 {
        color: var(--color-text-white);
      }
    }
    .AddPhotoIcon {
      filter: brightness(0) invert(1);
    }
  }

  h5 {
    color: var(--color-admin-primary);
    text-decoration: underline;
  }

  &.hasImage {
    background-color: var(--color-background-light-mode);
    border: 1px solid var(--color-gray);
    padding: 24px 0;

    .AddPhotoImageFormat {
      margin-bottom: 24px;
    }
  }

  &.showBorder {
    background-color: var(--color-background-light-mode);
    border: none;
  }
}

.AddPhotoClickable {
  cursor: pointer;
}

.AddPhotoInner {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AddPhotoFileInput {
  display: none;
}

.AddPhotoTitle {
  color: var(--color-admin-primary);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
}

.AddPhotoImageFormat {
  color: var(--color-inverse-50);
  margin-top: 4px;
}

.AddPhotoUploadedImage {
  display: flex;
  align-items: center;
  justify-content: center;

  &.typeA {
    width: 100%;
  }
}

.AddPhotoUploadedImageContainer {
  width: 100px;
  aspect-ratio: 1;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  &.typeA {
    width: 100%;
    height: 100%;
  }

  .SpinnerContainer {
    height: 50%;
  }
}

.AddPhotoUploadedImageRemove {
  position: absolute;
  top: 8px;
  right: 8px;
}

.AddPhotoUploadedImagesList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    height: 92px;
    width: 92px;
  }
}

.AddPhotoUploadedImages {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 16px;

  h6 {
    text-align: center;
  }
}

.AdminBusinessInfoAboutImagesBackgroundAdd,
.AdminBusinessInfoAboutImagesLogoAdd {
  height: 168px;
}

@media (max-width: $mobile) {
  .AddPhoto {
    padding: 24px 0;
  }

  .AddPhotoUploadedImagesList {
    gap: 16px;

    > div {
      height: 90px;
      width: 90px;
      margin-right: 0;
    }
  }
}
