.GuestAuth {
  padding: 16px;
  height: 100%;

  .GuestAuthHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .GuestAuthContent {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;
    height: 100%;
    .GuestAuthFooterButtons {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}