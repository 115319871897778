@import "src/assets/styles/variables";

.UnderConstructionBand {
  background-color: var(--color-under-construction);
  padding: 8px 0;
  width: 143vw;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 4px 12px 12px rgba(0, 0, 0, 0.5);
  &:nth-child(1) {
    rotate: -20deg;
    left: -50px;
    z-index: 1;
    top: 35%;
    transform-origin: left;
  }
  &:nth-child(2) {
    rotate: 15deg;
    top: 30%;
    right: -50px;
    z-index: 2;
    transform-origin: right;
  }
  &:nth-child(3) {
    rotate: 45deg;
    top: 45%;
    right: -50px;
    z-index: 1;
    transform-origin: right;
  }
  &:nth-child(4) {
    rotate: 35deg;
    left: -50px;
    bottom: 46%;
    z-index: 1;
    transform-origin: left;
  }
  &:nth-child(5) {
    rotate: 10deg;
    left: -50px;
    bottom: 31%;
    z-index: 4;
    transform-origin: left;
  }
  &:nth-child(6) {
    rotate: 10deg;
    left: -50px;
    bottom: 21%;
    z-index: 2;
    transform-origin: left;
  }
  &:nth-child(7) {
    rotate: -7deg;
    right: -50px;
    bottom: 19%;
    z-index: 3;
    transform-origin: right;
  }
  &:nth-child(8) {
    rotate: -30deg;
    right: -50px;
    bottom: 30%;
    z-index: 1;
    transform-origin: right;
  }
}

.UnderConstructionBandTicker {
  display: flex;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
  animation: scrollText 200s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: $tablet) {
  .UnderConstructionBand {
    &:nth-child(1) {
      rotate: 15deg;
      left: -50px;
      z-index: 1;
      top: 0;
      transform-origin: left;
    }
    &:nth-child(2) {
      rotate: -23deg;
      top: 0;
      right: -50px;
      z-index: 2;
      transform-origin: right;
    }
    &:nth-child(3) {
      rotate: -23deg;
      bottom: 0;
      left: -50px;
      z-index: 1;
      transform-origin: left;
      top: initial;
      right: initial;
    }
    &:nth-child(4) {
      rotate: 15deg;
      left: -50px;
      bottom: 20%;
      z-index: 2;
      transform-origin: left;
    }
    &:nth-child(5) {
      rotate: 15deg;
      left: -50px;
      bottom: 30%;
      z-index: 2;
      transform-origin: left;
    }
    &:nth-child(6) {
      display: none;
    }
    &:nth-child(7) {
      display: none;
    }
    &:nth-child(8) {
      display: none;
    }
  }
}
@media (max-width: $mobile) {
  .UnderConstructionBand {
    &:nth-child(3) {
      bottom: 6%;
    }
  }
}
