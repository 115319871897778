.SpeechToTextContainerContentEmptyState {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  justify-content: center;

  .EmptyStateIcon {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .EmptyStateTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    .EmptyStateTitle {
      align-self: stretch;
      text-align: center;
      font-size: 28px;
      line-height: 36px;
      background: linear-gradient(
        90deg,
        #2f7ce5 0%,
        #33dff6 50%,
        #a154f2 96.9%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .EmptyStateDescription {
      align-self: stretch;
      color: rgba(0, 0, 0, 0.24);
      text-align: center;
    }
  }
}

.SpeechToTextContainerContent {
  padding-top: 64px;
  height: calc(100%);
  padding-bottom: 16px;
  display: flex;
  gap: 28px;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .SpeechToTextContainerMessage {
    padding: 16px;
    border-radius: 24px 2px 24px 24px;
    background: var(--color-text-white);
    width: fit-content;
    max-width: 300px;
    margin-right: 16px;
    border: 1px solid #eeedf2;
    &:dir(rtl) {
      margin-right: 0;
      margin-left: 16px;
      border-radius: 2px 24px 24px 24px;
    }
    &:first-child {
      margin-top: 17px;
    }

    h5 {
      color: var(--color-text-black-high);
      word-break: break-word;
    }
  }

  .SpeechToTextContainerResponseMessage {
    padding: 8px 0 0 12px;
    border-radius: 16px;
    background: var(--color-gray-20);
    width: max-content;
    max-width: 100%;
    align-self: flex-start;
    &:dir(rtl) {
      padding: 8px 12px 0 0;
    }
    .SpeechToTextContainerLoading {
      padding: 12px;
      border-radius: 2px 16px 16px 16px;
      border: 1px solid #eeedf2;
      background: var(--color-text-white);
      &:dir(rtl) {
        border-radius: 16px 2px 16px 16px;
      }
    }

    .NoResultContainer {
      display: flex;
      height: 74px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin: 0 12px 20px 16px;
      &:dir(rtl) {
        margin: 0 16px 20px 12px;
      }
      img {
        width: 28px;
        height: 28px;
      }

      h6 {
        color: var(--color-text-black-high);
      }
    }

    h5 {
      color: var(--color-text-black-high);
    }
  }
}

.Recommends {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  overflow-x: scroll;
  padding: 0 16px;
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  min-height: 100px;

  .Recommend {
    cursor: pointer;
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    background: var(--color-text-white);
    border: 1px solid var(--color-gray-30);
    border-radius: 12px;
    min-width: 180px;
    width: 100%;
    max-width: 197px;
    height: 90px;
    max-height: 90px;

    .FlashIconWrapper {
      display: flex;
      padding: 4px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--color-gradient-brand-secondary);
    }

    h6 {
      color: var(--color-background-modal);
      align-self: stretch;
    }
  }
}
