.AddInventoryItemModalHeader {
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
}

.AddInventoryItemModalHeaderTitle {
  margin-right: auto;
}

.AddInventoryItemModalBodyForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
  margin-bottom: 56px;

  > div {
    margin-bottom: 16px;
    width: 100%;
  }

  :nth-child(4) {
    width:  94%;
  }
}

.AddInventoryItemModalBody {
  width: 500px;
}

.AddInventoryModalWarningAmountInfo {
  width: 48%;
  color: var(--color-inverse-50);
}

.AddInventoryModalWarningAmountInfoStar {
  color: var(--color-error);
}

.amountunitcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.AddInventoryModalBodyFormInputHalfWidth {
  width: 50%;
}