@import "src/assets/styles/_variables";

.AdminDashWeeklyStats {
  .StatsCardChartWrapper {
    height: 460px;
  }
  .WrapperOfWeekDayStats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    > div {
      flex-grow: 1;
      min-width: 300px;
    }
  }
  .WrapperOfWeekDayStats .StatsCardChartWrapper:last-child {
    .StatsCardChartWrapperTitleAndSubtitle {
      margin-bottom: auto;
    }
  }


  .SpinnerContainer {
    height: 100%;
  }

  @media (max-width: $tablet) {
    .WrapperOfWeekDayStats {
      flex-direction: column;
      align-items: center;
      > div {
        width: 100%;
        min-width: initial;
      }
    }

  }
}
