.Root {
  width: 384px;
  padding: 24px;
  border-radius: var(--l, 20px);
  position: relative;
  background: var(--color-background-light-mode);

  &.Info {
    .Outer {
      background: rgba(239, 143, 0, 0.12);
    }
    .Inner {
      background: rgba(239, 143, 0, 0.16);
    }
  }
  &.Success {
    .Outer {
      background: rgba(2, 192, 74, 0.12);
    }
    .Inner {
      background: rgba(2, 192, 74, 0.16);
    }
  }
  &.Error {
    .Outer {
      background: rgba(227, 21, 46, 0.12);
    }
    .Inner {
      background: rgba(227, 21, 46, 0.16);
    }
  }
  &.Preview {
    .Outer {
      background: rgba(239, 143, 0, 0.12);
    }
    .Inner {
      background: rgba(239, 143, 0, 0.16);
    }
  }
}
.Container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .CloseBtn {
    position: absolute;
    top: 10px;
    right: 14px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 50%;
  }
  .Outer {
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50%;
  }
  .Inner {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50%;
  }
}

.Main {
  width: 100%;
  margin-top: 24px;

  .Info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    h1 {
      color: var(--color-pos-text-black-high);
      text-align: center;
    }

    h4 {
      color: var(--color-pos-text-black-medium);
      text-align: center;
    }
  }
}
.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  .Button {
    width: 160px;
  }
}
