.AccordionContainerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AccordionContainerIconArrowContainer {
  padding: 4px;
  cursor: pointer;

  svg {
    transition: 0.3s;

    &.isAccordionArrowRotated {
      transform: rotate(180deg);
    }

    path {
      fill: var(--color-text-black);
    }
  }
}

.AccordionContainerBody {
  max-height: 0;
  transition: 0.2s ease-in-out;
  opacity: 0;
  overflow: visible;

  &.isAccordionBodyVisible {
    max-height: 1000px;
    margin-top: 20px;
    opacity: 1;
  }
}
