.ReservationFinalInfo {
  .ReservationEditorInputTextarea {
    margin-top: 32px;
    margin-bottom: 100px;
  }
  .ReservationSubmitButton {
    h4 {
      text-align: center;
      margin: 0 auto;
    }
  }
}
