.Switch {
  background-color: var(--color-inverse-25);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &.isChecked {
    background-color: var(--color-admin-primary);
  }

  &.typeA {
    width: 48px;
    height: 24px;
    border-radius: 12px;

    .SwitchSlider {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      margin-left: 1px;
    }

    &.isChecked {
      .SwitchSlider {
        transform: translateX(25px);
        margin-left: 0;
      }
    }
  }
}

.SwitchContainer {
  display: flex;
  align-items: center;


  &.typeB {
    border: 5px solid rgba(133, 133, 133, 0.2);
    width: 76px;
    height: 43px;
    border-radius: 40px;

    & > div {
      background-color: var(--color-text-black);
      width: 66px;
      height: 33px;
      border-radius: 40px;
      padding: 3px;

      .SwitchSlider {
        width: 27px;
        height: 27px;
        border-radius: 13px;

      }

      &.isChecked {
        background-color: var(--color-primary);
        .SwitchSlider {
          transform: translateX(32px);
        }
      }
    }
  }

  &.isDisabled {
    pointer-events: none;
    cursor: not-allowed;

    .Switch {
      background-color: var(--color-inverse-85);
    }

    .SwitchSlider {
      background-color: var(--color-inverse-75);
    }
  }
}

.SwitchSlider {
  background-color: var(--color-background-light-mode);
  transition: transform 0.3s;
  //transform: translateX(1px);
}

.SwitchLabel {
  margin-left: 12px;
}
