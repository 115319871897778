.Root {
  border-radius: 50%;
  position: relative;
  background-color: var(--color-pos-neutral-2);
  display: flex;
  align-items: center;
  justify-content: center;

  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.Avatar24 {
    min-width: 24px;
    width: 24px;
    height: 24px;

    .StatusType {
      width: 6px;
      height: 6px;
    }

    .Initial {
      font-size: 10px;
      line-height: 16px;
    }

    .Icon {
      width: 12px;
      height: 12px;
    }

  }

  &.Avatar28 {
    min-width: 28px;
    width: 28px;
    height: 28px;

    .StatusType {
      width: 8px;
      height: 8px;
    }

    .Initial {
      font-size: 10px;
      line-height: 16px;
    }

    .Icon {
      width: 12px;
      height: 12px;
    }
  }

  &.Avatar32 {
    min-width: 32px;
    width: 32px;
    height: 32px;

    .StatusType {
      width: 10px;
      height: 10px;
    }

    .Initial {
      font-size: 12px;
      line-height: 16px;
    }

    .Icon {
      width: 16px;
      height: 16px;
    }
  }

  &.Avatar36 {
    min-width: 36px;
    width: 36px;
    height: 36px;

    .StatusType {
      width: 10px;
      height: 10px;
    }

    .Initial {
      font-size: 12px;
      line-height: 16px;
    }

    .Icon {
      width: 16px;
      height: 16px;
    }
  }

  &.Avatar40 {
    min-width: 40px;
    width: 40px;
    height: 40px;

    .StatusType {
      width: 12px;
      height: 12px;
    }

    .Initial {
      font-size: 14px;
      line-height: 20px;
    }

    .Icon {
      width: 16px;
      height: 16px;
    }
  }

  &.Avatar44 {
    min-width: 44px;
    width: 44px;
    height: 44px;

    .StatusType {
      width: 12px;
      height: 12px;
    }

    .Initial {
      font-size: 14px;
      line-height: 20px;
    }

    .Icon {
      width: 20px;
      height: 20px;
    }
  }

  &.Avatar48 {
    min-width: 48px;
    width: 48px;
    height: 48px;

    .StatusType {
      width: 12px;
      height: 12px;
    }

    .Initial {
      font-size: 16px;
      line-height: 24px;
    }

    .Icon {
      width: 24px;
      height: 24px;
    }
  }

  &.isClickable {
    cursor: pointer;
  }
}

.Initial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--color-pos-utility-colors-dark-gray);
  background-color: var(--color-pos-neutral-2);
  border-radius: 50%;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.StatusType {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: 1px solid var(--color-pos-utility-colors-white);

  &.Success {
    background-color: var(--color-pos-semantic-colors-success);
  }

  &.Error {
    background-color: var(--color-pos-semantic-colors-error);
  }

  &.Warning {
    background-color: var(--color-pos-semantic-colors-warning);
  }
}
