@import 'src/assets/styles/variables';

.AdminBusinessModalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminBusinessModalTitleRight {
  display: flex;

  > * + * {
    margin-left: 10px;
  }
}

.AdminBusinessModalBody {
  padding-top: 20px;
  width: 500px;
  min-height: 380px;
}

.AdminBusinessVerificationContainer {
  display: flex;
  justify-content: space-between;

  .AdminBusinessVerificationStatus {
    display: flex;
    align-items: center;

    .AdminBusinessVerificationStatusText {
      color: var(--color-inverse-75);
      margin-right: 8px;
    }
  }
}

.AdminBusinessModalForm {
  > div {
    margin-bottom: 16px;
  }
}

@media (max-width: $mobile) {
  .AdminBusinessModalBody {
    width: auto;
    min-height: 364px;
  }
}
