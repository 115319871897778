.AdminSidebarBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-modal);
  z-index: 5;
}

.HamburgerButtonContainer {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 4;
  height: 44px;
  cursor: pointer;

  svg path {
    stroke: var(--color-text-black);
    fill: var(--color-text-black);
  }

  &.isHidden {
    opacity: 0;
  }
}
