.GuestAuthSignInHeader {
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
  h2{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
