@import "src/assets/styles/mixins";

.ProfilesInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  padding: 6px;

  p {
    font-size: 12px;
    line-height: 17px;
  }

  .TableName {
    padding-bottom: 4px;
  }

  .AllGuestsCount {
    color: var(--color-inverse-75);
    padding-left: 2px;
  }

  .SelectedGuestsCount {
    color: var(--color-inverse-75);
  }
}

.GuestProfiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  padding: 0 14px;
  margin: 0 -16px;
  gap: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  .AllGuestsButton {
    padding: 14px 17px;
    background-color: white;
    width: auto;

    .CTAButtonName {
      font-weight: 500;
    }

    &.isAllGuestsButtonActive,
    &.isNoGuestsSelected {
      background-color: var(--color-primary);
    }
  }

  div {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .GuestProfile {
    background-color: var(--color-text-white);
    padding: 12px 8px;
    height: 144px;
    min-width: 100px;
    width: 100px;
    border: 1px solid #eeedf2;
    border-radius: 16px;
    &.isActive {
      border: 2px solid var(--color-preferred-secondary-bg);
      h6 {
        color: var(--color-preferred-secondary-text);
      }
    }

    h6 {
      @include text-truncate-ellipsis(2);
      text-align: center;
      word-break: break-word;
    }

    .GuestTotalPrice {
      margin-top: 8px;
      .Price {
        h6 {
          color: var(--color-text-black-low);
        }
      }
    }
  }

  .GuestProfileIdentity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .GuestProfileName {
      width: 78px;
      height: 40px;
    }
    //.GuestTotalPrice{
    //  h6{
    //    color: var(--color-text-black-low);
    //  }
    //}
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 8px;
    flex-shrink: 0;
  }
}
