.PrivacyPolicyContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 30px;

  > h3 {
    color: var(--color-text-black);
    font-style: normal;
    line-height: 24px;
  }

  .PrivacyPolicy {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .PrivacyPolicyTitle {
      align-self: stretch;
      color: var(--color-inverse-100);
      font-style: normal;
      line-height: 20px;
    }

    .PrivacyPolicyDescription {
      align-self: stretch;
      color: var(--color-inverse-100);
      font-size: 14px;
      font-style: normal;
      line-height: 22px;

    }

    .NewLine {
      white-space: pre-line;

    }
  }
}