@import "src/assets/styles/_variables";

.InfoController {
  min-height: 100vh;
  padding: 100px 50px 40px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;
  background-color: var(--color-background-yellow);

  .LighDarkMode {
    background-color: var(--color-mode);
    color: #1163c5;
    cursor: pointer;
    padding: 0 10px;
  }

  .InfoContainer {
    width: 330px;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 15px 15px 50px #b8b436, -15px -15px 50px #faf448;
  }

  .Row {
    font-weight: 300;

    & > span {
      font-weight: bold;
      font-family: Monaco, monospace;
    }
  }
}

.QRContainer {
  .QRImage {
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    margin-bottom: 5px;

    img {
      width: 100%;
      aspect-ratio: 1;
    }

    .NoQRCode {
      font-size: 12px;
    }
  }

  .FileInput {
    input {
      display: none;
    }

    label {
      cursor: pointer;
    }

    .SelectedFileName {
      font-size: 12px;
    }
  }

  .FileInput label, .FormField, .SubmitButton {
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
    width: 100%;
    padding: 10px 15px;
    margin: 5px 0;
    border: 1px solid black;

    &::placeholder {
      color: #000;
    }

  }

  .SubmitButton {
    cursor: pointer;
    background-color: black;
    color: white;
    width: fit-content;
    transition: .3s;

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
  }
}
