@import "src/assets/styles/variables";

.Login {
  display: flex;
  width: 100%;
  height: 100vh;
  background: var(--color-background-black);
}

.LoginBackgroundImg {
  position: relative;
  width: 45%;

  img {
    width: calc(100% + 40px);
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: -40px;
  }
}

.LoginContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 55%;
  border-radius: 40px 0 0 40px;
  padding: 64px 115px 10px;
  z-index: 1;
  background: var(--color-background-light-mode);
  overflow-y: auto;
  height: 100%;
}

.LoginTitle {
  margin: 24px 0 8px;
  color: var(--color-text-black);
}

.LoginSubTitle {
  color: var(--color-text-black);
}

.LoginSubTitleLink {
  color: var(--color-admin-primary);
  margin-left: 8px;
}

.LoginTopContainer {
  position: relative;
  text-align: center;
  margin-bottom: 100px;

  .BackButton {
    position: absolute;
  }

  svg {
    width: 66px;
  }

  .LoginLanguage {
    width: max-content;
    position: absolute;
    right: 0;
    top: -4px;
    .LoginLanguageDropDown {
      .DropdownSelectedItem {
        padding: 8px 16px;
        border: none;
      }
    }
  }
}

.LoginSSO {
  margin-top: 40px;
}

.LoginSSOOr {
  position: relative;
  text-align: center;
  margin-bottom: 40px;

  &::before {
    position: absolute;
    content: "";
    background-color: var(--color-background-light-mode);
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    transform: translateY(2px);
  }

  h6 {
    color: var(--color-text-white);
    display: inline-block;
    padding: 0 10px;
    background: var(--color-background-black);
    z-index: 1;
    position: relative;
  }
}

.LoginSSOGoogleAndFacebook {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    width: calc(50% - 5px);
  }
}

.LoginSSOButton {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;

  img {
    margin-right: 8px;
  }
}

@media (max-width: 1280px) {
  .LoginContent {
    padding: 64px 50px 10px;
    width: 65%;
  }

  .LoginBackgroundImg {
    width: 35%;
  }
}

@media (max-width: $tablet) {
  .LoginSSOGoogleAndFacebook {
    flex-direction: column;

    > * {
      width: 100%;

      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .Login {
    flex-direction: column;
  }

  .LoginContent {
    width: 100%;
    border-radius: 0;
    padding: 24px 16px 30px;
  }

  .LoginBackgroundImg {
    width: 100%;
    height: 100px;

    img {
      width: 100%;
      right: 0;
      clip-path: ellipse(60% 100px at 50% 0);
    }
  }

  .LoginTitle {
    margin: 24px 0 17px;
  }
}
