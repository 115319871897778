@import "src/assets/styles/variables";

.Container {
  min-height: 100vh;
  background: var(--color-gray-20);
}

.Header {
  display: flex;
  padding: 20px 12px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--color-gray-20);
  z-index: 1;
}

.BackWrapper {
  position: absolute;
  left: 16px;

  &:dir(rtl) {
    right: 16px;
    left: auto;
    transform: rotate(180deg);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 32px;
  border: 1px solid var(--color-gray-20);
  background: #eeedf2;
}

.Title {
  align-self: stretch;
  color: var(--color-text-black-high);
  text-align: center;
  margin-inline: 35px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ActionBtn {
  position: absolute;
  right: 16px;

  &:dir(rtl) {
    left: 16px;
    right: auto;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.Items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
  padding: 65px 16px 20px;
  background: var(--color-gray-20);
}

.EmptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  h5 {
    text-align: center;
  }
}

@media (min-width: $tablet) {
  .Header {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
}
