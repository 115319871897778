@import "src/assets/styles/_variables";

.AdminOrderNotifications {
  border-radius: 8px;
  background-color: var(--color-group-background-light-mode);
  padding: 16px;

  &.isNotificationExist {
    background-color: var(--color-green-30);
    animation: flashBackground 1.7s infinite ease;

    .AdminOrderNotificationsHeader {
      height: 52px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: $mobile) {
    padding: 8px;
  }
}


@keyframes flashBackground {
  0% {
    background: var(--color-green-15);
  }
  30% {
    background: var(--color-green-30);
  }
  70% {
    background: var(--color-green-30);
  }
  100% {
    background: var(--color-green-15);
  }
}

.AdminOrderNotificationsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;

  .AdminOrderNotificationsTitle {
    display: flex;
    align-items: center;

    .AdminOrderNotificationsTitleName {
      margin-left: 8px;
    }
  }


  .AdminOrderNotificationCustomButton {
    background-color: var(--color-admin-primary);
    color: var(--color-text-black);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 10px;
    padding: 15px 35px;
    cursor: pointer;
  }

  @media (max-width: $mobile) {
    h4 {
      font-size: 14px;
      font-weight: 600;
    }
    margin: 8px 0 10px;
  }
}

.AdminOrderNotificationsActions {
  &.hasMarginBottom {
    margin-bottom: 40px;
  }
}

.AdminOrderNotificationsSectionTitle {
  color: var(--color-inverse-85);
  margin-bottom: 8px;
}

.AdminOrderNotificationsAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--color-background-light-mode);
  padding: 10px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
