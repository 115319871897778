@import 'src/assets/styles/_variables';

.AdminOrderMenu {
  display: flex;
  flex-direction: column;

  .ActiveTab {
    background-color: var(--color-inverse-85);

    svg {
      path {
        stroke: var(--color-text-white);
      }
    }

    p {
      color: var(--color-background-light-mode);
    }
  }
}

.AdminOrderMenuTabs {
  display: flex;
  align-items: center;
  height: 100%;

  > button {
    background-color: var(--color-mini-button);

    svg {
      width: 32px;
      height: 32px;

      path {
        stroke: var(--color-text-black);
      }
    }

    &:not(.ActiveTab) {
      &:hover {
        background-color: var(--color-group-background-light-mode);
      }
    }

    &.AnimatedTab {
      animation: TabBackgroundColorChange 1.7s infinite ease;
    }
  }

  @media (max-width: $tablet) {
    > button {
      width: 90px;
      height: 48px;
      flex-direction: row;
      align-items: center;

      p {
        margin: 0;
      }

      svg {
        margin-right: 8px;
      }
    }
  }
}

.AdminOrderMenuTab {
  display: flex;
  align-items: center;
  padding: 2px 12px 6px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  transition: $defaultTransition;
  border-bottom: 2px solid transparent;

  &.isActive {
    border-bottom: 2px solid var(--color-admin-primary);
    background: var(--color-mini-button);
  }

  img {
    margin-right: 6px;
  }
}

@keyframes TabBackgroundColorChange {
  0% {
    background: var(--color-group-background-light-mode);
  }

  50% {
    background: var(--color-admin-primary);
  }

  100% {
    background: var(--color-group-background-light-mode);
  }
}
