.OtpInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.OtpInputWrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.InputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  aspect-ratio: 1;
  border-radius: 8px;
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  &.hasError {
    border-color: var(--color-error);
  }

  &:focus-within {
    border-color: var(--color-admin-primary);
  }
}

.Input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 207px;
  flex: 1 0 0;
  align-self: stretch;
  border: none;
  outline: none;
  text-align: center;
  background: transparent;
  color: var(--Light-Mode-Text-Black, #1F1F1F);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.ErrorMessage {
  color: #f34237;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
}

.HiddenInput {
  display: none;
}
