@import "src/assets/styles/_variables";

.ModificationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  border: none;
  background: none;
  cursor: pointer;
  gap: 13px;
  color: var(--color-text-black);

  &.isReadOnly {
    pointer-events: none;
  }

  &.lightWhite {
    span,
    .Price {
      color: var(--color-text-white);
    }
  }

  &.lightBlack {
    span,
    .Price {
      color: var(--color-text-black);
    }
  }

  &.cursorDefault {
    cursor: default;
  }
}

.ModificationItemLabelContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  width: 60%;

  span {
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .RadioButtonContainer{
    flex-shrink: 0;
  }
}

.ModificationItemPrice {
  font-size: 14px;
  line-height: 17px;
}
