@import "src/assets/styles/_variables";

.FavoritesIconButton {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--radius-preferred-border-radius);
  width: 44px;
  height: 44px;

  &.typeA {
    background-color: var(--color-background-light-mode);
    border-radius: var(--radius-preferred-border-radiusF);
  }

  &.typeB {
    background-color: var(--color-mini-button);
    min-width: 44px;
    min-height: 44px;
    border-radius: var(--radius-preferred-border-radiusB);
  }

  &.typeC {
    width: 32px;
    height: 32px;
    background-color: var(--color-background-light-mode);
    border-radius: var(--radius-preferred-border-radiusL);
    &.menuListViewBorder {
      border-radius: var(--radius-preferred-border-radiusZ)
        var(--radius-preferred-border-radiusS);
      border: 1px solid var(--color-stroke);
      margin: var(--margin-preferred-marginMR);
    }
  }
  &.typeD {
    min-width: 44px;
    min-height: 44px;
    border-radius: 8px;
  }
  &.typeE {
    background-color: var(--color-mini-button);
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
  }

  &.typeF {
    background-color: var(--color-mini-button);
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background: var(--Glass-effect, rgba(0, 0, 0, 0.2));
    backdrop-filter: blur(8px);
    img {
      margin: 8px;
    }
  }
  &.typeG {
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
    margin-left: auto;
    background: none;

  }
}
