.FooterSocial {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: var(--Light-Mode-Text-White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
}
.FooterSocial:active{
  background-color: #FD0;
}

