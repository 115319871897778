.ModificationSettingsModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ModificationSettingsModalBodySelection {
  margin-top: 48px;
}

.ModificationSettingsModalBodySelectionForm {
  margin-bottom: 54px;
  display: flex;
  justify-content: space-between;
}

.ModificationSettingsModalBodyOptionsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-stroke);
}

.ModificationSettingsModalBodyOptionsList {
  margin-top: 20px;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.ModificationSettingsModalBody {
  width: 500px;
}

.ModificationSettingsModalBodyOptions {
  margin-bottom: 90px;
}

.ModificationSettingsModalBodyForm {
  padding-top: 7px;
}

.ModificationSettingsModalBodyFormTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-stroke);
  margin-bottom: 20px;
}
