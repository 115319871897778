.SelectBusinessModalBody {
  padding: 30px 0 28px;
  color: var(--color-background-black);
  max-width: 500px;

  > p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 4px;
    color: inherit;
    line-height: 20px;
  }

  .UserBusinessLists {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    color: inherit;

    li {
      width: calc((100% / 2) - 10px);
      min-height: 180px;
      padding: 16px 12px;
      border-radius: 16px;
      border: 1px solid var(--color-stroke);
      background: var(--color-mini-button);
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin-top: 32px;

      img {
        object-fit: cover;
        width: 96px;
        height: 96px;
        border-radius: 50%;
      }
      h3 {
        width: 85%;
        text-align: center;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .InactiveBusiness {
        display: flex;
        p {
          color: var(--toastify-color-error);
          font-size: 12px;
          line-height: 16px;
        }
        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
        .TextDash {
          margin: 0 3px;
        }
      }
    }

    .ActiveBusiness {
      border: 1px solid var(--color-admin-primary);
      background: var(--color-admin-primary_15);
    }

    .DeletedBusiness {
      border: 1px solid var(--color-deleted-border-unselect);
      background: var(--color-deleted-background-unselect);
      h3 {
        color: var(--color-inverse-75);
      }
    }

    .DeletedActiveBusiness {
      border: 1px solid var(--toastify-color-error);
      background: var(--color-deleted-background-unselect);
    }
  }
}
