.MyProfileResetPasswordHeader {
  padding: 16px;
  display: flex;
  align-items: center;

  h2 {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
  }
}

.MyProfileResetPasswordBody {
  padding: 80px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  .MyProfileResetPasswordBodyTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .MyProfileResetPasswordBodyTitle {
      align-self: stretch;
      color: var(--color-text-black);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
    }

    > h6 {
      color: var(--color-text-black);
      text-align: center;
      align-self: stretch;
    }
  }

  .MyProfileResetPasswordForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
  }
}