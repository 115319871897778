.Checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  h5 {
    color: var(--color-text-black-medium);
  }
  &:hover {
    .Icon {
      border: 2px solid var(--color-pos-utility-colors-gray);
    }
    h5 {
      color: var(--color-text-black-high);
    }
  }
}
.isDisabled {
  cursor: not-allowed;
  .Icon {
    border: 2px solid var(--color-gray-50);
    pointer-events: none;
    cursor: not-allowed;
  }
  h5 {
    color: var(--color-gray-50);
  }
}
.isChecked {
  .Icon {
    border: 2px solid #4042e2;
    svg {
      width: 100%;
      height: 100%;
    }

  }
  h5{
    color: var(--color-text-black-high);

  }
  &:hover {
    .Icon{
      border: 2px solid #4042e2;
    }
    h5{
      color: var(--color-text-black-high);
    }
  }
}
.CheckboxModalLabel {
  text-decoration: underline;
  cursor: pointer;
}

.Icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid var(--color-pos-neutral-1);
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: #4042e2;
    }
  }
}
