@import "src/assets/styles/_variables";
.Root {
  display: flex;
  position: relative;
  flex-direction: column;
}
.Dropdown {
  width: 100%;
  position: relative;
}
.Text {
  color: var(--color-text-black-high);
  margin-bottom: 8px;
}
.HelperText {
  color: var(--color-pos-utility-colors-dark-gray);
  position: absolute;
  bottom: -24px;
  left: 0;
}
.Chips {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.DropdownChip {
  width: fit-content;
}
.hasError {
  .SelectedItem {
    border: 1px solid var(--color-pos-semantic-colors-error);
  }
  .HelperText {
    color: var(--color-pos-semantic-colors-error);
  }
}
.hasWarning {
  .SelectedItem {
    border: 1px solid var(--color-pos-semantic-colors-warning);
  }
  .HelperText {
    color: var(--color-pos-semantic-colors-warning);
  }
}
.SelectedItem {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid var(--color-pos-neutral-3);
  background: var(--color-text-white);

  &.hasValue {
    border: 1px solid var(--color-pos-neutral-2);
  }
  color: var(--color-text-black);
  cursor: pointer;

  &.isOpenOptions {
    border: 1px solid var(--color-pos-brand-colors-primary-normal);
  }

  &.isDisabled {
    background: var(--color-gray-30);
    h5 {
      color: var(--color-inverse-50);
    }
    .Arrow {
      filter: opacity(0.5);
    }
  }

  h5 {
    word-break: break-all;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}

.Options {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  padding: 8px;
  background: var(--color-background-light-mode);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  border-radius: 16px;
  overflow: hidden;
  z-index: 6;
  max-height: 240px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Arrow {
  transition: 0.3s;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.SelectedItemSubTitle {
  color: var(--color-text-black-high);
}

.GroupName {
  padding:8px 12px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.EmptyState {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-black-medium);
}

.SubOption {
  padding-left: 30px;
}

.SelectedItemTitle {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}

.SelectedItemTitleImage {
  width: 24px;
  height: 24px;
  margin-right: 11px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.SelectedItemTitleSymbol {
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.Label {
  color: var(--color-text-black-medium);
  transition: $defaultTransition;
  pointer-events: none;
  width: calc(100% - 52px);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &.hasChips {
    display: none;
  }

  &.isCentered {
    top: 50%;
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    transform: translateY(-50%);
  }
}
