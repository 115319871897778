.SpeechToTextContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  position: fixed;
  background: var(--color-gray-20);
  width: 100%;
  z-index: 1;

  .BackButtonWrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--color-gray-20);
    background: #eeedf2;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MagicOrderBackButton {
      display: flex;
      align-items: center;
      padding: 0;
      width: 24px;
      height: 24px;
      background-color: transparent;

      svg {
        &:dir(rtl) {
          transform: rotate(180deg);
        }

        path {
          stroke: var(--color-gray-600);
        }
      }
    }
  }

  .SpeechToTextContainerHeaderTitle {
    text-align: center;
    color: var(--color-text-black-high);
    margin-top: 8px;
  }

  .SpeechToTextContainerHeaderActions {
    display: flex;
    align-items: center;
    gap: 12px;

    .TrashContainer {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid var(--color-gray-20);
      background: #eeedf2;
      padding: 8px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .BasketContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--color-gray-20);
    background: #eeedf2;
    padding: 8px;

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: var(--color-gray-600);
      }
    }

    .BasketButtonCountWrapper {
      position: absolute;
      padding: 0 5px;
      border-radius: 10px;
      top: -6px;
      left: 24px;
      background: var(--color-preferred-secondary-bg);
      display: flex;
      align-items: center;
      justify-content: center;

      &:dir(rtl) {
        left: auto;
        right: 24px;
      }

      p {
        color: var(--color-text-black-high);
        text-align: center;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: var(--color-gray-600);
      }
    }
  }
}
