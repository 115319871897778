.AdminMapSidebar{
  background-color: var(--color-text-black);
  height: 100vh;
  width: 105px;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.AdminMapSidebarElements{
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}
.AdminMapSidebarActionButtons{
  display: flex;
  flex-direction: column;
  gap: 15px;
}