.MyReservations {
  height: 100%;
  padding: 0 16px 16px;

  .AddNewReservation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    button {
      background: var(--color-preferred-secondary-bg);
      h5 {
        color: var(--color-preferred-secondary-text);
      }
      svg {
        path {
          stroke: var(--color-preferred-secondary-text);
        }
      }
    }
  }

  .MyReservationsReservationList {
    height: calc(100% - 158px);
    margin-top: 16px;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  //.GuestReservationEmptyClass {
  //  padding: 0 20px;
  //}
}
.GuestReservationLoadingWrapper {
  height: 100%;
  .GuestReservationLoading {
    height: 100%;
  }
}

.ClientReservation {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  margin-bottom: 16px;
}
