@mixin text-truncate-ellipsis($line-count: 2) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $line-count) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $line-count;
    -webkit-box-orient: vertical;
  }
}

@mixin cornerShadow($side, $radius, $shadowX, $shadowY, $shadowColor) {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;

  @if $side == "left" {
    left: -20px;
    border-bottom-right-radius: $radius;
    box-shadow: $shadowX $shadowY 0 0 $shadowColor;
  } @else if $side == "right" {
    right: -20px;
    border-bottom-left-radius: $radius;
    box-shadow: $shadowX $shadowY 0 0 $shadowColor;
  }
}
