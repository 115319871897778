.IntegrationCardItem {
  width: 223px;
  height: 210px;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 #00000016;
  padding: 16px;
  background-color: var(--color-text-white);

  .IntegrationCardItemInfo {
    .IntegrationCardItemLogoAndIcon {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 8px;

      .IntegrationCardItemLogo {
        width: 58px;
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-gray);
        background: #FFF;
        border-radius: 12px;
      }

      .IntegrationCardItemIcon {
        border-radius: 8px;
        padding: 8px;
        background-color: var(--color-mini-button);

        svg {
          display: block;

          path {
            fill: black;
          }
        }

        &:hover {
          background-color: var(--color-mini-button-reverse);
          cursor: pointer;

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .IntegrationCardItemTitle {
      margin-bottom: 8px;
    }

    .IntegrationCardItemOrganizationId {
      margin-bottom: 8px;
    }

    .IntegrationCardItemIsActive {
      color: #B3B3B3;
      font-size: 12px;

      &.isActive {
        span {
          color: var(--color-success);
        }
      }

      p {
        font-size: 12px;
        color: #B3B3B3;

        span {
          color: var(--toastify-color-error);
        }
      }
    }

    .IntegrationCardAction {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 10px;

      h6 {
        color: var(--color-inverse-25);
      }
    }
  }

  &.TelegramIntegration{
    .IntegrationCardItemIsActive p span{
      color: var(--color-text-black);
    }
  }
}