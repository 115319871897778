@import "src/assets/styles/_variables";

.Root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-auto-rows: min-content;
  gap: 16px;
  align-items: start;
  overflow-y: auto;
  padding: 28px 0;
  height: calc(100% - 136px);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.NavigationCard {
  width: 288px;
}

@media (max-width: $mobile) {
  .Root {
    height: 100%;
    padding: 0;
    gap: 8px;
  }
}

@media (max-width: 680px) {
  .Root {
    grid-template-columns: repeat(2, 1fr);
  }
  .NavigationCard {
    width: 100%;
    gap: 20px;
    height: min-content;

    div {
      width: 44px;
      height: 44px;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (min-width: 680px) and (max-width: $desktop) {
  .Root {
    grid-template-columns: repeat(3, 1fr);

    .NavigationCard {
      width: 100%;
      gap: 36px;
      height: min-content;

      div {
        width: 52px;
        height: 52px;
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

@media (min-width: $tablet) {
  .Root {
    grid-template-columns: repeat(4, 1fr);

    .NavigationCard {
      width: 100%;
      gap: 36px;
      height: min-content;

      div {
        width: 52px;
        height: 52px;
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
@media (min-width: $desktop) {
  .Root {
    grid-template-columns: repeat(5, 1fr);

    .NavigationCard {
      width: 100%;
      gap: 36px;
      height: min-content;

      div {
        width: 52px;
        height: 52px;
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
