@import "src/assets/styles/_variables";

.BasketInputControl {
  position: relative;
  display: flex;
  flex-direction: column;
}

.BasketInputControlContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  p {
    color: var(--color-text-black-low);
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-self: flex-end;
  }
  .ClearMessageIcon {
    width: 24px;
    height: 24px;
    background-color: #bfbfbf;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    &:dir(rtl) {
      left: 12px;
      right: auto;
    }
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        stroke: var(--color-text-white);
      }
    }
  }
}

.BasketInputControlInput {
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-gray-40);
  background: transparent;

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
  }
  &::placeholder {
    color: var(--color-text-black-low);
    font-size: 16px;
    font-weight: 500;
  }

  &:focus {
    border: 1px solid var(--color-admin-primary);
  }

  &:not(:focus) {
    &.hasValue {
      border: 1px solid var(--color-gray-600);
    }
  }

  &.hasError {
    border: 1px solid var(--color-error);
  }
}

.BasketInputControlTextarea {
  padding: 12px 48px 12px 12px;
  &:dir(rtl) {
    padding: 12px 12px 12px 48px;
  }
  resize: none;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  min-height: 48px;
  max-height: 96px;
  line-height: 26px;
  &:focus {
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
