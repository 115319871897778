.AddSpecialCategoryItemModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 28p;

  .AddSpecialCategoryTitle {
    color: var(--Text-Black-High, rgba(0, 0, 0, 0.87));
  }

  .AddSpecialCategoryButtonWrapper {
    border-radius: 4.8px;
    background: var(--Light-Mode-Mini-Button, #F7F7F7);
  }
}

.AddSpecialCategoryItemModalBody {
  width: 500px;
  padding-bottom: 28px;

  .ModalBodyFilterContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .ModalBodyFilter {
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 15px;
      align-self: stretch;
      border-radius: 12px;
      background: #EDEDED;

      h6 {
        color: var(--Light-Mode-Text-Black, #1F1F1F);
      }
    }

    .AddSpecialCategoryVisibilityDropdown {
      margin-top: 20px;
    }
  }

  .ModalBodyAllItemsContainer {
    display: flex;
    margin: 28px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Light-Mode-Mini-Button, #F7F7F7);

    .ModalBodyAllItemsHeader {
      width: 100%;

      .ModalBodyAllItemsHeaderTitle {
        padding: 20px 0 20px 16px;
        color: var(--Light-Mode-Text-Black, #1F1F1F);
      }
    }

    .AllItemsBodyItemsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      margin: 0 16px 16px;

      .AllItemsBodyCategory {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        background: #FFF;

        .AllItemsBodyCategoryHeader {
          display: flex;
          padding: 16px 16px 0 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 6px;

          .AllItemsBodyCategoryTitle {
            color: var(--Light-Mode-Text-Black, #1F1F1F);
          }

          .AllItemsBodyCategoryHeaderButton {
            cursor: pointer;
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: var(--Light-Mode-Mini-Button, #F7F7F7);
          }
        }

        .AllItemsBodyCategoryBody {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;

          .AllItemsBodyCategoryBodyItem {
            width: 100%;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;

            .RadioButtonContainer {
              width: 24px;
              height: 24px;
            }

            .AllItemsBodyCategoryBodyItemTitle {
              display: flex;
              gap: 8px;
              align-items: center;

              .CategoryBodyItemImage {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}