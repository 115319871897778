.CompletedOrderPopUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 30px 16px;
  height: 100%;

  .CompletedOrderSmileyFace {
    margin-bottom: 56px;
  }

  .CompletedOrderMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .CompletedOrderTitle {
      color: var(--color-text-black);
      width: 80%;
      margin-bottom: 8px;
    }

    .CompletedOrderDescription {
      color: var(--color-inverse-85);
    }
  }

  .CompletedOrderFooterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    > :not(:last-child) {
      margin-bottom: 16px;
      color: var(--color-text-black);
    }

    .CompletedOrderOrSeparator {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .CompletedOrderOrSeparator::before,
    .CompletedOrderOrSeparator::after {
      content: '';
      width: 100px;
      border-bottom: 1px solid var(--color-inverse-25);
      margin: 0 16px;
    }
  }
}