.WithoutImageWrapper {
  display: flex;
  gap: 16px;
  scrollbar-width: none;
  flex-direction: column;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}