@import "src/assets/styles/mixins";

.Table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: var(--color-background-light-mode);
  cursor: pointer;
  width: 130px;
  height: 160px;
  padding: 16px 12px;
  justify-content: space-between;

  &:hover {
    background-color: var(--color-group-background-light-mode);
  }
}

.TableTitleName {
  text-transform: capitalize;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

}
.TableQRNameContainer {
  padding: 4px 8px;
  border-radius: 10px;
  background: rgba(76, 191, 74, 0.15);
  display: flex;
  gap: 4px;
  svg{
    width: 30px;
    height: 16px;
  }
}
.TableUnlinkQRNameContainer {
  background-color: var(--color-mini-button);
  padding: 4px 8px;
  border-radius: 10px;
}
.TableName{
  display: flex;
  gap: 4px;
  align-items: center;
  svg{
    width: 24px;
    height: 24px;
  }
}
.TableQR {
  width: 100%;

  &.isLinked {
    .TableQRIcon {
      min-width: 16px;
      path {
        stroke: var(--color-success);
      }
    }
  }
}

.TableQRName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-black);
}
.Qr{
  color: var(--color-success);
}
.TableQRNameUnlinked {
  color: var(--color-inverse-50);
}
