.WaitLoading {
  width: 66.67px;
  height: 20px;
  position: relative;
  z-index: 1;

  .circle {
    width: 6.67px;
    height: 6.67px;
    position: absolute;
    border-radius: 50%;
    background:  linear-gradient(130deg, #2F7CE5 -66.29%, #33DFF6 21.86%, #A154F2 104.54%);;

    left: 15%;
    transform-origin: 50%;
    animation: circle7124 .5s alternate infinite ease;
  }

  @keyframes circle7124 {
    0% {
      top: 20px;
      height: 1.67px;
      border-radius: 16.67px 16.67px 8.33px 8.33px;
      transform: scaleX(1.7);
    }

    40% {
      height: 6.67px;
      border-radius: 50%;
      transform: scaleX(1);
    }

    100% {
      top: 0%;
    }
  }

  .circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
  }

  .circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
}
