
.AdminInventoryCategories {
  margin-top: 32px;
  padding: 36px 30px 0;
}

.AdminInventoryCategoriesListAdd {
  margin: 0 0 10px 0;
}

.AdminInventoryCategoriesList {
  display: flex;
  align-items: center;
  margin-top: 20px;   

  &Add {
    width: 56px;
    min-width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #f2f2f2; 
    cursor: pointer;
  }
  
  &Existing {
    display: flex;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    margin-left: 20px;
    padding: 0 20px;


    &::-webkit-scrollbar {
      display: none; 
    }

    .AdminInventoryCategoriesListItem {
      padding: 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff; 
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      cursor: pointer;
      position: relative;

      .elipsl, .elipsr {
        position: absolute;
        bottom: -7px;
        fill: var(--Light-Mode-Group-Background, #EDEDED);
        display: none;

        &.active {
          display: block;
        }
      }
    
      .elipsl {
        left: -16px;
      }

      .elipsr {
        right: -16px;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }

      &.active {
        background: var(--Light-Mode-Group-Background, #EDEDED);
      }

      .TagCard {
        padding: 5px 10px;
        border: 1px solid var(--color-stroke);
      }
    }
  }
}

.AdminInventoryItems {
  padding: 36px 30px 0;
  background: var(--Light-Mode-Group-Background, #EDEDED);
  height: 90vh;
}

.AdminInventoryItemsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.AdminInventoryItemsTitleLeft {
  h4 {
    margin-bottom: 5px;
    color: var(--color-text-black);
  }

  h6 {
    color: var(--color-inverse-25);
  }
}

.AdminInventoryItemsTitleRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.AdminInventoryItemsTitleRightSearch {
  margin-right: 16px;
}

.AdminInventoryItemsStock {
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
  }
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
}

.tableRowCell {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rowactioncontainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  p{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
}
.actioncontainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.AmountContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}