@import "src/assets/styles/variables";

.ResetPasswordForm {
  padding-top: 56px;
}

.ResetPasswordFormSubmitButton {
  margin-top: 56px;
}

.ResetPasswordFormInput {
  width: 100%;

  &:first-child {
    margin-bottom: 24px;
  }
}

@media (max-width: $mobile) {
  .ResetPasswordFormSubmitButton {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 30px;
  }
}
