.StatsNumberDataWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
  gap: 20px;

  > div {
    width: calc((100% - 50px) / 3);
    margin-bottom: 20px;
    height: 88px;
  }
}