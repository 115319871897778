.Menu {
  background-color: var(--color-gray-800);
}

.Container {
  padding-top: 16px;
  background-color: var(--color-mini-button);
  border-radius: 24px 24px 0 0;
  padding-bottom: 12px;
  min-height: calc(100vh - 237px);

  :global {
    .MenuDisplayNoResult {
      height: calc(100vh - 271px);
      margin: 0;
    }
  }
}

.Wrapper {
  margin-bottom: 100px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
