@import "src/assets/styles/variables";

.AdminUserModalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: var(--color-text-black);
  }

  .AdminUserModalTitleLeft {
    .IconButton {
      svg {
        width: 10px;
      }
    }
  }

  .AdminUserModalTitleRight {
    display: flex;
    align-items: center;

    > * {
      margin-left: 8px;
    }
  }
}

.AdminUserModalBody {
  width: 500px;
  margin-top: 40px;
  height: calc(500px - 126px);

  .AdminUserModalOtpVerify {
    .AdminUserModalOtpVerifyTitle {
      margin-top: 40px;
      margin-bottom: 16px;
    }

    .AdminUserModalOtpVerifySubTitle {
      margin-bottom: 40px;
    }

    .AdminUserModalOtpVerifyResend {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }

    .ForgetPasswordVerifyOtpResendButton {
      padding: 8px 16px;
      border-radius: 72px;
      border: 1px solid var(--color-text-black);
      background: none;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;

      &.isDisabled {
        color: var(--color-inverse-50);
        border: 1px solid var(--color-inverse-50);
        cursor: not-allowed;
      }
    }
  }

  .AdminAddUserModal {
    .AdminAddUserModalBodyEmailOrPhone {
      h5 {
        margin-bottom: 16px;
      }
    }

    .AdminUserModalBodyForm {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      margin-top: 16px;

      > div {
        width: calc(50% - 5px);
        margin-bottom: 16px;
        flex: 1 1 auto;
      }

      .AdminUserModalSendContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        .AdminUserModalSendDescription {
          color: var(--color-text-black);
          margin-right: 4px;
        }

        .AdminUserModalSendButton {
          width: 80px;
          height: 40px;
          padding: 8px 16px;
        }
      }
    }
  }

  .AdminResetPassModalForm {
    .InputControlContainer {
      margin-bottom: 16px;
    }
  }

  .AdminUserModalBodyResetPassword {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .AdminUserModalBodyResetPasswordTitle {
      color: var(--color-text-black);
      margin-right: 4px;
    }

    .AdminUserModalBodyResetPasswordButton {
      text-wrap: nowrap;
      padding: 8px 16px;
      width: fit-content;
    }
  }

  .AdminUserModalSignInDetails {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;

    > div {
      margin-top: 16px;
    }
  }

  .UserModalWithoutSignUp {
    margin-top: 16px;
    h6 span {
      cursor: pointer;
    }
  }
}

.AdminUserModalGenerateNewPass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  .AdminUserModalGenerateNewPassButton {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    background: var(--color-mini-button);
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;

    &:hover {
      background: var(--color-gray);
    }
  }
}
