@import "src/assets/styles/mixins";

.Root {
  padding: 14px 16px;
  display: flex;
  border-radius: 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.Small{
    .Label{
      font-size: 16px;

    }
  }
  &.Medium{
    .Label{
      font-size: 18px;

    }
  }

  &.isLoading {
    align-items: center;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    svg {
      path {
        stroke: var(--color-gray-50);
      }
    }
    .Label {
      color: var(--color-gray-50);
    }
  }

  &.Primary {
    background-color: var(--color-pos-brand-colors-primary-normal);
    &.isDisabled {
      background-color: var(--color-gray-30);
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-primary-pressed);
    }
  }

  &.Secondary {
    background-color: var(--color-pos-brand-colors-secondary-low);
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        color: var(--color-gray-50);
        -webkit-text-fill-color: var(--color-gray-50);
        background: transparent;
      }
    }
    .Label {
      background: var(--color-pos-brand-colors-secondary-normal);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-secondary-medium);
    }
  }

  &.Tertiary {
    border: 1px solid var(--color-pos-neutral-3);
    background-color: var(--color-pos-utility-colors-white);
    &.isDisabled {
      background-color: var(--color-pos-utility-colors-white);
      .Label {
        color: var(--color-gray-50);
        -webkit-text-fill-color: var(--color-gray-50);
        background: transparent;
      }
    }
    .Label {
      background: var(--color-pos-brand-colors-secondary-normal);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:active,
    &:hover {
      background: var(--color-pos-brand-colors-secondary-normal);
      .Label {
        color: var(--color-pos-utility-colors-white);
        -webkit-text-fill-color: var(--color-pos-utility-colors-white);
        background: transparent;
      }
    }
    .isLoading {
      background-color: var(--color-pos-utility-colors-white);
    }
  }

  &.Neutral {
    border: 1px solid var(--color-pos-neutral-2);
    background-color: var(--color-pos-neutral-3);
    .Label {
      color: var(--color-pos-utility-colors-dark-gray);
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-neutral-2);
    }
    &.isDisabled {
      .Label {
        color: var(--color-gray-50);
      }
    }
  }

  &.Text {
    background-color: transparent;
    .Label {
      background: var(--color-pos-brand-colors-secondary-normal);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-secondary-low);
    }
    &.isDisabled {
      .Label {
        color: var(--color-gray-50);
        -webkit-text-fill-color: var(--color-gray-50);
        background: transparent;
      }
    }
  }

  &.Split {
    background-color: var(--color-pos-brand-colors-primary-normal);
    position: relative;
    padding-right: 24px;
    justify-content: normal;
    .Label {
      padding-right: 30px;
      &:before {
        position: absolute;
        width: 1px;
        right: 48px;
        height: 100%;
        background-color: var(--color-text-black-low);
        content: "";
        top: 0;
      }
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-brand-colors-primary-pressed);
    }
    .Icon {
      &:last-child {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.isLoading {
      justify-content: center;
    }
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        &:before {
          background-color: var(--color-gray-40);
        }
      }
    }
  }

  &.Lock {
    background-color: var(--color-gray-80);
    .Label {
      color: var(--color-pos-utility-colors-white);
    }
    &:active,
    &:hover {
      background-color: var(--color-pos-gray-100);
    }
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        color: var(--color-gray-50);
      }
    }
  }
  &.Success {
    background-color: var(--color-pos-semantic-colors-success);
    .Label {
      color: var(--color-pos-utility-colors-white);
    }
    &:active,
    &:hover {
      background-color: #03a741;
    }
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        color: var(--color-gray-50);
      }
    }
  }
  &.Error {
    background-color: var(--color-pos-semantic-colors-error);
    .Label {
      color: var(--color-pos-utility-colors-white);
    }
    &:active,
    &:hover {
      background-color: #b7091e;
    }
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        color: var(--color-gray-50);
      }
    }
  }
  &.LogOut {
    background-color: rgba(227, 21, 46, 0.16);
    .Label {
      color: var(--color-pos-semantic-colors-error);
    }
    &:active,
    &:hover {
      background-color: rgba(227, 21, 46, 0.32);
    }
    &.isDisabled {
      background-color: var(--color-gray-30);
      .Label {
        color: var(--color-gray-50);
      }
    }
  }
}
