.GuestBlock {
  height: 100%;
  position: relative;
  .GuestBlockHeader {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .GuestBlockBoxContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 16px;
    .GuestBlockBox {
      width: 245px;
      .SelectBusinessModalBody {
        padding: 0;
        ul {
          width: 100%;
          justify-content: center;
          li {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
    .GuestBlockText {
      margin-top: 40px;
      h2 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-top: 8px;
      }
    }
  }
}
