.DownloadButton {
  margin-top: 20px;
}
.Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  gap: 12px;
  //overflow-y: auto;
  //max-height: calc(100vh - 300px);
  //&::-webkit-scrollbar {
  //  display: none;
  //}
  //scrollbar-width: none;
}

.Row {
  display: flex;
  align-items: center;
  gap: 12px;
  > div {
    flex: 9;
  }
  .Minus {
    flex: 1;
    height: 46px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
.Range{
  width: 130px;
}
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  >div{
    display: flex;
    align-items: center;
    gap: 8px;
    span{
      width: 20px;
    }
  }
}
.Plus {
  width: 100px;
  height: 46px;
  svg {
    width: 28px;
    height: 28px;
    path {
      stroke: var(--color-text-black);
    }
  }
}
