.Root {
  display: flex;
  width: 288px;
  height: 156px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  border-radius: 16px;
  border: 1px solid var(--color-pos-neutral-2);
  background-color: var(--color-pos-utility-colors-white);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.02);

  &.isClickable {
    cursor: pointer;
  }

  &.Primary {
    .Icon {
      background-color: var(--color-pos-brand-colors-primary-normal);
    }
  }

  &.Secondary {
    .Icon {
      background: var(--color-pos-brand-colors-secondary-normal);
    }
  }
}

.Icon {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.Label {
  line-height: 28px;
}
