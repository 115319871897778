.AddPromotionOrBannerModalHeader {
  display: flex;
  align-items: center;

  .AddPromotionOrBannerModalHeaderTitle {
    flex-grow: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .ModalHeaderTitlePublishMode {
    display: flex;
    align-items: center;

    h6 {
      margin-right: 10px;
      color: var(--color-inverse-50);
      white-space: nowrap;
    }
  }

  .DeleteButton {
    &.isDisabled {
      background-color: var(--color-mini-button);

      > svg {
        path {
          fill: var(--color-inverse-25);
        }
      }
    }
  }

  > button,
  > div {
    margin-left: 8px;
    flex: 0 0 30px;
  }

  h3 {
    margin-right: 10px;
  }
}

.AddPromotionOrBannerModalBody {
  width: 500px;

  .ThumbnailAndCTATypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    margin-bottom: 24px;

    .ThumbnailContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;

      &.Banner {
        .ThumbnailPhoto {
          > div {
            width: 361px;
            height: 84px;
          }
        }

        .ThumbnailPhotoAddNew {
          .ThumbnailPhotoAddNewComponent {
            .SpinnerContainer {
              width: 361px;
              height: 84px;
            }

            .AddPhotoButtonInner {
              width: 361px;
              height: 84px;
            }
          }
        }
      }

      .ThumbnailTitle {
        color: var(--Text-Black-High, rgba(0, 0, 0, 0.87));
        line-height: 24px;
      }

      .ThumbnailPhoto {
        display: flex;
        align-items: center;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        > div {
          height: 136px;
          width: 361px;
          flex-shrink: 0;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      .ThumbnailPhotoAddNew {
        .ThumbnailPhotoAddNewComponent {
          .SpinnerContainer {
            width: 361px;
            height: 136px;
          }

          .AddPhotoButtonInner {
            width: 361px;
            height: 136px
          }
        }
      }

    }

    .CTAContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .CTATitleAndIsActiveButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;

        .CTATitle {
          color: var(--Text-Black-High, rgba(0, 0, 0, 0.87));
          line-height: 24px;
        }
      }

      .CTATypes {
        display: flex;
        padding: 4px;
        align-items: center;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid var(--color-gray-20);
        background: var(--Gray-10, #FAFAFA);

        .CTAType {
          height: 56px;
          cursor: pointer;
          display: flex;
          padding: 4px 32px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border-radius: 8px;

          h6 {
            text-align: center;
            color: var(--Text-Black-Low, rgba(0, 0, 0, 0.38));
            line-height: 20px;
          }

          &.notAllowed {
            cursor: not-allowed;
          }

          &.isChecked {
            padding: 8px 32px;
            background: var(--color-admin-primary);

            h6 {
              color: var(--Text-Black-High, rgba(0, 0, 0, 0.87));
            }
          }
        }
      }
    }
  }

  .AddPromotionOrBannerFormContainer {
    .CTALinkDropdown {
      .DropdownLabel {
        background-color: var(--color-text-white);
      }
    }

    .AddPromoOrBannerModalBodyFormDescriptionInput {
      margin-top: 14px;
    }
  }
}