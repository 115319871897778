.AdminOrderMapContainer {
  background: var(--color-mini-button);
  height: 100dvh;
  .EmptyStateContainer {
    min-height: calc(100vh - 112px);
    padding: 0 0 30px  0;
  }
}
.AdminOrderMapWrapper {
  display: flex;
}
.AdminOrderMapSections {
  display: flex;
}
