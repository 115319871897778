.SectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;

  .SectionHeaderItem {
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}
