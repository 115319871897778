.AdminQRModalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: var(--color-text-black);
  }
  .AdminQRModalTitleAndId {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.AdminQRModalBodyForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;

  > div {
    margin-bottom: 16px;
    width: 100%;
  }
}

.AdminQRModalBodyFormDropdown {
  width: 100%;
  margin-bottom: 20px;
}

.AdminQRModalTitleRight {
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }
}

.AdminQRModalBody {
  width: 500px;
  padding-bottom: 40px;

  &.AddQRModalBody {
    margin-bottom: 200px;
  }
}

.AdminQRModalFooter {
  display: flex;

  .DeleteButton {
    margin-right: 16px;
  }

  .DeleteButton,
  .PrimaryButton {
    flex: 1;
  }
}
