
.AdminOrderMenuDisplay{
  .Search{
    border: 1px solid #1F1F1F !important;
    border-radius: 24px;
    .SearchIconContainer svg{
      scale: 1.5;
    }
    .SearchInput{
      font-weight: 500;
      font-size: 26px;
      padding: 6px 16px 12px 38px;
    }
  }
}