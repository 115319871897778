@import "src/assets/styles/variables";

.AdminOrderItemModalBody {
  width: 500px;
  padding-bottom: 90px;
}

.AdminOrderItemModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    flex: 0 0 auto;
  }
}

.AdminOrderItemModalBody {
  .MenuItemInfoWrapper {
    h2 {
      display: none;
    }
  }
}

.AdminOrderItemModalBodyModifications {
  margin-top: 30px;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.AdminOrderItemModalHeaderSubTitle {
  color: var(--color-inverse-25);
}

.AdminOrderItemModalHeaderTitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.AdminMenuItemFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .AnimatedPlusMinusButtonContainer {
    flex: 1;
    position: sticky;
  }

  .CTAButton {
    flex: 3;
    bottom: 0;
  }
}

.AdminOrderModalBodyModifications {
  padding: 16px 30px;
  margin-top: 32px;
  margin-right: -30px;
  margin-left: -30px;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.AdminOrderModalBody {
  width: 500px;
  padding-bottom: 90px;
}

@media (max-width: 1024px) {
  .AdminOrderModalBody {
    width: 450px;
  }
}

@media (max-width: $mobile) {
  .AdminOrderModalBody {
    width: 100%;
  }
}
