.phone-input {
  &.hasError {
    .react-international-phone-country-selector-button,
    .react-international-phone-input {
      border-color: var(--color-error);
    }
  }

  .react-international-phone-country-selector-dropdown {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    overflow: hidden;
    z-index: 6;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    outline: none;
    top: 0;
    transform: translateY(-100%);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .react-international-phone-input {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }

  .react-international-phone-country-selector-button__button-content {
    padding: 8px 12px;
  }

  .react-international-phone-input {
    height: 46px;
    border-radius: 0 8px 8px 0;
  }

  .react-international-phone-country-selector {
    button {
      height: 46px;
      border-radius: 8px 0 0 8px;
    }
  }
}

.react-international-phone-input-container
  .react-international-phone-input--disabled,
.react-international-phone-country-selector-button--disabled {
  color: var(--color-inverse-50);
  background-color: transparent;
}

.EmailOrPhoneSignInMethod {
  margin-top: 32px;
  margin-bottom: 12px;
}

.EmailOrPhoneSignInMethodError {
  margin-bottom: 16px;

  .error-message {
    color: var(--color-error);
    font-size: 12px;
    margin-top: 3px;
    margin-left: 16px;
    &:dir(rtl) {
      margin-left: 0;
      margin-right: 16px;
    }
  }
}

.EmailOrPhoneInput {
  margin-bottom: 16px;
}

.EmailOrPhoneSignInMethodSelection {
  display: flex;
  gap: 56px;
  margin-top: 16px;
}
