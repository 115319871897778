@import "src/assets/styles/global/index";
@import "src/assets/styles/global/rootVariables";
@import "shared/text";
@import "shared/drag";
@import "shared/neumorphism";

html {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Ilgar {
  &.isArabian {
    * {
      direction: rtl;
      //text-align: right;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      //direction: ltr;
      //text-align: right;
    }
  }
}
