
.CreateGenericQrContainer{
  position: relative;
  padding-top: 24px ;
}
.CreateGenericQrForm{
  margin-bottom:20px ;
}
.QRCode{
  svg{
    width: 180px;
    height: 180px;
  }
  margin-bottom: 20px;
}
