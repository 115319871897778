@import "src/assets/styles/_variables";

.EditButton {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mini-button);
  border: 1px solid transparent;

  h6,
  svg path {
    transition: $defaultTransition;
  }

  &.typeA {
    width: 40px;
    height: 40px;

    .EditButtonIcon {
      width: 24px;
      height: 24px;
    }
  }

  &.typeB {
    width: 36px;
    height: 36px;

    .EditButtonIcon {
      width: 16px;
      height: 16px;
    }
  }

  &.typeC {
    width: 46px;
    height: 46px;
    border-radius: 8px;

    .EditButtonIcon {
      width: 20px;
      height: 20px;
    }
  }

  &.typeD {
    width: 100%;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .EditButtonIcon {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }

  &.typeE {
    width: 40px;
    height: 40px;
    border-radius: 8px;

    .EditButtonIcon {
      width: 18px;
      height: 18px;
    }
  }

  &.typeF {
    width: 100px;
    height: 84px;
    border-radius: 8px;

    .EditButtonIcon {
      width: 64px;
      height: 64px;
    }
  }

  &:hover {
    background: var(--color-group-background-light-mode);

    h6 {
      color: var(--color-inverse-25);
    }
  }

  &:active {
    background: var(--color-stroke);

    svg path {
      stroke: var(--color-background-black);
      fill: var(--color-background-black);
    }

    h6 {
      color: var(--color-text-black);
    }
  }
}
